<template>
<v-simple-table v-if="employeeCounters">
  <template #default>
    <thead>
      <tr>
        <th v-for="header in headers" :key="header.text">
          {{ header.text }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr :key="item.id" v-for="item in employeeCounters" @click="$emit('editEmployeeCounter', item)" class="cursor-pointer">
        <td>{{ translate('types.' + item.employment_counter.name) }}</td>
        <td>{{ item.initial_value }} {{ $t('measurement_units_plural.' + item.employment_counter.measurement_unit.key) }}</td>
        <td>{{ item.value }} {{ $t('measurement_units_plural.' + item.employment_counter.measurement_unit.key) }}</td>
        <td>{{ item.expire_at }}</td>
      </tr>
    </tbody>
  </template>
</v-simple-table>
</template>
<script>
import ClockingMixin from "@/mixins/clockingMixin";

export default{
  name: "EmployeeCounterTable",
  mixins:[ClockingMixin],
  emits: ['editEmployeeCounter', 'showCountersTable'],
  props: ['employee'],
  data(){
    return {
      headers: [
        { text: this.translate('counter'), value: 'counter' },
        { text: this.translate('initial_value'), value: 'initial_value' },
        { text: this.translate('value'), value: 'value' },
        { text: this.translate('expiry_date'), value: 'expiry_date' },
      ],
      items: [],
      dialog: false,
      editedIndex: -1,
      editedItem: {
        counter_id: null,
        initial_value: null,
        expiry_date: null,
      },
      defaultItem: {
        counter_id: null,
        initial_value: null,
        expiry_date: null,
      },
    }
  },
  computed:{
    forceReloadComponentKey() {
      return this.$store.state.forceReloadComponentKey;
    }
  },
  watch:{
    employeeCounters(val){
        this.$emit('showCountersTable', val.length > 0)
    },
    async employee(val){
      if(!val){
        this.employeeCounters = []
      }

      await this.getCounters()
    },
    async forceReloadComponentKey(){
      await this.getCounters()
      this.$emit('showCountersTable', this.employeeCounters.length > 0)
    },
  },
  methods: {
    async getCounters(){
      this.employeeCounters = await this.getEmployeeCounters(this.employee.active_contract.id)
    }
  }
}
</script>