<template>
  <div>
    <h4 v-if="showLabel" class="mb-3" style="color:#9B9B9B; font-weight:normal">
      {{ label || $t("scheduler.add_bill_note") }}
    </h4>
    <wysiwyg v-model="note" />
  </div>
</template>
<script>
export default {
  name: "NoteEditor",
  emits: ["input"],
  props: {
    label: {
      default: null,
    },
    value: {
      required: true,
    },
    showLabel: {
      default: true,
    },
    rows:{
        default: 5
    }
  },

  computed: {
    note: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style scoped>
.editr{
    min-height: 400px !important;
    max-height: 400px !important;
}

.editr >>> .editr--content{
    min-height: 300px !important;
    max-height: 300px !important;
    overflow-y: scroll !important;
}
</style>