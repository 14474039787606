<template>
  <v-card flat>
    <v-form
      ref="form"
      class="main_form"
      id="billing-form"
    >
      <v-btn
        @click="showAttachmentDialog()"
        class="d-none"
        id="add-attachment"
      ></v-btn>
      <attachment-dialog
          :showDialog.sync="attachmentDialog"
          :data="selectedRecurrence && selectedRecurrence.id"
          :countKey="'billing'"
          :resource="'billing'"
      ></attachment-dialog>
      <input type="hidden" name="id" />
      <v-container fluid>
        <v-overlay :value="!isPageLoaded">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <!-- Clients -->
        <v-row class="group_label mt-0x">
          <v-col class=" pb-0">
            <span style="color: #2196F3"> {{ $t('billings.client') }} </span>
            <create-edit-client
              v-if="checkTag('clients')"
              ref="clientComp"
              :comp_entityType.sync="parent_entityType"
              :comp_client_id.sync="parent_client_id"
              :comp_company.sync="parent_company"
              :comp_company_client_name.sync="parent_company_client_name"
              :comp_company_client_first_name.sync="
                parent_company_client_first_name
              "
              :comp_company_client_names.sync="parent_company_client_names"
              :comp_search_client.sync="parent_search_client"
              :comp_company_client_phone.sync="parent_company_client_phone"
              :comp_company_client_gsm.sync="parent_company_client_gsm"
              :comp_company_client_fax.sync="parent_company_client_fax"
              :comp_company_client_email.sync="parent_company_client_email"
              :comp_company_client_language.sync="parent_company_client_language"
              :comp_web.sync="parent_web"
              :comp_currency.sync="parent_currency"
              :comp_representative_company_id.sync="
                parent_representative_company_id
              "
              :comp_user.sync="parent_user"
              :comp_note.sync="parent_note"
              :comp_familyClient.sync="parent_familyClient"
              :comp_denomination.sync="parent_denomination"
              :comp_tva.sync="parent_tva"
              :comp_is_shipping_visible="false"
              :comp_is_billing_visible="false"
              :comp_order_counts="null"
            />
          </v-col>
        </v-row>
        <!-- Recurrence -->
        <v-row class="group_label">
          <v-col class=" pb-0">
            <span style="color: #2196F3"> {{ $t('client_recurrence_document.recurrence') }} </span>
            <v-row>
              <v-col cols="12" sm="2" class="mt-2">
                <v-autocomplete
                  :autocomplete="autocompleteValueLocal"
                  v-model="selectedRecurrence"
                  class="to-upper"
                  :items="recurrences"
                  :search-input.sync="search_recurrence"
                  item-text="name"
                  item-value="id"
                  :loading="isLoadingSearchRecurrence"
                  :error-messages="$store.state.validation && !$v.selectedRecurrence.required ? [this.$t('client_recurrence_document.text_messages.recurrence_required')] : [] "
                  :placeholder="$t('billings.start_typing')"
                  return-object
                  append-icon="mdi-close"
                  v-on:keydown="handleInput($event, 'alphanumeric', 20)"
                  v-on:keydown.enter.prevent=""
                  ref="billDropdown"
                  @click:append="
                    onResetPageRecurrenceData();
                    search_recurrence = null;
                    looseFocusFromBillBox();
                  "
                  :outlined="is_boxes"
                  :hide-no-data="true"
                  hide-selected
                  dense
                >
                  <template slot="item" slot-scope="{ item }">
                    <v-list-item-content>
                      <span>
                        <v-icon
                          class="ma-0"
                          v-if="item.document_type.key == 'bill'"
                          color="red"
                          right
                        >
                          mdi-script-text
                        </v-icon>
                        <v-icon
                          class="ma-0"
                          v-if="item.document_type.key == 'purchase_order'"
                          color="green"
                          right
                        >
                          mdi-tag-text
                        </v-icon>
                        <v-icon
                          class="ma-0"
                          v-if="item.document_type.key == 'estimation'"
                          color="black"
                          right
                        >
                          mdi-book-open
                        </v-icon>
                        <v-icon
                          class="ma-0"
                          v-if="item.document_type.key == 'credit_note'"
                          color="black"
                          right
                        >
                          mdi-note-multiple
                        </v-icon>
                        {{ item.name }}
                      </span>
                      <br />
                      <!-- <span class="client_name" v-if="item.name">
                        {{ $t('billings.types.' + item.document_type.key)}}
                      </span> -->
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" md="2" :style="{textAlign: 'right'}" >
                <v-menu
                  v-model="menuStartAt"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="start_at"
                      :label="$t('client_recurrence_document.start_at')"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:append="start_at = null"
                      :error-messages="$store.state.validation && !$v.start_at.required ? [$t('client_recurrence_document.text_messages.start_at_required')] : [] "
                      append-icon="mdi-close"
                      class="to-upper"
                      :outlined="is_boxes"
                      :dense="is_boxes"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :first-day-of-week="1"
                    :weekday-format="getDay"
                    v-model="start_at"
                    @input="menuStartAt = false"
                    no-title
                    scrollable
                    :locale="userLanguageId"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" md="2" :style="{textAlign: 'right'}" >
                <v-menu
                  v-model="menuEndAt"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="end_at"
                      :label="$t('client_recurrence_document.end_at')"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:append="end_at = null"
                      append-icon="mdi-close"
                      class="to-upper"
                      :outlined="is_boxes"
                      :dense="is_boxes"
                      
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :first-day-of-week="1"
                    :weekday-format="getDay"
                    v-model="end_at"
                    @input="menuEndAt = false"
                    no-title
                    scrollable
                    :locale="userLanguageId"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="1">
                <v-combobox
                  v-on:keydown="handleInput($event,'int',2);"
                  @keyup="handleAutoFocus($event,2,'day','int')"
                  autocomplete="donotautocomplete"
                  v-model="run_day"
                  :return-object="false"
                  :items="days"
                  @input="debounceInput"
                  @focus="run_day=null"
                  class="pt-0 to-upper"
                  item-value="id"
                  item-text="text"
                  ref="day"
                  :error-messages="$store.state.validation && !$v.run_day.required ? [this.$t('client_recurrence_document.text_messages.run_day_required')] : [] "
                  :label="$t('client_recurrence_document.run_day')"
                  v-on:keydown.enter.prevent=""
                  :outlined="is_boxes"
                  :dense="is_boxes"
                ></v-combobox>
              </v-col>
              <v-col cols="12" sm="1">
                <v-combobox
                    v-on:keydown="handleInput($event,'int',2);"
                    @keyup="handleAutoFocus($event,2,'month','int')"
                    autocomplete="donotautocomplete"
                    v-model="run_month"
                    ref="month"
                    :return-object="false"
                    :items="months"
                    class="pt-0 to-upper"
                    @input="debounceInput"
                    @focus="run_month=null"
                    item-value="id"
                    item-text="text"
                    :label="$t('client_recurrence_document.run_month')"
                    v-on:keydown.enter.prevent=""
                    :outlined="is_boxes"
                    :dense="is_boxes"
                ></v-combobox>
              </v-col>
              <v-col cols="12" sm="2">
                <v-combobox
                  :autocomplete="autocompleteValueLocal"
                  v-model="notification_type"
                  :items="notification_types"
                  item-value="id"
                  class="pt-0 to-upper"
                  item-text="text"
                  :label="$t('scheduler.reminder')"
                  :outlined="is_boxes"
                  :dense="is_boxes"
                ></v-combobox>
              </v-col>
              <v-col cols="12" sm="1">
                <v-checkbox
                  class="pt-0 to-upper"
                  v-model="is_active"
                  :label="$t('client_recurrence_document.is_active')"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- Client Document Articles -->
        <v-row class="group_label mt-4">
          <v-col class=" pb-0">
            <span style="color: #2196F3"> {{ $t('client_recurrence_document.recurrence_document_articles') }} </span>
            <v-row class="pt-0 mt-3">
              <v-data-table
                :footer-props="{
                  'items-per-page-options': dataTableOptions,
                }"
                :headers="dataHeader"
                :hide-default-footer="true"
                :hide-default-header="true"
                :items="selectedArticles"
                v-sortable-data-table
                :item-class="row_classes"
                @sorted="saveOrder"
                :items-per-page="50"
                class="elevation-0 mb-10"
                style="width: 100%"
                disable
              >
                <template #header="{ props: { headers } }">
                  <thead class="v-data-table-header">
                    <tr>
                      <template v-for="header in headers">
                        <th :key="header.value" scope="col" class="text-right">
                          <template v-if="header.value == 'article_original_name'">
                            <v-row justify="space-between">
                              <v-col class="d-flex" cols="12" sm="2">
                                {{ header.text }}
                              </v-col>
                              <v-col
                                v-if="
                                  selectedFamilyArticle &&
                                    selectedFamilyArticle.is_stock_managed == 1
                                "
                                cols="12"
                                sm="3"
                                class="ma-1 pa-0"
                                style="height:20px; display: flex; justify-content: flex-end"
                                v-on:keydown.enter.prevent="
                                  parentInternalStockOnly = !parentInternalStockOnly
                                "
                              >
                                <v-checkbox
                                  class="mt-1 ma-0 pa-0"
                                  hide-details="auto"
                                  v-model="parentInternalStockOnly"
                                  :label="$t('billings.is_only_internal')"
                                ></v-checkbox>
                              </v-col>
                            </v-row>
                          </template>
                          <template v-else-if="header.value == 'actions'">
                            <v-row justify="space-between">
                              <v-col class="d-flex dontbreakline" cols="12" sm="2">
                                {{ header.text }}
                              </v-col>
                            </v-row>
                          </template>
                          <template v-else>
                            {{ header.text }}
                          </template>
                        </th>
                      </template>
                    </tr>
                  </thead>
                </template>
                <template v-slot:no-data>
                  <span></span>
                </template>
                <template v-slot:body.prepend>
                </template>
                <template v-slot:item.information="{ item, index }">
                    <div class="d-flex flex-row information-class"
                            @mouseover="toggleElementById(index, 'visible')"
                            @mouseleave="toggleElementById(index, 'hidden')">
                    <div>
                        <!-- <v-icon
                                :id="generateElementId(index)"
                                class="mdi mdi-drag"
                                :style="{
                                    visibility: 'hidden',
                                    cursor: 'move',
                                    marginLeft: 0,
                                    paddingLeft: 0
                                }"
                        ></v-icon> -->
                    </div>
                  <!-- <span v-if="item.supplier_order_status">{{item.supplier_order_status.status_id}} {{ item.supplier_order_status.status_id in [10091000,10091001,10091004] }}</span> -->
                  <div
                    v-if="
                      item.supplier &&
                        item.supplier.length > 0 &&
                        item.supplier[0] &&
                        item.supplier[0].id
                    "
                    :class="
                      typeof item.article != 'object'
                        ? item.article.internal_company_article &&
                          item.article.internal_company_article.length > 0 &&
                          item.article.internal_company_article[0].stock <
                            item.quantity
                          ? 'articleNotInStock'
                          : item.supplier_order_status &&
                            item.supplier_order_status.supplier_order_details &&
                            [10091000, 10091001, 10091004].includes(
                              item.supplier_order_status.status_id
                            )
                          ? new Date(
                              item.supplier_order_status.supplier_order_details.delivery_date
                            ) >=
                            new Date(formatDate(getCurrentDate(), 'yyyy-mm-dd'))
                            ? 'articleWaitingForSupplierOrderOrange'
                            : 'articleWaitingForSupplierOrder'
                          : 'articleInStock'
                        : ''
                    "
                    style="max-width: 100px; border:0px; width: 60px;"
                    dense
                    return-object
                    readonly
                    flat
                    solo
                    hide-details="auto"
                  >
                    <v-icon
                      v-if="
                        item.supplier_order_status &&
                          [10091000, 10091001, 10091004].includes(
                            item.supplier_order_status.status_id
                          )
                      "
                      slot="prepend"
                      :color="
                        item.supplier_order_status &&
                        item.supplier_order_status.supplier_order_details &&
                        item.supplier_order_status.supplier_order_details
                          .delivery_date &&
                        new Date(
                          item.supplier_order_status.supplier_order_details.delivery_date
                        ) >= new Date(formatDate(getCurrentDate(), 'yyyy-mm-dd'))
                          ? 'orange'
                          : 'red'
                      "
                    >
                      mdi-timer-sand
                    </v-icon>
                    <v-icon
                      v-if="
                        item.supplier_order_status &&
                          [10091005].includes(item.supplier_order_status.status_id)
                      "
                      slot="prepend"
                      :color="'green'"
                    >
                      mdi-check
                    </v-icon>
                    {{ item.supplier[0].identifier }}
                  </div>
                  <!-- Dropdown For INTERNAL STOCK -->
                  <v-select
                    v-if="
                      (typeof item.article == 'object'
                        ? item.article.family.is_with_stock
                        : item.article_original_name &&
                          item.article_original_name.family.is_with_stock) &&
                        ((item.supplier && item.supplier[0] == null) ||
                          !item.supplier)
                    "
                    :value="internalSupplier"
                    :items="[internalSupplier]"
                    item-value="id"
                    item-text="identifier"
                    class="ma-0"
                    :class="
                      item.article.internal_company_article &&
                      item.article.internal_company_article.length > 0 &&
                      item.article.internal_company_article[0].stock < item.quantity
                        ? 'articleNotInStock'
                        : item.supplier_order_status &&
                          [10091000, 10091001, 10091004].includes(
                            item.supplier_order_status.status_id
                          )
                        ? 'articleWaitingForSupplierOrder'
                        : 'articleInStock'
                    "
                    style="max-width: 100px"
                    outlined
                    dense
                    return-object
                    readonly
                    hide-details="auto"
                  >
                    <v-icon slot="append" v-text="''"></v-icon>
                    <v-icon
                      v-if="
                        item.supplier_order_status &&
                          [10091000, 10091001, 10091004].includes(
                            item.supplier_order_status.status_id
                          )
                      "
                      slot="append"
                      color="red"
                    >
                      mdi-timer-sand
                    </v-icon>
                  </v-select>
                        </div>
                </template>
                <template v-slot:item.article_original_name="{ item, index }">
                  <v-row
                          @mouseover="toggleElementById(index, 'visible')"
                          @mouseleave="toggleElementById(index, 'hidden')"
                  >
                    <v-col cols="12" sm="12" style="display:flex;">

                      <div class="" style="width: 100%">
                        <template v-if="item.group">
                          <div class="mt-2"></div>
                          <strong>{{ item.group.toUpperCase() }}</strong> <br />
                        </template>
                        <template
                          v-if="
                            item.article_original_name &&
                              item.article_original_name.title_description.length >
                                0
                          "
                        >
                          <div class="cursor-pointer-trans" @click="dataTableItemClicked(item)">
                            <span>
                            {{
                              item.article_original_name.title_description[0]
                                .description
                            }}<br />
                            <span> {{ item.article_original_name.identifier }} </span>
                            </span>
                          </div>
                          <!-- <v-icon
                            @click="addTextAndGroupToArticle(item)"
                            :id="'editArticle' + index"
                            color="blue"
                            :class="
                              item.text
                                ? 'mdi mdi-pencil'
                                : 'mdi mdi-pencil-outline'
                            "
                            style="position:relative; float:right; top:-30%; visibility:hidden"
                          ></v-icon> -->
                        </template>
                        <template v-else>
                          <div v-html="item.text"></div>
                        </template>
                      </div>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item.quantity="{ item, index }">
                  <v-row
                          @mouseover="toggleElementById(index, 'visible')"
                          @mouseleave="toggleElementById(index, 'hidden')"
                  >
                    <v-col cols="12" sm="12">
                      <template v-if="item.quantity">
                        <!-- {{ item.article_original_name }} -->
                        <span>
                          {{
                            fixDecimal(
                              item.quantity,
                              item
                                ? item.article_original_name
                                  ? item.article_original_name.family
                                      .quantity_decimals
                                  : 0
                                : 0
                            )
                          }}
                          {{
                            item.selectedmu.value
                          }}
                          <!-- Here we have hardcoded if nothing is their then family mu -->
                        </span>
                        <!-- <span> {{ item.quantity }} </span> -->
                      </template>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item.rounded_unit_price="{ item, index }">
                  <v-row
                          @mouseover="toggleElementById(index, 'visible')"
                          @mouseleave="toggleElementById(index, 'hidden')"
                  >
                    <v-col cols="12" sm="12">
                      <!-- <span v-if="billType.key == 'credit_note'">
                        {{ item.rounded_unit_price ? (parseFloat(item.rounded_unit_price) * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber()) : (0).toFixed(getDecimalNumber()) }}
                        {{
                          parseFloat(item.rounded_unit_price).toFixed(
                            getDecimalNumber()
                          )
                        }}
                      </span>
                      <span v-else> -->
                        {{ currencySymbol }}
                        {{
                          item.rounded_unit_price
                            ? parseFloat(item.rounded_unit_price).toFixed(
                                getDecimalNumber()
                              )
                            : (0).toFixed(getDecimalNumber())
                        }}
                      <!-- </span> -->
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item.red_percentage="{ item, index }">
                  <v-row
                          @mouseover="toggleElementById(index, 'visible')"
                          @mouseleave="toggleElementById(index, 'hidden')"
                  >
                    <v-col cols="12" sm="12">
                      <span>
                        {{ currencySymbol }}
                        {{
                          (item.red_percentage ? item.red_percentage : 0).toFixed(
                            getDecimalNumber()
                          )
                        }}
                      </span>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item.tva="{ item, index }">
                  <v-row
                          @mouseover="toggleElementById(index, 'visible')"
                          @mouseleave="toggleElementById(index, 'hidden')"
                  >
                    <v-col cols="12" sm="12">
                      <span>
                        {{ currencySymbol }}
                        {{ (item.tva.tax ? item.tva.tax : 0).toFixed(getDecimalNumber()) }}
                      </span>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item.thtva="{ item, index }">
                  <v-row
                          @mouseover="toggleElementById(index, 'visible')"
                          @mouseleave="toggleElementById(index, 'hidden')"
                  >
                    <v-col cols="12" sm="12">
                        {{ currencySymbol }}
                        {{
                          (item.thtva ? item.thtva : 0).toFixed(getDecimalNumber())
                        }}
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item.total="{ item, index }">
                  <v-row
                          @mouseover="toggleElementById(index, 'visible')"
                          @mouseleave="toggleElementById(index, 'hidden')"
                  >
                    <v-col cols="12" sm="12">
                      <!-- <span v-if="billType.key == 'credit_note'"> -->
                        <!-- {{ ((item.total ? item.total : 0) * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber()) }} -->
                        <!-- {{ item.total.toFixed(getDecimalNumber()) }} -->
                      <!-- </span>
                      <span v-else> -->
                        {{ currencySymbol }}
                        {{
                          (item.total ? item.total : 0).toFixed(getDecimalNumber())
                        }}
                      <!-- </span> -->
                    </v-col>
                  </v-row>
                </template>
                <!-- <template v-slot:item.actions="{ item, index }">
                  <v-row
                          @mouseover="toggleElementById(index, 'visible')"
                          @mouseleave="toggleElementById(index, 'hidden')"
                  >
                    <v-col cols="9" sm="9">
                      
                    </v-col>
                    <v-col
                      v-if="!(selectedRecurrence && selectedRecurrence.status_id == 10251003)"
                      cols="3"
                      sm="3"
                      class="pa-0 pt-4"
                    >
                      <v-icon small color="red" @click="deleteItem(item)">
                        mdi-delete
                      </v-icon>
                    </v-col>
                  </v-row>
                </template> -->

                <template v-slot:body.append>
                    <!-- Total -->
                    <tr
                        class="last_row"
                        v-if="selectedArticles.length > 0"
                        style="background-color:rgba(142, 142, 142, 0.07)"
                    >
                        <th class="text-sm-right" v-text="''"/>
                        <th class="text-sm-right" v-text="''"/>
                        <th class="text-sm-right" v-text="''"/>
                        <th class="text-sm-right" v-text="''"/>
                        <th class="text-sm-right ma-0 pa-0 mt-1 mb-1">
                            <custom-money-input
                                :autocomplete="autocompleteValueLocal"
                                :disabled="true"
                                @input="calculateTotals()"
                                class="pt-0 to-upper right-aligned-input shrink float-right pr-2"
                                hide-details="auto"
                                dense
                                tabindex="3"
                                style="width:80px"
                                ref="reduction_on_order"
                                v-model="reduction_on_order"
                                v-on:keydown="handleInput($event, 'float', 5);"
                                prefix="%"
                                v-bind:properties="{
                                    prefix: '%',
                                    readonly: true,
                                    disabled: true,
                                    outlined: false,
                                    placeholder: getDecimalNumber() == 2 ? '0.00' : '0.000',
                                    dense: true,
                                    hideDetails: 'auto',
                                }"
                                v-bind:options="{
                                    locale: 'en',
                                    length: 12,
                                    precision: getDecimalNumber(),
                                    empty: getDecimalNumber() == 2 ? 0.0 : 0.0,
                                }"
                            />
                        </th>
                        <th class="text-sm-right" v-text="''"/>
                        <th class="text-sm-right">
                        <span v-if="billType && billType.key == 'credit_note'" class="text-no-wrap">
                            {{ currencySymbol }}
                            {{ articleTHTVATotalBeforeReduction.toFixed(getDecimalNumber()) }}
                        </span>
                        <span v-else class="text-no-wrap">
                            {{ currencySymbol }}
                            {{
                            (articleTHTVATotalBeforeReduction ? articleTHTVATotalBeforeReduction : 0).toFixed(
                                getDecimalNumber()
                            )
                            }}
                        </span>
                        </th>
                        <th class="text-sm-right">
                        <span v-if="billType && billType.key == 'credit_note'" class="text-no-wrap">
                            <!-- {{ (articleTotal * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber())}} -->
                            {{ currencySymbol }}
                            {{ articleTotalBeforeReduction.toFixed(getDecimalNumber()) }}
                        </span>
                        <span v-else class="text-no-wrap">
                            {{ currencySymbol }}
                            {{
                            (articleTotalBeforeReduction ? articleTotalBeforeReduction : 0).toFixed(
                                getDecimalNumber()
                            )
                            }}
                        </span>
                        </th>
                    </tr>
                    <!-- Total After Reduction -->
                    <tr
                        class="last_row"
                        v-if="selectedArticles.length > 0 && total_reduction > 0"
                        style="background-color:rgba(142, 142, 142, 0.07)"
                    >
                        <th class="text-sm-right" v-text="''"/>
                        <th class="text-sm-right" v-text="''"/>
                        <th class="text-sm-right" v-text="''"/>
                        <th class="text-sm-right" v-text="''"/>
                        <th class="text-sm-right">
                            {{ currencySymbol }}
                            - {{ total_reduction }}
                        </th>
                        <th class="text-sm-right" v-text="''"/>
                        <th class="text-sm-right">
                        <!-- <span v-if="billType && billType.key == 'credit_note'" class="text-no-wrap">
                            {{ currencySymbol }}
                            {{ articleTHTVATotal.toFixed(getDecimalNumber()) }}
                        </span>
                        <span v-else class="text-no-wrap">
                            {{ currencySymbol }}
                            {{
                            (articleTHTVATotal ? articleTHTVATotal : 0).toFixed(
                                getDecimalNumber()
                            )
                            }}
                        </span> -->
                        </th>
                        <th class="text-sm-right">
                        <span v-if="billType && billType.key == 'credit_note'" class="text-no-wrap">
                            <!-- {{ (articleTotal * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber())}} -->
                            {{ currencySymbol }}
                            {{ articleTotal.toFixed(getDecimalNumber()) }}
                        </span>
                        <span v-else class="text-no-wrap">
                            {{ currencySymbol }}
                            {{
                            (articleTotal ? articleTotal : 0).toFixed(
                                getDecimalNumber()
                            )
                            }}
                        </span>
                        </th>
                    </tr>
                </template>
              </v-data-table>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-btn @click="onSubmit()" class="d-none" id="submit-recurrence"></v-btn>
    <v-btn @click="onDelete()" class="d-none" id="delete-recurrence"></v-btn>
    
    <template>
      <v-row justify="center">
        <v-dialog v-model="showTextDialog" scrollable max-width="650px">
          <v-card>
            <v-card-title>{{
              showGroupText
                ? $t("edit_article_text_and_group")
                : $t("edit_article_text")
            }}</v-card-title>
            <v-card-text style="min-height: 200px;">
              <v-text-field
                v-if="showGroupText"
                v-model="tempItemGroup"
                :label="$t('group')"
                v-on:keydown.enter.prevent=""
                :outlined="is_boxes"
                :dense="is_boxes"
                class="to-upper"
              ></v-text-field>
              <v-textarea
                v-model="tempItemText"
                :label="$t('text')"
                :outlined="is_boxes"
                :dense="is_boxes"
                class="to-upper"
              ></v-textarea>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="px-2">
              <v-spacer></v-spacer>
              <v-btn
                color="grey lighten-2"
                @click="cancelTextAndGroupToArticle()"
              >
                {{ $t("cancel") }}
                <v-icon dark right> mdi-keyboard-return </v-icon>
              </v-btn>
              <v-btn color="success" @click="saveTextAndGroupToArticle()">
                {{ $t("save") }}
                <v-icon dark right> mdi-content-save </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <depot-add-modify-tyre
      v-if="depotInMod == true"
      :depotDetails="null"
      :vehicleDriverDetails.sync="vehicleDriverDetails"
      @modifyClose="modifyDepotInClose"
    ></depot-add-modify-tyre>
    <depot-out
      v-if="depotOutMod == true"
      :vehicleDriverDetails.sync="vehicleDriverDetails"
      :alreadySelectedDepot="selectedArticles"
      @modifyClose="modifyDepotOutClose"
    ></depot-out>
    <loading-model :showLoadingModel.sync="showLoadingModel"></loading-model>

    <confirmation-model
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
  </v-card>
</template>
<style scoped>
.occupy_space{
  width: 100%;
}
.first_row:hover:not(.v-table__expanded__content) {
  background: white !important;
}
.supplier_name {
  font-size: 12px;
  margin: 0px;
}
.article_name {
  font-size: 14px;
  margin-bottom: 2px;
}
.bill_number {
  font-size: 14px;
  margin: 0px;
}
.identifier {
  font-size: 12px;
  margin-bottom: 2px;
}
.client_name {
  font-size: 16px;
  margin-bottom: 2px;
}
.bill_type {
  font-size: 11px;
  margin-bottom: 2px;
}
</style>

<script>
import axios from "@/plugins/axios";
import Sortable from "sortablejs";
import _ from "lodash";
import "vuejs-noty/dist/vuejs-noty.css";
import {API_BASE_URL, VIEW_MODE} from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import { DETAILS_MODE } from "@/config";
import CutsomMoneyInput from "@/components/CustomInputs/MoneyInput";
import CreateEditClient from "@/components/Client/CreateEditClient";
import AttachmentDialog from "@/components/AttachmentDialog";
import billMixin from "@/mixins/billMixin";

import TimeComponent from "../../components/TimeComponent.vue";

import { validationMixin } from "vuelidate";
import {
  required,
  email,
  requiredIf,
  phoneNum,
  numeric,
  minLength,
  maxLength,
  alpha,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";
import SearchArticle from "@/components/Billing/SearchArticle";
import DepotAddModifyTyre from "@/components/TyreHotel/DepotAddModifyTyre";
import DepotOut from "@/components/TyreHotel/DepotOut";
import LoadingModel from "@/components/LoadingModel";
import DatePicker from "@/components/DatePicker";
import ConfirmationModel from "@/components/ConfirmationModel";
import {mapState} from "vuex";
import { DateTime } from "luxon";

export default {
  mixins: [validationMixin, billMixin],
  validations: {
    selectedRecurrence: {
      required,
    },
    start_at: {
      required
    },
    run_day: {
      required
    },
  },
  name: "Recurrence",
  props: {
    iCompany: Boolean,
  },
  components: {
    "create-edit-client": CreateEditClient,
    // "search-article": SearchArticle,
    "depot-add-modify-tyre": DepotAddModifyTyre,
    "depot-out": DepotOut,
    "loading-model": LoadingModel,
    "confirmation-model": ConfirmationModel,
    "custom-money-input": CutsomMoneyInput,
    "attachment-dialog":	AttachmentDialog,
    // "time-component": TimeComponent,
    // "date-picker": DatePicker,
  },
  data() {
    return {
      notification_types: [],
      notification_type: null,
      recurring_id: null, 
      menuStartAt: false,
      start_at: (new Date).toISOString().substring(0, 10), 
      menuEndAt: false,
      end_at: null,
      attachmentDialog: false,
      showBillNote: false,
      parentOrder: null,
      childOrder: null,
      months:                   [],
      run_month:                    null,
      days:                     [],
      run_day:                      null,
      recurring_name: null,
      document_type: null, 
      internalSupplier: {
        id: 9,
        name: "INTERNAL",
        contact: "",
        identifier: "INT",
        family_article_id: null,
        parent_supplier_id: null,
        parent_supplier: null,
        status_id: 10181000,
        type_id: 10191001,
        created_at: "2018-12-27",
        updated_at: "2019-06-14",
      },
      selectedItemForGroupAndText: false,
      tempItemText: null,
      tempItemGroup: null,
      showTextDialog: false,
      addArticlesAutomatically: this.getCompanyPropertyFromLocalStorage(
        "ADD_AUTOMATICALLY_ARTICLES"
      )
        ? this.convertToBoolean(
            this.getCompanyPropertyFromLocalStorage(
              "ADD_AUTOMATICALLY_ARTICLES"
            ).value
          )
        : null,
      showGroupText: this.getCompanyPropertyFromLocalStorage(
        "group_articles",
        1
      )
        ? true
        : false,
      parentGroupText: null,
      selectedFamilyArticle: null,
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      creditNoteMultiplicationValue: null,
      storedDepotIn: [],
      depotInMod: false,
      depotOutMod: false,
      parentSearchSelectedArticleId: null,
      parentSearchSelectedArticleSupplierDetail: null,
      parentInternalStockOnly: true,
      newValidation: false,
      notificationData: [],
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      page: 1,
      lastPage: null,
      totalItems: null,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      sortBy: "description",
      sortDesc: false,
      options: {},
      pagination: {},
      DeleteConfirm: false,
      denominations: [],
      allStatuses: [],
      order_status: "",
      isVisibleOrderStatus: false,
      isRecalculatingTableData: false,
      payment_methods: [],
      payment_method: null,
      ispayment_method: false,
      operation: "edit",
      bill_amount: "",
      creditors: [],
      showLoadingModel: false,

      // Client Component Sync Values
      parent_entityType: null,
      parent_client_id: null,
      parent_company: null,
      parent_company_client_name: null,
      parent_company_client_first_name: null,
      parent_company_client_names: [],
      parent_search_client: null,
      parent_company_client_phone: null,
      parent_company_client_gsm: null,
      parent_company_client_fax: null,
      parent_company_client_email: null,
      parent_company_client_language: null,
      parent_web: null,
      parent_currency: null,
      parent_representative_company_id: null,
      parent_user: null,
      parent_note: null,
      parent_familyClient: null,
      parent_denomination: null,
      parent_registration_code: null,
      parent_tva: null,

      rdv_date: null,
      menu_rdv_date: false,

      note: "",
      tvas: [],
      reference_client: "",
      isPageLoaded: false,
      unit_price: 0.0,
      quantity: 4,
      first_row_tariff: 0,
      first_row_tva: "",
      cached_first_row_tva: "",
      first_row_thtva: "",
      first_row_total: "",
      red_percentage: 0.0,
      next_event_codes: [],
      next_event_code: "",
      search: "",
      isLoadingArticle: false,
      isLoading: false,
      rowNumber: 0,
      articleTHTVATotal: 0.0,
      articleTHTVATotalBeforeReduction: 0.0,
      articleTotal: 0.0,
      articleTotalBeforeReduction: 0.0,
      total_reduction: 0.0,
      reduction_on_order: 0,
      selectedArticles: [],
      finedSelectedArticles: [],
      articles: [],
      article: "",
      search_article: "",
      search_recurrence: "",
      allowContinueSearch: true,
      recurrences: [],
      selectedRecurrence: "",
      isLoadingSearchRecurrence: false,
      familyArticle: "",
      customer_balance: 0,

      dialog: false,

      isEditModeForDataTableItem: false,
      selectedItemIndexInDataTable: null,
      currentMu: '',
      mus:[],

      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      autocompleteValueLocal: "off",
      is_active: false, 
      dataHeader: [
        {
          text: "",
          align: "right",
          width: 5,
          sortable: false,
          value: "information",
        },
        {
          text: this.$t("billings.article"),
          align: "start",
          width: 450,
          sortable: true,
          value: "article_original_name",
        },
        {
          text: this.$t("billings.quantity"),
          align: "end",
          width: 80,
          sortable: true,
          value: "quantity",
        },
        // { text: this.$t('billings.unit_price'),           align: 'end',    width:80,  sortable: false,         value: 'unit_price' },
        {
          text: this.$t("billings.unit_price"),
          align: "end",
          width: 80,
          sortable: false,
          value: "rounded_unit_price",
        },
        {
          text: this.$t("billings.red_percentage"),
          align: "end",
          width: 80,
          sortable: false,
          value: "red_percentage",
        },
        // { text: this.$t('billings.tarrif'),               align: 'end',    width:100,  sortable: false,         value: 'tarrif' },
        {
          text: this.$t("billings.tva"),
          align: "end",
          width: 100,
          sortable: false,
          value: "tva",
        },
        {
          text: this.$t("billings.thtva"),
          align: "end",
          width: 125,
          sortable: false,
          value: "thtva",
        },
        {
          text: this.$t("billings.total"),
          align: "end",
          width: 125,
          sortable: false,
          value: "total",
        },
        // {
        //   text: this.$t("billings.actions") + "      .",
        //   align: "center",
        //   width: 200,
        //   sortable: false,
        //   value: "actions",
        // },
      ],

      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
      selectedDepot: null,
      pauseWatchForUnitPrice: false,

      stock_module_data_for_order: null,
      lastSupplierOrderCreated: null,
      defaultListOfRecurrences: [],
    };
  },
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          filter: "", // Selectors that do not lead to dragging (String or Function)
          animation: 450,
          onUpdate: function(event) {
            vnode.child.$emit("sorted", event);
          },
          // Event when you move an item in the list or between lists
          onMove: function(/**Event*/ evt, /**Event*/ originalEvent) {
            // Example: https://jsbin.com/nawahef/edit?js,output
            evt.dragged; // dragged HTMLElement
            evt.draggedRect; // DOMRect {left, top, right, bottom}
            evt.related; // HTMLElement on which have guided
            evt.relatedRect; // DOMRect
            evt.willInsertAfter; // Boolean that is true if Sortable will insert drag element after target by default
            originalEvent.clientY; // mouse position

            // console.log("on move", evt.dragged.className.includes("first_row"));
            if (
              evt.dragged.className.includes("first_row") ||
              evt.dragged.className.includes("last_row") ||
              evt.related.className == "last_row" ||
              evt.related.className == "first_row"
            ) {
              return false;
            }
            // return -1; — insert before target
            // return 1; — insert after target
            // return true; — keep default insertion point based on the direction
            // return void; — keep default insertion point based on the direction
          },
        };
        // console.log(
        //   "get element by tag name",
        //   el.getElementsByClassName("tbody")[0],
        //   options
        // );
        Sortable.create(el.getElementsByTagName("tbody")[0], options);
      },
    },
  },
  async mounted() {
    this.$store.commit("setValidation", false); // enable validation
    this.autocompleteValueLocal = this.autocompleteValue();
    this.header = { Authorization: "Bearer " + this.token },
    this.$store.commit("setFormMode", DETAILS_MODE);
    this.$store.commit("setShowPrintReportInPrintDropDown", false);
    this.creditNoteMultiplicationValue =
      this.getCompanyPropertyFromLocalStorage(
        "CREDIT_NOTE_MULTIPLICATION_VALUE"
      ) ?? this.creditNoteMultiplicationDefaultValue;
    this.setCurrentFormOperation();
    // this.getTvas();
    this.getMeasurementUnits();
    this.fillMonths();
    this.fillDays();
    this.getNotificationTypes();

    if (this.$route.name == "EditClientRecurrence") {
      // console.log(this.$store.state.editId, "Fetched Bill ID");
      let recurrenceId = this.$store.state.editId;
      if (recurrenceId) {
        await this.featchClientDocumentRecurrenceByID(recurrenceId);
      } else {
        this.$router.push("/client_recurrence_document/add").catch(() => {});
        this.isPageLoaded = true;
        this.onResetPageData();
      }
    } else {
      this.isPageLoaded = true;
      this.onResetPageData();
    }

    if(this.selectedRecurrence){
        this.fetchBillRelatives(this.selectedRecurrence);
    }
    this.fetchRecurrencesSuggesList(true);
  },
  beforeDestroy() {
    this.$store.commit("setBillCancelVisible", false);
    this.$store.commit("setEditingAllowed", false);
    this.$store.commit("setMakeBillVisible", false);
    this.$store.commit("setMakePurchaseOrderVisible", false);
    this.$store.commit("setMakeCreditNoteVisible", false);
  },
  methods: {
    fixBillCurrencySymbol(){
      if(this.selectedArticles.length > 0){
        this.selectedArticles.forEach((element) => {
          element.currency_id = this.$store.state.topCurrencyType ? this.$store.state.topCurrencyType.id : this.defaultCurrencyId;
        });
        this.fineDataForSending();
      }
    },
    fillMonths(){
      let data = [];
      for (let index = 1; index <= 12; index++) {
        data.push({'id'  : index,'text' : index})
      }
      this.months = data;
    },
    fillDays(){
      let data = [];
      for (let index = 1; index <= 31; index++) {
        data.push({'id'  : index,'text' : index})
      }
      this.days = data;
    },
    getNotificationTypes() {
      return new Promise((resolve, reject) => {
        this.getCodes('client_notification_types', 'scheduler.notification_types')
            .then((data) => {
                this.notification_types = data;
                resolve();
            });
      });
    },
    showAttachmentDialog(){
      this.attachmentDialog = true;
    },
    getMeasurementUnits() {
      return new Promise((resolve, reject) => {
        this.getCodes('measurement_units', 'measurement_units')
            .then((data) => {
              this.mus = data;
              this.mus = this.mus.sort(function (a, b) {
                  if (a.text < b.text) {
                      return -1;
                  }
                  if (a.text > b.text) {
                      return 1;
                  }
                  return 0;
              });
              resolve();
            });
      });
    },
    dataTableItemClicked(item){
      console.log("------------------------------------------", item);
      // Enable Edit Mode for DataTable Item
      this.isEditModeForDataTableItem = true;
      this.selectedItemIndexInDataTable = item.srno;

      console.log("selecting item",item);
      if(item.article_original_name){
        this.articles = [item.article_original_name];
        this.article = item.article_original_name;
        this.article.supplier = item.supplier ?? [];
        this.quantity = parseFloat(item.quantity);
        this.currentMu = item.selectedmu;
        this.first_row_tariff = parseFloat(item.tarrif ?? 0);
        this.unit_price = parseFloat(item.unit_price);
        this.red_percentage = parseFloat(item.red_percentage ?? 0)
        // this.first_row_tva = parseFloat(this.article.internal_company_article.length > 0 ? this.article.internal_company_article[0].tva.tax : 17);
        this.first_row_thtva = parseFloat(item.thtva);
        this.first_row_tva = parseFloat(item.tva)
        this.first_row_total = parseFloat(item.total);
        console.log("passed prices", this.currentMu);
      }
      // this.article.supplier = [];
      // this.article.supplier_stock_detail = [];
    },
    checkIfselectedRecurrenceHasArticleNotDeliveredYet() {
      let hasNotDelivered = false;
      if (
        this.selectedRecurrence &&
        this.selectedRecurrence.supplier_order &&
        this.selectedRecurrence.supplier_order.length > 0
      ) {
        this.selectedRecurrence.supplier_order.forEach((supplierOrder) => {
          if (
            [10091000, 10091001, 10091004].includes(supplierOrder.status_id)
          ) {
            hasNotDelivered = true;
          }
          // console.log(
          //   "Supplier checking status for the supplier",
          //   supplierOrder
          // );
        });
      }
      return hasNotDelivered;
    },
    updateItemInSelectedArticles(item) {
      this.selectedArticles.forEach((element) => {
        console.log("element", element, item);
      });
    },
    addTextAndGroupToArticle(item) {
      this.tempItemGroup = item.group;
      this.tempItemText = item.text;
      this.selectedItemForGroupAndText = item;
      this.showTextDialog = true;
    },
    saveTextAndGroupToArticle() {
      this.selectedItemForGroupAndText.group = this.tempItemGroup;
      this.selectedItemForGroupAndText.text = this.tempItemText;
      this.updateItemInSelectedArticles(this.selectedItemForGroupAndText);
      this.showTextDialog = false;
      this.selectedItemForGroupAndText = null;
    },
    cancelTextAndGroupToArticle(item) {
      this.tempItemGroup = null;
      this.tempItemGroup = null;
      this.showTextDialog = false;
      this.selectedItemForGroupAndText = null;
    },
    saveOrder(event) {
      console.log(
        "moving",
        event.oldIndex,
        event.newIndex,
        event.oldIndex - 1,
        event.newIndex - 1
      );
      // const movedItem = this.selectedArticles.splice(event.oldIndex, 1)[0];
      // this.selectedArticles.splice(event.newIndex, 0, movedItem);
      // this.srno = 0;
      // this.selectedArticles.forEach((element) => {
      //     element.srno = this.srno ++;
      // })
      let tempArticles = this.selectedArticles;
      this.selectedArticles = [];
      this.$nextTick(() => {
        this.selectedArticles = this.swap_article_array_elements(
          tempArticles,
          event.oldIndex - 1,
          event.newIndex - 1
        );
      });
    },
    row_classes(item) {
      // if(item.)
      return "articles_row_item";
    },
    reverseCalculate(total, thtva) {
      this.pauseWatchForUnitPrice = true;
      if (total) {
        // x = total/quantity(1+ tva/100)
        // (unit price * quantity) * ( 1 -  red_percentage /100)( 1 + tva /100)
        console.log("total", total, this.quantity);
        this.unit_price = parseFloat(
          total /
            (this.quantity *
              (1 - this.red_percentage / 100) *
              (1 + this.first_row_tva.tax / 100))
        );
        this.rounded_unit_price = parseFloat(
          total / (this.quantity * (1 + this.first_row_tva.tax / 100))
        ).toFixed(this.getDecimalNumber());
        this.calculateFields(true, true, true, false);
      }
      if (thtva) {
        //  thtva = unit_price * quantity (1 - r/100 )
        this.unit_price = parseFloat(
          thtva / (this.quantity * (1 - this.red_percentage / 100))
        );
        this.rounded_unit_price = parseFloat(thtva / this.quantity).toFixed(
          this.getDecimalNumber()
        );
        console.log("THTVA", this.thtva / this.quantity);
        this.calculateFields(true, true, false, true);
      }
    },
    getProposedArticles(
      article_id,
      family_article_id,
      family_client_id,
      quantity
    ) {
      axios
        .get(
          API_BASE_URL +
            "/articles/proposed/" +
            article_id +
            "?family_article_id=" +
            family_article_id +
            (family_client_id ? "&family_client_id=" + family_client_id : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          data.data.forEach((proposedArticle) => {
            console.log("article to add", proposedArticle);
            let unit_price = proposedArticle.price ?? 0.0;
            let rounded_unit_price = proposedArticle.price ?? 0.0;
            let first_row_tariff = parseFloat(
              proposedArticle.article.tariff_price ?? 0
            );
            let first_row_thtva = 0;
            let first_row_total = 0;
            let red_percentage = 0;

            console.log("proposed article after", proposedArticle.article);

            // check if article already exists in the datatable
            let alreadyExistsProposedArticle = this.selectedArticles.find(
              (element, index) => {
                if (
                  typeof element.article == "object" &&
                  element.article.id == proposedArticle.article.id
                ) {
                  this.selectedArticles.splice(index, 1);
                  return element;
                }
                if (
                  typeof element.article == "number" &&
                  element.article == proposedArticle.article.id
                ) {
                  this.selectedArticles.splice(index, 1);
                  return element;
                }
              }
            );
            if (alreadyExistsProposedArticle) {
              // recalculate the row total
              quantity =
                parseFloat(quantity) +
                parseFloat(alreadyExistsProposedArticle.quantity);
              unit_price = parseFloat(unit_price);
              // this.first_row_tva = parseFloat(this.article.internal_company_article.length > 0 ? this.article.internal_company_article[0].tva.tax : 17);
              first_row_thtva = parseFloat(
                unit_price * quantity -
                  (unit_price * quantity * red_percentage) / 100
              );
              first_row_total = parseFloat(
                parseFloat(first_row_thtva) +
                  parseFloat((this.first_row_tva.tax * first_row_thtva) / 100)
              );
            }

            let isToMultiplyWithCredNoteMulValue = false;
            // if (this.billType && this.billType.key == "credit_note") {
            //   isToMultiplyWithCredNoteMulValue = true;
            // }

            let thtva = parseFloat(
              unit_price * quantity - (unit_price * quantity * 0.0) / 100
            );

            this.resetMeasurementUnit();
            
            this.selectedArticles.push({
              srno: this.rowNumber++,
              article: proposedArticle.article,
              article_original_name: proposedArticle.article,
              quantity: quantity,
              unit_price: parseFloat(
                isToMultiplyWithCredNoteMulValue
                  ? parseInt(this.creditNoteMultiplicationValue.value) *
                      (unit_price ?? 0)
                  : unit_price ?? 0
              ),
              rounded_unit_price: parseFloat(
                isToMultiplyWithCredNoteMulValue
                  ? parseInt(this.creditNoteMultiplicationValue.value) *
                      (rounded_unit_price ?? 0)
                  : rounded_unit_price ?? 0
              ).toFixed(this.getDecimalNumber()),
              red_percentage: red_percentage,
              tarrif: proposedArticle.article.tariff_price ?? 0,
              tva: this.first_row_tva,
              thtva: parseFloat(
                isToMultiplyWithCredNoteMulValue
                  ? parseInt(this.creditNoteMultiplicationValue.value) *
                      (thtva ?? 0)
                  : thtva ?? 0
              ),
              stock_property: null,
              total: parseFloat(
                isToMultiplyWithCredNoteMulValue
                  ? parseInt(this.creditNoteMultiplicationValue.value) *
                      (parseFloat(
                        parseFloat(thtva) +
                          parseFloat((this.first_row_tva.tax * thtva) / 100)
                      ) ?? 0)
                  : parseFloat(
                      parseFloat(thtva) +
                        parseFloat((this.first_row_tva.tax * thtva) / 100)
                    ) ?? 0
              ),
              text: null,
              row: 0,
              text_position: 0,
              depot_out_id: null,
              depot_in_id: null,
              dots: null,
              group: null,
              selectedmu: ''
            });
            this.resetSrNo();
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    confirmationButtonClicked(buttonClicked) {
      this.confirmationDialogButtonClickedIs = buttonClicked;
      this.confirmationDialog = false;
    },
    debounceInput: _.debounce(function(val) {}, 500),
    looseFocusFromBillBox() {
      console.log(
        "loose focus ",
        document.getElementById("billing-form").click()
      );
    },
    goBackToListing() {
      if (this.$store.state.editId) {
        // if the user has came from the listing page and clicks on close button then redirect him to the last page visited
        this.$router.go(-1);
      }
    },
    changeDialog() {
      this.dialog = false;
      this.$nextTick(() => this.$refs["tableQuantity"].focus());
    },
    resetSelectedArticle() {
      this.article = "";
      this.unit_price = 0.0;
      this.red_percentage = 0.0;
      this.first_row_thtva = 0.0;
      this.first_row_total = 0.0;
      this.quantity = this.selectedFamilyArticle
        ? this.selectedFamilyArticle.default_quantity
        : 4;
      this.resetMeasurementUnit();
      this.parentGroupText = null;
      this.isEditModeForDataTableItem = false;
      this.selectedItemIndexInDataTable = null;
    },
    showDialog() {
      this.parentSearchSelectedArticleId = null;
      this.parentSearchSelectedArticleSupplierDetail = null;
      this.dialog = true;
    },
    getTvas() {
      axios
        .get(API_BASE_URL + "/tvas?by_auth=1", { headers: this.header })
        .then(({ data }) => {
          // this.tvas.push(parseFloat(0));
          // data.forEach((item, index)  => {
          //   this.tvas.push(parseFloat(item.tax));
          // });

          // this.tvas = this.tvas.sort(function(a, b) {
          //   return b - a;
          // });
          this.first_row_tva = this.fixDecimal(data.tax);
          this.cached_first_row_tva = this.fixDecimal(data.tax);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    featchClientDocumentRecurrenceByID(id) {
      return new Promise((resolve, reject) => {
        console.log("id", id);
          if (id) {
              
              axios
                  .get(API_BASE_URL + "/client_document_recurrences?id=" + id, {
                      headers: this.header,
                  })
                  .then(({ data }) => {
                      console.log(data,"==============", data.data);
                      if(data.data.length > 0){
                        let client_recurrence_document = data.data[0];
                        this.selectedRecurrence = client_recurrence_document.recurrence_document;
                        this.selectedArticles = client_recurrence_document.recurrence_document.details;

                        let isCompany = !!client_recurrence_document.client.company_id;
                        console.log("parent entity type", isCompany ? this.entityTypeSelectItems[1] : this.entityTypeSelectItems[0],client_recurrence_document.client);
                        this.parent_entityType = isCompany ? this.entityTypeSelectItems[1] : this.entityTypeSelectItems[0];
                        this.parent_company_client_name = client_recurrence_document.client;
                        this.parent_company_client_names = [client_recurrence_document.client];
                        this.parent_familyClient = client_recurrence_document.client.family_client_id;
                        
                        
                        this.parent_company_client_email = client_recurrence_document.client.email;
                        this.parent_company_client_gsm = client_recurrence_document.client.gsm;
                        this.parent_company_client_phone = client_recurrence_document.client.phone;

                        this.recurring_name = client_recurrence_document.name;
                        this.start_at = client_recurrence_document.start_at;
                        this.end_at = client_recurrence_document.end_at;
                        this.run_day = client_recurrence_document.run_day;
                        this.run_month = client_recurrence_document.run_month;
                        if (client_recurrence_document.client_notification_type_id) { // Set Notification Type
                          this.notification_types.forEach((notification_type) => {
                            if (notification_type.id == client_recurrence_document.client_notification_type_id) {
                              this.notification_type = notification_type;
                            }
                          });
                        }
                        this.is_active = client_recurrence_document.is_active;
                        this.isPageLoaded = true;
                        resolve();
                      }
                  })
                  .catch((err) => {
                      console.log(err);
                      this.isLoadingArticle = false;
                      this.isPageLoaded = true;
                      this.$router.push("/client_recurrence_document/add").catch(() => {});
                  })
                  .finally(
                      () => (
                          (this.isLoadingArticle = false),
                              (this.isPageLoaded = true)
                      )
                  );
          } else {
              this.$router.push("/client_recurrence_document/add").catch(() => {});
          }
      })
    },
    checkIfFamilyClientEmpty(event) {
      if (!this.parent_familyClient) {
        this.$refs["clientComp"].$refs["family_client"].focus();
        this.$refs["clientComp"].$refs["family_client"].$touch;
        event.preventDefault();
      }
    },
    jumpToField: function(event, ref) {
      this.$refs[ref].focus();
    },
    checkIfMinusPressed(event) {
      if (/^[/-]$/i.test(event.key)) {
        this.unit_price =
          this.unit_price * parseInt(this.creditNoteMultiplicationValue.value);
      }
    },
    onResetPageData() {
      // // this.resetBillType();
      // this.$store.commit("setValidation", false);
      // this.selectedRecurrence = null;
      // this.selectedArticles = [];
      // this.bill_amount = "";
      // this.recurrences = [];
      // this.parent_reg_date = null;
      // this.reference_client = null;
      // this.unit_price = null;
      // this.quantity = this.selectedFamilyArticle
      //   ? this.selectedFamilyArticle.default_quantity
      //   : 4;
      // this.first_row_tariff = null;
      // this.first_row_thtva = null;
      // this.first_row_tva = this.cached_first_row_tva; // Reset TVA to company's Default TVA
      // this.first_row_total = null;
      // this.red_percentage = 0.0;
      // this.unit_price = 0.0;
      // this.next_event_code = null;
      // this.finedSelectedArticles = [];
      // this.articles = [];
      // this.search_article = null;

      // // Client Component Sync Values
      // this.parent_client_id = null;
      // this.parent_company = null;
      // this.parent_client_id = null;
      // this.parent_company_client_name = null;
      // this.parent_company_client_first_name = null;
      // this.parent_company_client_names = [];
      // this.parent_search_client = null;
      // this.parent_company_client_phone = null;
      // this.parent_company_client_gsm = null;
      // this.parent_company_client_fax = null;
      // this.parent_company_client_email = null;
      // this.parent_company_client_language = null;
      // this.parent_zipcode = null;
      // this.parent_web = null;
      // this.parent_currency = null;
      // this.parent_representative_company_id = null;
      // this.parent_user = null;
      // this.parent_note = null;
      // this.parent_familyClient = null;
      // this.parent_denomination = null;
      // this.parent_registration_code = null;
      // this.parent_tva = null;
      // this.parent_address = null;
      // this.parent_city = null;
      // this.parent_county = null;
      // this.parent_country = null;
      // this.parent_shipping_address = null;
      // this.parent_shipping_city = null;
      // this.parent_shipping_county = null;
      // this.parent_shipping_country = null;
      // this.parent_shipping_zipcode = null;
      // this.parent_address_same_as = false;
    },
    onResetPageRecurrenceData(){
      this.selectedRecurrence = null;
      this.start_at = null;
      this.end_at = null;
      this.run_day = null;
      this.run_month = null;
      this.notification_type = null;
      this.reduction_on_order = 0;
      this.is_active = false;
    },
    convertCommaToDecimalSeparator(str) {
      return parseFloat((str + "").replace(",", ".").replace(" ", ""));
    },
    addArticleToDataTable: function(e) {
      if (e) {
        e.preventDefault();
      }
      if (!(this.article && this.quantity && this.red_percentage <= 100)) {
        return false;
      }
      // resetting the article id in local storage
      var articleFilters = JSON.parse(
        localStorage.getItem("ARTICLE_MODEL_FILTERS")
      );
      if (articleFilters) {
        articleFilters["id"] = null;
      }
      localStorage.setItem(
        "ARTICLE_MODEL_FILTERS",
        JSON.stringify(articleFilters)
      );

      let isToMultiplyWithCredNoteMulValue = false;
      // if (this.billType && this.billType.key == "credit_note") {
      //   isToMultiplyWithCredNoteMulValue = true;
      // }

      console.log("Is Edit Mode for data table item", this.isEditModeForDataTableItem);
      if(this.isEditModeForDataTableItem){ // If article is being edited then modify that particular item quantity and other tdetails
        this.selectedArticles[this.selectedItemIndexInDataTable].quantity = this.quantity;
        this.selectedArticles[this.selectedItemIndexInDataTable].selectedmu = this.currentMu;
        this.selectedArticles[this.selectedItemIndexInDataTable].red_percentage = this.red_percentage;
        this.selectedArticles[this.selectedItemIndexInDataTable].tarrif = this.first_row_tariff;
        this.selectedArticles[this.selectedItemIndexInDataTable].supplier = this.article.supplier;
        this.selectedArticles[this.selectedItemIndexInDataTable].unit_price = this.unit_price;
        this.selectedArticles[this.selectedItemIndexInDataTable].rounded_unit_price = parseFloat(
          this.convertCommaToDecimalSeparator(this.unit_price) ?? 0
        ).toFixed(this.getDecimalNumber());
        this.selectedArticles[this.selectedItemIndexInDataTable].tva = parseFloat(this.first_row_tva);
        this.selectedArticles[this.selectedItemIndexInDataTable].thtva = parseFloat(this.first_row_thtva);
        this.selectedArticles[this.selectedItemIndexInDataTable].total = parseFloat(this.first_row_total);
        this.selectedArticles[this.selectedItemIndexInDataTable].group = this.parentGroupText ?? null;

        console.log("selected article", this.selectedArticles[this.selectedItemIndexInDataTable], this.unit_price);
        this.calculateTotals();
        // Reset Edit Mode for DataTable
        this.resetSelectedArticle();
      }
      else{ // If New Article is added
        console.log("else entered")
        this.unit_price = parseFloat(
          this.convertCommaToDecimalSeparator(this.unit_price) ?? 0
        );
        this.rounded_unit_price = parseFloat(
          this.convertCommaToDecimalSeparator(this.unit_price) ?? 0
        ).toFixed(this.getDecimalNumber());
        this.red_percentage = parseFloat(this.red_percentage);
        // Add Related Articles
        console.log("resetting reseted measurement unit", this.currentMu);
        this.$nextTick(() => {
          this.resetMeasurementUnit();
        })
        this.getProposedArticles(
          this.article.id,
          this.article.family_id,
          this.parent_familyClient,
          parseFloat(this.quantity)
        );

        // check if article already exists in the datatable
        let alreadyExistsArticle = this.selectedArticles.find(
          (element, index) => {
            if (
              typeof element.article == "object" &&
              element.article.id == this.article.id &&
              (element.group ?? "").toUpperCase() ===
                (this.parentGroupText ?? "").toUpperCase()
            ) {
              this.selectedArticles.splice(index, 1);
              return element;
            }
            if (
              typeof element.article == "number" &&
              element.article == this.article.id &&
              (element.group ?? "").toUpperCase() ===
                (this.parentGroupText ?? "").toUpperCase()
            ) {
              this.selectedArticles.splice(index, 1);
              return element;
            }
          }
        );
        if (alreadyExistsArticle) {
          // recalculate the row total
          this.quantity =
            parseFloat(this.quantity) + parseFloat(alreadyExistsArticle.quantity);
          this.first_row_tariff = parseFloat(this.article.tarrif ?? 0);
          this.unit_price = parseFloat(this.unit_price);
          // this.first_row_tva = parseFloat(this.article.internal_company_article.length > 0 ? this.article.internal_company_article[0].tva.tax : 17);
          this.first_row_thtva = parseFloat(
            this.unit_price * this.quantity -
              (this.unit_price * this.quantity * this.red_percentage) / 100
          );
          this.first_row_total = parseFloat(
            parseFloat(this.first_row_thtva) +
              parseFloat((this.first_row_tva.tax * this.first_row_thtva) / 100)
          );
        }
        this.selectedArticles.push({
          srno: this.rowNumber++,
          article: this.article,
          article_original_name: this.article,
          quantity: this.quantity,
          unit_price: parseFloat(
            isToMultiplyWithCredNoteMulValue
              ? parseInt(this.creditNoteMultiplicationValue.value) *
                  (this.unit_price ?? 0)
              : this.unit_price ?? 0
          ),
          rounded_unit_price: parseFloat(
            isToMultiplyWithCredNoteMulValue
              ? parseInt(this.creditNoteMultiplicationValue.value) *
                  (this.unit_price ?? 0)
              : this.unit_price ?? 0
          ).toFixed(this.getDecimalNumber()),
          red_percentage: parseFloat(this.red_percentage ?? 0.0),
          tarrif: this.first_row_tariff,
          tva: this.first_row_tva.tax,
          thtva: parseFloat(
            isToMultiplyWithCredNoteMulValue
              ? parseInt(this.creditNoteMultiplicationValue.value) *
                  (this.first_row_thtva ?? 0)
              : this.first_row_thtva ?? 0
          ),
          stock_property: null,
          total: parseFloat(
            isToMultiplyWithCredNoteMulValue
              ? parseInt(this.creditNoteMultiplicationValue.value) *
                  (this.first_row_total ?? 0)
              : this.first_row_total ?? 0
          ),
          text: null,
          row: 0,
          text_position: 0,
          depot_out_id: null,
          depot_in_id: null,
          dots: null,
          supplier: this.article.supplier,
          supplier_stock_detail: this.article.supplier_stock_detail,
          group: this.parentGroupText ?? null,
          selectedmu: this.currentMu,
        });
        this.resetFirstRowValues();
        this.resetSrNo();
      }
    },
    resetSrNo() {
      let sno = 0;
      this.selectedArticles.forEach((element) => {
        element.srno = sno;
        sno++;
      });
      this.rowNumber = sno;
    },
    resetFirstRowValues() {
      this.article = "";
      this.quantity = this.selectedFamilyArticle
        ? this.selectedFamilyArticle.default_quantity
        : 4;
      this.red_percentage = 0.0;
      this.unit_price = 0.0;
      this.first_row_tariff = "";
      this.first_row_thtva = "";
      this.first_row_total = "";
    },
    deleteItem(item) {
      this.selectedArticles.find((element, index) => {
        if (element.srno == item.srno) {
          this.selectedArticles.splice(index, 1);
          this.resetSrNo();
          return true;
        }
      });
    },
    resetMeasurementUnit(){
      this.currentmu = this.selectedFamilyArticle.mu;
      console.log("resetting measurement unit", this.currentmu);
    },
    onSubmit() {
      this.$store.commit("setValidation",true); 
      if (!this.$v.$invalid) {
        if (this.$store.state.editId) {
          this.updateRecurrence(this.makeData());
        } else {
          this.addRecurrence(this.makeData());
        }
      }
      else{
        this.$toast.warning(this.$t('please_fill_form_correctly'))
      }
    },
    addRecurrence(data) {
        
        axios.post(API_BASE_URL + '/client_document_recurrences', data, {headers: this.header})
        .then(response => {
            this.$router.push("/client_recurrence_document").catch(() => {});
        })
        .catch(function (error) {
          this.$toast.error( this.$t('client_recurrence_document.text_messages.something_went_wrong'));
        })
        .finally(() => {
          
        });
    },
    updateRecurrence (data) {
        
        axios.put(API_BASE_URL + '/client_document_recurrences/' + this.$store.state.editId , data, {headers: this.header})
        .then(response => {
            this.$router.push("/client_recurrence_document").catch(() => {});
        })
        .catch(function (error) {
          console.log('an error occured ' + error);
          this.$toast.error( this.$t('client_recurrence_document.text_messages.something_went_wrong'));
        })
        .finally(() => {
          
        });
    },
    makeData(){
      return {
        client: this.makeClientData(),
        recurrence_id: this.selectedRecurrence ? this.selectedRecurrence.id : null,
        start_at: this.start_at ? this.start_at : null,
        end_at: this.end_at ? this.end_at : null,
        run_day: this.run_day ? this.run_day : null,
        run_month: this.run_month ? this.run_month : null,
        notification_type: this.notification_type ? this.notification_type.id : null,
        is_active: this.is_active ? this.is_active : false,
        document_type: this.document_type ? this.document_type.id : null,
        // articles: this.finedSelectedArticles
      };
    },
    deleteClientRecurrence() {
      
      axios
        .delete(API_BASE_URL + "/client_document_recurrences/" + this.$store.state.editId, {
          headers: this.header,
        })
        .then((data) => {
          this.$toast.success(
            this.$t("client_recurrence_document.text_messages.client_recurrence_document_deleted_successfully")
          );
          this.$router.push("/client_recurrence_document").catch(() => {});
        })
        .catch((error) => {
          this.$toast.error(
            this.$t("client_recurrence_document.text_messages." + error.response.data.errors[0])
          );
        })
        .finally(() => {
          
        });
    },
    deletePopUp() {
      this.confirmationDialogConfirmationText = this.$t(
        "client_recurrence_document.delete_confirmation"
      );
      this.confirmationDialogFalseText = this.$t("clients.cancel");
      this.confirmationDialogTrueText = this.$t("clients.delete");
      this.confirmationDialogOperation = "delete";
      this.confirmationDialog = true;
    },
    onDelete() {
      console.log("delete dialog");
      this.DeleteConfirm = true;
      this.deletePopUp();
    },
    makeClientData() {
      return {
        client_id: this.parent_company_client_name
          ? typeof this.parent_company_client_name == "object"
            ? this.parent_company_client_name.id
            : null
          : null,
        entityType: this.parent_entityType ? this.parent_entityType.id : 0, // By Default Private Client
        denomination: this.parent_denomination ? (typeof this.parent_denomination == 'object' ? this.parent_denomination.id : this.parent_denomination) : null,
        familyClient: this.parent_familyClient
          ? typeof this.parent_familyClient == "object"
            ? parseInt(this.parent_familyClient.id)
            : this.parent_familyClient
          : null,

        name: this.parent_company_client_name
          ? typeof this.parent_company_client_name == "object"
            ? this.parent_company_client_name.name
            : this.parent_company_client_name
          : null,
        first_name: this.parent_company_client_first_name
          ? typeof this.parent_company_client_first_name == "object"
            ? this.parent_company_client_first_name.name
            : this.parent_company_client_first_name
          : null,
        email: this.parent_company_client_email ?? null,
        language: this.parent_company_client_language
          ? typeof this.parent_company_client_language == "object"
            ? this.parent_company_client_language.id
            : this.parent_company_client_language
          : null,
        fax: this.parent_company_client_fax ?? null,
        gsm: this.parent_company_client_gsm ?? null,
        phone: this.parent_company_client_phone ?? null,
        note: this.parent_note ?? null,
        tva: this.parent_tva ?? null,
        web: this.parent_web ?? null,
      };
    },
    makeVehicleData() {
      return {
        vehicle_id:
          this.parent_registration_no &&
          typeof this.parent_registration_no == "object"
            ? this.parent_registration_no.id
            : null,
        vehicle_type_id:
          this.parent_vehicle_type &&
          typeof this.parent_vehicle_type == "object"
            ? this.parent_vehicle_type.id
            : null,
        registration_no:
          this.parent_registration_no &&
          typeof this.parent_registration_no == "object"
            ? this.parent_registration_no.registration_number
            : this.parent_registration_no,
        brand: this.parent_brand
          ? typeof this.parent_brand == "number"
            ? this.parent_brand
            : typeof this.parent_brand == "object"
            ? this.parent_brand.id
            : null
          : null,
        model: this.parent_model ?? null,
        km: this.parent_km ?? null,
        reg_date: this.parent_reg_date ?? null,
        vin: this.parent_vin ?? null,
        is_leasing: this.parent_is_leasing ?? null,
        creditor_id: this.parent_creditor
          ? typeof this.parent_creditor == "object"
            ? this.parent_creditor.id
            : this.parent_creditor
          : null,
      };
    },
    convertDateTimeToString(datetime) {
      if (datetime) {
        datetime = new Date(datetime);
        return (
          datetime.toISOString().substring(0, 10) +
          " " +
          ("0" + datetime.getHours()).slice(-2) +
          ":" +
          ("0" + datetime.getMinutes()).slice(-2)
        );
      } else {
        return null;
      }
    },
    setCurrentFormOperation() {
      this.operation = this.$route.path.indexOf("/add") > 0 ? "add" : "edit";
    },
    updatePagination(page) {
      // this.page = page;
      // this.fetchData();
    },
    updateItemsPerPage(itemsPerPage) {
      // console.log("----------",itemsPerPage == -1 ? this.totalItems : itemsPerPage);
      // this.itemsPerPage = (itemsPerPage == -1 ? this.totalItems : itemsPerPage)
      // this.fetchData();
      // this.itemsPerPage = (this.itemsPerPage == -1 ? -1 : itemsPerPage);
    },
    clearArticleList() {
      this.articles = [];
    },
    fetchArticleListDebounced() {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchArticlesList();
      }, 500); /* 500ms throttle */
    },
    fetchArticlesList() {
      if (!this.search || typeof this.search == "object") {
        return;
      }
      this.clearArticleList();
      axios
        .get(
          API_BASE_URL +
            "/billings/articles/search?page=1&items_per_page=10" +
            (this.parent_familyClient
              ? "&family_client_for_pricing=" + this.parent_familyClient
              : "") +
            (this.search ? "&search=" + encodeURIComponent(this.search) : "") +
            (this.parentInternalStockOnly ? "&internal_stock=true" : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          if (data.data.length == 0) {
            this.$toast.error(
              this.$t(
                "scheduler.appointment.no_pricing_found_for_this_article"
              )
            );
          }
          this.articles = data.data;
        })
        .catch((err) => {
          console.log(err);
          this.isLoadingArticle = false;
        })
        .finally(() => (this.isLoadingArticle = false));
    },
    fetchRecurrencesSuggesListDebounced() {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchRecurrencesSuggesList();
      }, 500); /* 500ms throttle */
    },
    fetchRecurrencesSuggesList(bypass = false) {
      if (!bypass && (!this.search_recurrence || typeof this.search_recurrence == "object")) {
        return;
      }
      // this.clearArticleList()
      axios
        .get(
          API_BASE_URL +
            "/recurring_documents?page=1&items_per_page=20" +
            (this.search_recurrence ? "&search=" + encodeURIComponent(this.search_recurrence) : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          this.recurrences = data.data;
          if(bypass){
            this.defaultListOfRecurrences = this.recurrences;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoadingSearchRecurrence = false;
        })
        .finally(() => (this.isLoadingSearchRecurrence = false));
    },
    calculateFields(UpdateQuantity, updateUnitPrice, updateTHTVA, updateTotal) {
      // this.unit_price = this.convertCommaToDecimalSeparator(this.unit_price);
      // this.first_row_tariff = parseFloat(this.article.tarrif ?? 0);
      // this.unit_price = "";
      if (UpdateQuantity) {
        // this.first_row_thtva = parseFloat((this.unit_price * this.quantity) - ( (this.unit_price * this.quantity) * this.red_percentage) /100);
      }
      if (updateUnitPrice) {
        // this.first_row_thtva = parseFloat((this.unit_price * this.quantity) - ( (this.unit_price * this.quantity) * this.red_percentage) /100);
      }
      if (updateTHTVA) {
        this.first_row_thtva = parseFloat(
          this.unit_price * this.quantity -
            (this.unit_price * this.quantity * this.red_percentage) / 100
        );
      }
      if (updateTotal) {
        this.first_row_total = parseFloat(
          parseFloat(this.first_row_thtva) +
            parseFloat((this.first_row_tva.tax * this.first_row_thtva) / 100)
        );
      }
    },
    calculateColumns(changeUnitPrice) {
      if(this.isEditModeForDataTableItem){ // do not update column values if an article is in edit mode
        this.unit_price = this.convertCommaToDecimalSeparator(this.unit_price);
        this.first_row_tariff = parseFloat(this.article.tarrif ?? 0);
        this.rounded_unit_price = parseFloat(this.unit_price).toFixed(
          this.getDecimalNumber()
        ),
        // this.first_row_tva = parseFloat(this.article.internal_company_article.length > 0 ? this.article.internal_company_article[0].tva.tax : 17);
        this.first_row_thtva = parseFloat(
          this.unit_price * this.quantity -
            (this.unit_price * this.quantity * this.red_percentage) / 100
        );
        this.first_row_total = parseFloat(
          parseFloat(this.first_row_thtva) +
            parseFloat((this.first_row_tva.tax * this.first_row_thtva) / 100)
        );
        this.calculateTotals();
        return
      }
      if (this.article) {
        this.unit_price = this.convertCommaToDecimalSeparator(this.unit_price);
        console.log(this.unit_price, "dot sep");
        this.first_row_tariff = parseFloat(this.article.tarrif ?? 0);
        this.unit_price = parseFloat(
          changeUnitPrice
            ? this.unit_price
            : this.article.internal_company_article
            ? this.article.internal_company_article.length > 0
              ? this.article.internal_company_article[0].price
              : 0
            : 0.0
        );
        (this.rounded_unit_price = parseFloat(this.unit_price).toFixed(
          this.getDecimalNumber()
        )),
          // this.first_row_tva = parseFloat(this.article.internal_company_article.length > 0 ? this.article.internal_company_article[0].tva.tax : 17);
          (this.first_row_thtva = parseFloat(
            this.unit_price * this.quantity -
              (this.unit_price * this.quantity * this.red_percentage) / 100
          ));
        this.first_row_total = parseFloat(
          parseFloat(this.first_row_thtva) +
            parseFloat((this.first_row_tva.tax * this.first_row_thtva) / 100)
        );
      } else {
        this.unit_price = this.convertCommaToDecimalSeparator(this.unit_price);
        (this.rounded_unit_price = parseFloat(this.unit_price).toFixed(
          this.getDecimalNumber()
        )),
          // this.first_row_tva = parseFloat(this.article.internal_company_article.length > 0 ? this.article.internal_company_article[0].tva.tax : 17);
          (this.first_row_thtva = parseFloat(
            this.unit_price * this.quantity -
              (this.unit_price * this.quantity * this.red_percentage) / 100
          ));
        this.first_row_total = parseFloat(
          parseFloat(this.first_row_thtva) +
            parseFloat((this.first_row_tva.tax * this.first_row_thtva) / 100)
        );
      }
    },
    calculateTotals() {
      let thtva_total = 0;
      let total_total = 0;
      this.selectedArticles.forEach((element) => {
        thtva_total += parseFloat(element.thtva);
        total_total += parseFloat(element.total);
      });
      this.articleTHTVATotalBeforeReduction = parseFloat(thtva_total);
      this.articleTHTVATotal = this.reduction_on_order ? (parseFloat(this.articleTHTVATotalBeforeReduction  - (this.articleTHTVATotalBeforeReduction * this.reduction_on_order / 100))) : this.articleTHTVATotalBeforeReduction;
      this.articleTotalBeforeReduction = parseFloat(total_total);
      this.articleTotal = this.reduction_on_order ? (parseFloat(this.articleTotalBeforeReduction - (this.articleTotalBeforeReduction * this.reduction_on_order / 100))) : this.articleTotalBeforeReduction;
      this.total_reduction = parseFloat((this.articleTotalBeforeReduction ?? 0) * ((this.reduction_on_order ?? 0) /100)).toFixed(this.getDecimalNumber());
    },
    fineDataForSending() {
      this.finedSelectedArticles = [];
      this.selectedArticles.forEach((element) => {
        console.log("Fined selected articles", element.supplier_stock_detail);
        console.log("fine data for sending", element);
        this.finedSelectedArticles.push({
          article_id:
            element.depot_in_id || element.depot_out_id
              ? 0
              : typeof element.article == "object"
              ? element.article.id
              : element.article, // If element has text property then we will send the article id as 0
          // company_id: element.article.company_id,
          // supplier_id: element.article.supplier_id,
          quantity: element.quantity,
          red_percentage: parseFloat(element.red_percentage ?? 0),
          tarrif: parseFloat(element.tarrif),
          thtva: parseFloat(element.thtva),
          total: parseFloat(element.total),
          tva: element.tva,
          stock_property: element.stock_property
            ? typeof element.stock_property == "object"
              ? element.stock_property.property
              : element.stock_property
            : null,
          unit_price: parseFloat(element.unit_price),
          rounded_unit_price: parseFloat(element.rounded_unit_price),
          text: element.text,
          row: element.row,
          text_position: element.text_position ?? 0,
          depot_out_id: element.depot_out_id,
          depot_in_id: element.depot_in_id,
          supplier_id:
            element.supplier &&
            element.supplier.length > 0 &&
            element.supplier[0] &&
            element.supplier[0].id
              ? element.supplier[0].id
              : null,
          supplier_stock_detail: element.supplier_stock_detail,
          group: element.group,
          selectedmu: element.selectedmu.id,
        });
      });
    },
    fetchClientBalance(client_id) {
      
      axios
        .get(
          API_BASE_URL +
            "/clients/" +
            client_id +
            "/pending/bill?return_total=1",
          { headers: this.header }
        )
        .then(({ data }) => {
          this.customer_balance = this.fixDecimal(data.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          
        });
    },
    deleteSupplierOrder() {
      
      console.log("supplier order", this.lastSupplierOrderCreated);
      axios
        .delete(
          API_BASE_URL + "/suppliers/order/" + this.lastSupplierOrderCreated.id,
          { headers: this.header }
        )
        .then(({ data }) => {})
        .catch(function(error) {
          console.log("an error occured " + error);
        })
        .finally(() => {
          
        });
    },
    updatePricingForSelectedArticle(val){
      console.log(
        "parent search article ======",
        val,
        this.parentSearchSelectedArticleSupplierDetail
      );
      if (val) {
        let stockItem = val;
        val = typeof val == "object" ? val.id : val;
        console.log(
          "parent search article - inside",
          val,
          this.parentSearchSelectedArticleSupplierDetail
        );
        let supplier = this.parentSearchSelectedArticleSupplierDetail
          ? this.parentSearchSelectedArticleSupplierDetail.supplier_detail
          : null;
        console.log("supplier change triggered", this.parentSearchSelectedArticleSupplierDetail, );
        let supplier_stock_detail = this
          .parentSearchSelectedArticleSupplierDetail
          ? this.parentSearchSelectedArticleSupplierDetail
          : null;
        this.dialog = false;
        // 
        this.showLoadingModel = true;
        axios
          .get(
            API_BASE_URL +
              "/billings/articles/search?page=1&items_per_page=10" +
              (this.parent_familyClient
                ? "&family_client_for_pricing=" + this.parent_familyClient
                : "") +
              ("&article_ids=" + val) +
              (supplier && supplier.id ? "&supplier_id=" + supplier.id : ""),
            { headers: this.header }
          )
          .then(({ data }) => {
            console.log(data.data[0]);
            if (data.data.length == 0) {
              this.$toast.error(
                this.$t(
                  "scheduler.appointment.no_pricing_found_for_this_article"
                )
              );
            }
            this.articles = [data.data[0]];
            this.article = data.data[0];
            this.article.supplier = [supplier];
            console.log("supplier details", this.cachedData["suppliers"], this.selectedFamilyArticle.id);
            this.article.supplier = this.article.supplier.concat(this.cachedData["suppliers"][this.selectedFamilyArticle.id]);
            
            this.isLoadingArticle = false;
            // 

            var articleFilters = JSON.parse(
              localStorage.getItem("ARTICLE_MODEL_FILTERS")
            );
            articleFilters["id"] = val;
            localStorage.setItem(
              "ARTICLE_MODEL_FILTERS",
              JSON.stringify(articleFilters)
            );
          })
          .catch(() => {
            // 
          })
          .finally(() => {
            this.$refs["tableQuantity"].focus();
            // this.parentSearchSelectedArticleId = null;
            
            this.showLoadingModel = false;
          });
      } else {
        this.articles = [];
        this.article = "";
        this.unit_price = 0.0;
        this.red_percentage = 0.0;
      }
    }
  },
  computed: {
    ...mapState(['repeatedArticles']),
    currencySymbol() {
      let symbol = null;
      this.fixBillCurrencySymbol();
      if(this.$store.state.topCurrencyType){
        symbol = this.parseSymbol(this.$store.state.topCurrencyType.symbol ?? '')
        return symbol;
      }
      return symbol ?? ''; 
    },
    selectedSupplier(){
      return this.article ? (this.article.supplier[0] ? this.article.supplier[0] : null) : null 
    },
    parentClientIsBlocked() {
      if (!this.parent_company_client_name) {
        return false;
      }

      if (this.parent_company_client_name.status_id == 10041003) {
        return true;
      }

      return false;
    },
    companyNameLabel() {
      return this.isCompanyEntitySelected ? "Company Name" : "Full Name";
    },
    articles_list() {
      return this.articles;
    },
    // billType() {
    //   return this.$store.state.billType;
    // },
    //   billTypes(){
    //     return this.$store.state.billTypes;
    //   },
    isEditingAllowed() {
      return this.$store.state.isEditingAllowed;
    },
  },
  watch: {
    currencySymbol() {
      return this.currentCurrencySymbol;
    },
    selectedSupplier(val){
      console.log("selected Supplier changed in article supplier supplier change triggered", val);
    },
    repeatedArticles(val){
        this.selectedArticles.push(...val);
    },
    selectedFamilyArticle(val) {
      this.quantity = this.selectedFamilyArticle
        ? this.selectedFamilyArticle.default_quantity
        : 4;
      // set current mu value (measurement value)
      this.currentMu = val ? val.mu.value : '';
      this.red_percentage = 0.0;
      this.unit_price = 0.0;
      this.first_row_tariff = "";
      this.first_row_thtva = "";
      this.first_row_total = "";
    },
    confirmationDialogButtonClickedIs(newVal) {
      if (newVal == true && this.confirmationDialogOperation != null) {
        if (
          this.confirmationDialogOperation == "delete" &&
          this.confirmationDialogButtonClickedIs == true
        ) {
          this.deleteClientRecurrence();
        }
        if (
          this.confirmationDialogOperation == "print_report" &&
          this.confirmationDialogButtonClickedIs == true
        ) {
          this.printReport();
        }
        if (
          this.confirmationDialogOperation == "print_label" &&
          this.confirmationDialogButtonClickedIs == true
        ) {
          this.printLabel();
        }
        if (
          (this.confirmationDialogOperation == "make_bill" ||
            this.confirmationDialogOperation == "make_bill_error") &&
          this.confirmationDialogButtonClickedIs == true
        ) {
          this.makeBill();
        }
        if (
          this.confirmationDialogOperation == "make_purchase_order" &&
          this.confirmationDialogButtonClickedIs == true
        ) {
          this.makePurchaseOrder();
        }
        if (
          this.confirmationDialogOperation == "make_credit_note" &&
          this.confirmationDialogButtonClickedIs == true
        ) {
          this.makeCreditNote();
        }
        if (
          this.confirmationDialogOperation == "keep_trying" &&
          this.confirmationDialogButtonClickedIs == true
        ) {
          if (this.confirmationDialogButtonClickedIs == false) {
            this.deleteSupplierOrder();
          }
        }
        this.confirmationDialog = false;
        this.confirmationDialogButtonClickedIs = null;
      }
    },
    parentSearchSelectedArticleId(val) {
      this.updatePricingForSelectedArticle(val);
    },

    parent_familyClient(val) {
      // if (val) {
      //   this.reCalculateSelectedArticlesPricing();
      // }
    },
    parent_company_client_name(val) {
      this.customer_balance = this.fixDecimal(0);
      if (this.selectedRecurrence && this.selectedRecurrence.vehicle) {
        let billVehicle = val.vehicles.find((vehicle) => {
          return vehicle.id == this.selectedRecurrence.vehicle.id;
        });
        if (billVehicle) {
          val.vehicles.unshift(billVehicle);
        }
        this.parent_company_client_name = val;
      }
      if (val && val.id) {
        this.fetchClientBalance(val.id);
      }
    },
    // billType(val) {
      // this.statuses = [];
      // this.order_status = null;
      // this.filterOutStatuses();
      //   if(val && val.key && val.key == 'purchase_order'){
      //     this.isVisibleOrderStatus = false;
      //   }
      //   else{
      // this.isVisibleOrderStatus = true;
      //   }
    // },
    ispayment_method() {
      // this.payment_method = null;
    },
    selectedArticles() {
      this.calculateTotals();
      this.selectedArticles.forEach((article, index) => {
        // update mus for every article
        console.log("selecting mu", article);
        if(! article.selectedmu){
          article.selectedmu = article.mu;
        }
      });
      this.fineDataForSending();
    },
    article(val) {
      this.calculateColumns();
      if(!this.isEditModeForDataTableItem){ // If article is being edited then modify that particular item quantity and other tdetails
        if(val && val.family && val.family.mu_id){
          console.log("entering family mu", val);
          this.mus.forEach((element) => {
            if(element.id == val.family.mu_id){
              console.log("entering family mu matched", val.family, element.id);
              this.currentMu = element
            } 
          })
        }
      }
    },
    quantity(val) {
      this.calculateColumns(true);
    },
    unit_price(val) {
      if (!val) {
        val = 0;
      }

      this.unit_price = val ?? 0;
      if (this.pauseWatchForUnitPrice) {
        this.pauseWatchForUnitPrice = false;
      } else {
        this.calculateColumns(true);
      }
    },
    red_percentage(val) {
      this.calculateColumns(true);
    },
    search(val) {
      if (
        !val ||
        (this.article && this.article.title_description[0].description == val)
      ) {
        return;
      }
      this.isLoadingArticle = true;
      this.fetchArticleListDebounced();
    },
    search_recurrence(val) {
      if (val && typeof val == "object") {
        this.search_recurrence = val.name;
        this.allowContinueSearch = false;
        return;
      }
      else{
        this.recurrences = this.defaultListOfRecurrences;
      }

      if (val && this.allowContinueSearch) {
        if (
          !val ||
          (this.selectedRecurrence && this.selectedRecurrence.bill_number == val)
        ) {
          return;
        }
        this.isLoadingSearchRecurrence = true;
        this.fetchRecurrencesSuggesListDebounced();
      }
      this.allowContinueSearch = true;
    },
  selectedRecurrence(val) {
    console.log("recurrence selected", val);
      this.fetchBillRelatives(val);
      this.lastSupplierOrderCreated = null;
      if (!this.selectedRecurrence) {
        this.$route.meta.title = "add_recurrence";
        this.onResetPageData();
      }
      if (this.selectedRecurrence) {
        this.recurrences = [this.selectedRecurrence];
        this.$route.meta.title = "edit_recurrence";
        this.selectedArticles = [];
        this.articles = [];
        this.article = "";
        this.document_type = this.selectedRecurrence.document_type;
        console.log("current element", this.selectedRecurrence, this.selectedRecurrence.document_type);
        if (
          this.selectedRecurrence &&
          this.selectedRecurrence.details &&
          this.selectedRecurrence.details.length > 0
        ) {
          this.rowNumber = 0;
          this.selectedRecurrence.details.forEach((element) => {
            let isToMultiplyWithCredNoteMulValue = false;
            console.log("current element", element, element.mu_id);
            let selectedmu = '';
            this.mus.forEach((elementmu) => {
              if(elementmu.id == element.mu_id){
                selectedmu = elementmu
              }
            })
            
            let thtva =
              element.fixed_price * element.quantity -
              (element.fixed_price * element.quantity * element.reduction) / 100;
              console.log("current element thtva", element);
            console.log("current element thtva----", thtva);
            this.selectedArticles.push({
              srno: this.rowNumber++,
              article: element.article_id,
              article_original_name: element.article,
              quantity: element.quantity,
              unit_price: parseFloat(
                isToMultiplyWithCredNoteMulValue
                  ? parseInt(this.creditNoteMultiplicationValue.value) *
                      (element.fixed_price ?? 0)
                  : element.fixed_price ?? 0
              ),
              rounded_unit_price: parseFloat(
                isToMultiplyWithCredNoteMulValue
                  ? parseInt(this.creditNoteMultiplicationValue.value) *
                      (element.rounded_unit_price ?? 0)
                  : element.rounded_unit_price ?? 0
              ),
              red_percentage: parseFloat(element.reduction ?? 0),
              tarrif: parseFloat(element.article.tariff_price),
              tva: element.tva,
              thtva: parseFloat(
                isToMultiplyWithCredNoteMulValue
                  ? parseInt(this.creditNoteMultiplicationValue.value) *
                      (thtva ?? 0)
                  : thtva ?? 0
              ),
              stock_property: element.stock_property ?? null,
              total: parseFloat(
                isToMultiplyWithCredNoteMulValue
                  ? parseInt(this.creditNoteMultiplicationValue.value) *
                      (element.total ?? 0)
                  : element.total ?? 0
              ),
              text: element.text,
              row: element.row,
              text_position: element.text_position ?? 0,
              depot_out_id: element.depot_out_id,
              depot_in_id: element.depot_in_id,
              dots: null,
              group: element.group ?? null,
              selectedmu: selectedmu,
            });
          });
          this.reduction_on_order = this.selectedRecurrence.reduction;
        }
      }
    },
  },
};
</script>

<style scoped>
.right-aligned-input >>> input {
  text-align: right;
}
.customerbalance >>> .theme--light.v-label--is-disabled {
  color: rgb(255, 10, 10) !important;
}
.customerbalance >>> input {
  text-align: right;
  color: rgb(255, 0, 0) !important;
}
.customerbalance >>> .theme--light.v-icon.v-icon.v-icon--disabled {
  color: rgb(255, 0, 0) !important;
}
.articlenotset {
  display: flex !important;
}
.articleset {
  display: none !important;
}
.selectedArticleTextBox {
  background-color: rgb(220, 255, 220);
}
.selectedArticleTextBox .v-input__slot {
  background-color: rgb(220, 255, 220);
}
.articleNotInStock {
  background-color: transparent;
  color: red;
}
.articleInStock {
  background-color: transparent;
  color: green !important;
}
.articleWaitingForSupplierOrder {
  background-color: transparent !important;
  color: red !important;
}
.articleWaitingForSupplierOrderOrange {
  background-color: transparent !important;
  color: orange !important;
}
.dontbreakline {
  white-space: pre !important;
}
.articles_row_item > .text-right {
  padding: 0px !important;
}
</style>
