import ConfirmationModel from "@/components/ConfirmationModel.vue";
export default {
    components:{
        ConfirmationModel
    },
    data(){
        return {
            confirmationDialog: false,
            confirmationDialogConfirmationText: null,
            confirmationDialogFalseText: this.$t('no'),
            confirmationDialogTrueText: this.$t('yes'),
            confirmationDialogButtonClickedIs: null,
            confirmationDialogOperation: null,
            confirmationDialogReCheckBoxConfirmationText: this.$t( "billings.convert_to_credit_note_confirmation_reconfirm_text"),
            confirmationDialogData: null
        }
    },
    methods:{
        confirmationButtonClicked(event,callback){

            if(event){
               callback()
            }
        },
        popConfirmation(text, operation, trueText = null, falseText = null, reCheckBoxConfirmationText = null, data = null){
            this.confirmationDialogConfirmationText = text
            this.confirmationDialogOperation = operation


                this.confirmationDialogData = data

            if(trueText){
                this.confirmationDialogConfirmationText = trueText
            }

            if(falseText){
                this.confirmationDialogFalseText = falseText
            }

            if(reCheckBoxConfirmationText){
                this.confirmationDialogReCheckBoxConfirmationText = reCheckBoxConfirmationText
            }

            this.confirmationDialog = true
        }
    }
}