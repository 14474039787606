<template>
    <v-dialog v-model="modifyDots" max-width="800" @click:outside="closeArticleDotModel()" @keydown.esc="closeArticleDotModel()">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{    rec_item.article.title_description[0].description     }}</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col class="12" sm="3">
                        <span style="color:green"> 
                            <strong> {{ $t('receptions.modify_article.total_received_quantity') }} :  </strong> 
                        </span>
                        <span> 
                            <strong> {{rec_item.received }} </strong> 
                        </span>
                    </v-col>
                    <v-col class="12" sm="4">
                        <span style="color:red"> 
                            <strong> {{ $t('receptions.modify_article.items_not_added_in_dot') }} : </strong> 
                        </span>
                        <span> 
                            <strong> {{ pendingReceivedQuantity() }}  </strong> 
                        </span>
                    </v-col>
                </v-row>
                <v-data-table
                    fixed-header
                    :footer-props="{
                        'items-per-page-options': dataTableOptions
                    }"
                    :headers="headers"
                    :items="dot_items"
                    :items-per-page="itemsPerPage"
                    :options.sync="options"
                    :sort-by="sortBy"
                    dense
                    :loading="isLoading"
                    :loading-text="this.$t('loading')"
                    @update:page="updatePagination"
                    @update:items-per-page="updateItemsPerPage"
                    class="elevation-0"
                    item-key="dot_property"
                    height="30vh"
                    style="width: 100%"
                >
                    <template v-slot:top>
                        <v-toolbar flat class="pt-4">
                            <v-row>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :autocomplete="autocompleteValueLocal"
                                        :disabled="pendingReceivedQuantity() <= 0 "
                                        class="mr-3 pb-5 to-upper"
                                        :label="$t('receptions.modify_article.dot')"
                                        v-bind:clearable="true"
                                        clear-icon="mdi-close"  
                                        v-model="dot_property"
                                        v-on:keydown.enter.prevent="addDotToDataTable()"
                                        v-on:keydown="handleInput($event,'int',4);"
                                        :error-messages="!$v.dot_property.minLength ? [$t('receptions.modify_article.dot_min_length')] : [] "
                                        :outlined="is_boxes"
                                        :dense="is_boxes"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :autocomplete="autocompleteValueLocal"
                                        :disabled="pendingReceivedQuantity() <= 0 "
                                        class="mr-3 to-upper"
                                        :label="$t('receptions.modify_article.quantity')"
                                        v-bind:clearable="true"
                                        clear-icon="mdi-close"  
                                        v-model="quantity"
                                        v-on:keydown.enter.prevent="addDotToDataTable()"
                                        v-on:keydown="handleInput($event,'int',6);"
                                        :error-messages="!$v.quantity.minLength ? [$t('receptions.modify_article.quantity_min_length')] : [] "
                                        :outlined="is_boxes"
                                        :dense="is_boxes"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-btn color="primary" @click="addDotToDataTable()" v-if="pendingReceivedQuantity() > 0 ">
                                        {{ $t('receptions.modify_article.add') }}
                                        <v-icon dark right> mdi-plus-thick </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.quantity="{ item }">
                        <td>
                            <v-text-field
                                :autocomplete="autocompleteValueLocal"
                                style="width:40px"
                                class="mr-3 pb-4 to-upper"
                                v-model="item.quantity"
                                v-on:keydown="handleInput($event,'int',6);"
                                hide-details="auto"
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-text-field>
                        </td> 
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <td align="right">
                            <v-icon color="red" right @click="deleteItem(item.id)"> mdi-delete </v-icon>
                        </td> 
                    </template>
                </v-data-table>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey-lighten-2" @click="closeArticleDotModel()">
                    {{ $t('receptions.modify_article.cancel') }}
                    <v-icon dark right> mdi-keyboard-return </v-icon>
                </v-btn>
                <v-btn color="green" dark @click="submitArticleDotModel()">
                    {{ $t('receptions.modify_article.save') }}
                    <v-icon dark right> mdi-content-save </v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import { validationMixin } from 'vuelidate'
    import { required, email, requiredIf, phoneNum, numeric ,minLength, maxLength, alpha } from 'vuelidate/lib/validators'
    import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from '@/config';
    export default {
        mixins: [validationMixin],
        validations: {
            quantity: { 
                minLength: minLength(1)
            },
            dot_property: { 
                minLength: minLength(4)
            },
        },
        props:{
            rec_item: [Object]
        },
        data() {
            return {
                is_boxes: this.getCompanyPropertyFromLocalStorage('boxes_on_fields', 1),
                dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
                page:             1,
                lastPage:         null,
                totalItems:       null,
                itemsPerPage:     DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
                sortBy:          'description',
                sortDesc:         false,
                options:          {},
                pagination:       {},
                modifyDots: true,
                isLoading: false,
                quantity: null,
                dot_property: null,
                dot_items: [],
                autocompleteValueLocal : 'off',
            };
        },
        mounted(){
            // this.dot_items = this.rec_item.dot_quantities ?? [];
            this.dot_items = Object.assign([], this.rec_item.dot_quantities);
            console.log(this.modifyDots,"into modifying dots")
            this.autocompleteValueLocal = this.autocompleteValue();
        },
        methods:{
            closeArticleDotModel(){
                this.modifyDots = false;
            },
            submitArticleDotModel(){
                let items = Object.assign({}, this.dot_items);
                this.rec_item.dot_quantities = items;
                this.modifyDots = false;
            },
            updatePagination (page) {
                this.page = page;
                this.fetchData();
            },
            updateItemsPerPage (itemsPerPage) {
                this.itemsPerPage = (itemsPerPage == -1 ? this.totalItems : itemsPerPage)
                this.fetchData();
                this.itemsPerPage = (this.itemsPerPage == -1 ? -1 : itemsPerPage);
            },
            addDotToDataTable(){
                if(this.dot_property.length == 4 && this.quantity > 0 && this.pendingReceivedQuantity() > 0 && this.pendingReceivedQuantity() >= parseInt(this.quantity) ){
                    let found = false;
                    this.dot_items.forEach((item) => {
                        if(parseInt(item.dot_property) == parseInt(this.dot_property)){
                            item.quantity = parseInt(item.quantity) + parseInt(this.quantity);
                            found = true
                        }
                    })
                
                    if(! found){
                        this.dot_items.push({
                            id: this.dot_items?.length + 1,
                            dot_property: this.dot_property,
                            quantity: this.quantity
                        });
                    }
                    this.dot_property= null;
                    this.quantity = null;
                }
            },
            pendingReceivedQuantity(){
                let receivedQuantityInDot = 0;
                this.dot_items.forEach((element) => {
                    if(element.quantity){
                        receivedQuantityInDot += parseInt(element.quantity ?? 0);
                    }
                })
                return parseInt(this.rec_item.received ?? 0) - parseInt(receivedQuantityInDot);
            },
            deleteItem(id){
                this.dot_items.splice(id - 1, 1);
            }
        },
        computed:{
            headers(){
                return [
                    { text: this.$t('receptions.modify_article.dot'),              align: 'start',   sortable: false, value:'dot_property', width:'10' },
                    { text: this.$t('receptions.modify_article.quantity'),         align: 'start',   sortable: false, value:'quantity', width:'530' },
                    { text: '',                                                    align: 'center',   sortable: false, value:'actions', width:'50' }
                ];
            }
        },
        watch:   {
            modifyDots(val){
                this.$emit('change-modify-article-dialog', true);
            },
        }
    }
</script>