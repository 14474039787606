var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.$route.path.includes('pricingcalculator'))?_c('v-card',{attrs:{"id":"navigation"}},[_c('confirmation-model',{attrs:{"showDialog":_vm.confirmationDialog,"openedForOperation":_vm.confirmationDialogOperation,"text":_vm.confirmationDialogConfirmationText,"trueText":_vm.confirmationDialogTrueText,"falseText":_vm.confirmationDialogFalseText},on:{"update:showDialog":function($event){_vm.confirmationDialog=$event},"update:show-dialog":function($event){_vm.confirmationDialog=$event},"update:openedForOperation":function($event){_vm.confirmationDialogOperation=$event},"update:opened-for-operation":function($event){_vm.confirmationDialogOperation=$event},"update:text":function($event){_vm.confirmationDialogConfirmationText=$event},"update:trueText":function($event){_vm.confirmationDialogTrueText=$event},"update:true-text":function($event){_vm.confirmationDialogTrueText=$event},"update:falseText":function($event){_vm.confirmationDialogFalseText=$event},"update:false-text":function($event){_vm.confirmationDialogFalseText=$event},"buttonClicked":_vm.confirmationButtonClicked}}),_c('v-navigation-drawer',{staticStyle:{"z-index":"9"},style:({
            backgroundColor: _vm.menuBackgroundColor,
            fontSize: '14px',
        }),attrs:{"app":"","expand-on-hover":_vm.mini},model:{value:(_vm.$store.state.showNavDrawer),callback:function ($$v) {_vm.$set(_vm.$store.state, "showNavDrawer", $$v)},expression:"$store.state.showNavDrawer"}},[_c('v-list-item',{staticStyle:{"padding-bottom":"8px","border-bottom":"1px solid #585858"}},[_c('v-list-item-content',{staticClass:"admin-head-top"},[_c('div',{staticStyle:{"max-width":"40px !important"}},[(!_vm.profileImage)?_c('v-icon',{attrs:{"color":_vm.menuIconColor || 'white'}},[_vm._v("mdi-account-circle-outline")]):_vm._e(),(_vm.profileImage)?_c('img',{staticStyle:{"width":"35px","height":"35px","border-radius":"50%","background-color":"white","margin-left":"-3px","margin-top":"10px"},attrs:{"src":_vm.profileImage}}):_vm._e()],1),_c('div',[_c('v-list-item-title',{staticClass:"title"},[_c('v-menu',{staticStyle:{"z-index":"999"},attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',{staticClass:"d-flex nowrap justify-content-around",staticStyle:{"width":"180px !important"}},[_c('div',_vm._g({staticClass:"d-flex align-center profile-dropdown active hide-scrollbar",staticStyle:{"width":"125px"}},on),[_c('span',{staticClass:"hide-scrollbar",staticStyle:{"overflow-x":"auto !important","color":"white","font-size":"15px"}},[_vm._v(" "+_vm._s(_vm.$store.state.loginUser)+" ")]),_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-chevron-down")])],1),_c('div',{staticStyle:{"width":"30px"}},[_c('v-icon',{attrs:{"color":_vm.totalUnreadMessagesCount > 0
                                                    ? 'red'
                                                    : 'white'},on:{"click":_vm.launchMessageModal}},[_vm._v(" mdi-email ")])],1),_c('div',{staticStyle:{"width":"30px","margin-left":"10px"}},[_c('v-icon',{attrs:{"color":"white"},on:{"click":function($event){_vm.mini = !_vm.mini}}},[_vm._v(_vm._s(_vm.mini ? "mdi-menu-close" : "mdi-menu-open"))])],1)])]}}],null,false,3106511300)},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.profile_list),function(item,index){return _c('div',{key:index},[[_c('v-list-item',{staticClass:"cursor-pointer",class:{
                                                    activeLanguage:
                                                        item.active,
                                                },on:{"click":function($event){return _vm.onListClick(
                                                        item.action,
                                                        item.data
                                                    )}}},[_c('v-hover',[_c('v-list-item-title',[_c('v-icon',[_vm._v(" "+_vm._s(item.icon)+" ")]),_vm._v(" "+_vm._s(_vm.$t(item.title))+" ")],1)],1)],1),(item.divider)?_c('v-divider',{key:index}):_vm._e()]],2)}),0)],1)],1)],1),_c('v-list-item-subtitle',{staticStyle:{"font-size":"small","color":"white"}},[_vm._v(" @"+_vm._s(_vm.getCompanyName())+" ")])],1)])],1),_c('v-treeview',{attrs:{"open":_vm.active_tree_opened_items,"items":_vm.nav_items,"active":_vm.active_tree_items,"item-key":"name","activatable":"","active-class":'active-nav',"dense":"","open-on-click":"","dark":"","color":"#ffc23e"},on:{"update:active":[function($event){_vm.active_tree_items=$event},function($event){return _vm.selectActiveKey($event)}]},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
                                                    var item = ref.item;
return [(item.to)?_c('v-icon',{attrs:{"color":_vm.menuIconColor || item.color || 'white'},on:{"click":function($event){item.toBAssignNavigationData === false
                            ? ''
                            : _vm.assignNavigationData(item);
                        item.toBAssignNavigationData === false
                            ? ''
                            : _vm.$router.push(item.to).catch(function () {});}}},[_vm._v(" "+_vm._s(item.icon)+" ")]):_c('v-icon',{attrs:{"color":_vm.menuIconColor || item.color || 'white'}},[_vm._v(" "+_vm._s(item.icon)+" ")])]}},{key:"label",fn:function(ref){
                            var item = ref.item;
return [(item.to)?_c('span',{style:({ color: _vm.menuFontColor }),on:{"click":function($event){item.toBAssignNavigationData === false
                            ? ''
                            : _vm.assignNavigationData(item);
                        item.toBAssignNavigationData === false
                            ? ''
                            : _vm.$router.push(item.to).catch(function () {});}}},[_vm._v(" "+_vm._s(_vm.$t("" + item.name))+" ")]):_c('span',{style:({ color: _vm.menuFontColor })},[_vm._v(" "+_vm._s(_vm.$t("" + item.name))+" ")])]}}],null,false,846803223),model:{value:(_vm.tree),callback:function ($$v) {_vm.tree=$$v},expression:"tree"}})],1),_c('messages-modal')],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }