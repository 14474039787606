<template>
    <v-dialog
        persistent
        @click:outside="falseButtonClicked()"
        @keydown.esc="falseButtonClicked()"
        v-model="internalShowDialog"
        min-width="500"
        max-width="1024"
    >
        <v-card>
            <div
                @drop.prevent="handleDrop"
                @dragover.prevent="() => true"
                @dragenter.prevent="() => true"
                @dragleave.prevent="() => true"
            >
                <v-card-title
                    class="text-title"
                    style="margin-bottom: 0px !important;"
                >
                    {{ $t("attachments") }}
                    <v-spacer></v-spacer>
                    <v-icon style="cursor: pointer" @click="resetAttachmentTab"
                        >mdi-reload</v-icon
                    >
                </v-card-title>
                <v-card-text style="padding: 0px !important;">
                    <!-- Attachment Upload & Title & Button -->
                    <v-row class="ml-2 mr-2 mt-3">
                        <!-- Upload Button -->
                        <v-col
                            class="d-flex ma-0 ml-n0 pa-2"
                            cols="12"
                            :sm="showAttachmentType ? 4 : 6"
                        >
                            <v-file-input
                                show-size
                                counter
                                id="attachment"
                                ref="attachment"
                                name="attachment"
                                :error-messages="
                                    attchmentErrors ? [attchmentErrors] : null
                                "
                                v-model="attachment"
                                type="file"
                                @change="showAttachmentTypeDialog"
                                :label="$t('attachment.file')"
                            ></v-file-input>
                        </v-col>
                        <!-- Attachment Type -->
                        <v-col
                            v-if="showAttachmentType"
                            class="d-flex ma-0 ml-n0 pa-2"
                            cols="12"
                            sm="3"
                        >
                            <v-autocomplete
                                autocomplete="off"
                                class="to-upper mr-3"
                                flat
                                :items="attachmentTypes"
                                item-text="text"
                                item-value="id"
                                v-model="attachmentType"
                                :label="$t('attachment.type')"
                                :hide-details="true"
                            ></v-autocomplete>
                        </v-col>
                        <!-- Attachment Title -->
                        <v-col
                            class="d-flex ma-0 ml-n0 pa-2"
                            cols="12"
                            :sm="showAttachmentType ? 3 : 4"
                        >
                            <v-text-field
                                :autocomplete="autocompleteValueLocal"
                                v-model="title"
                                class="to-upper"
                                :label="$t('attachment.title_optional')"
                                v-on:keydown.enter.prevent=""
                                v-on:keydown="
                                    handleInput($event, 'alphanumeric', 80)
                                "
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-text-field>
                        </v-col>
                        <!-- Add Attachment -->
                        <v-col class="d-flex ma-0 ml-n0 pa-2" cols="12" sm="2">
                            <v-btn
                                :loading="isLoading"
                                color="success"
                                class="px-4 mt-2 text-white-color"
                                :disabled="!attachment || isLoading"
                                @click="addAttachments()"
                            >
                                {{ $t("add") }}
                                <v-icon dark right v-if="!isLoading">
                                    mdi-plus
                                </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <!-- Attachment Description -->
                    <v-row class="ml-2 mr-2">
                        <v-col class="d-flex ma-0 ml-n0 pa-2" cols="12" sm="12">
                            <v-textarea
                                v-model="description"
                                :label="$t('attachment.description')"
                                rows="2"
                                class="to-upper"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="ma-4">
                            <v-tabs
                                v-model="attachment_tab"
                                height="80px"
                                background-color="blue darken-4"
                                icons-and-text
                                grow
                                dark
                            >
                                <v-tabs-slider></v-tabs-slider>

                                <template v-for="tab in orderedTabs">
                                    <v-tab
                                        v-if="checkIfExists(tab.key)"
                                        :key="tab.key"
                                        :href="tab.href"
                                    >
                                        {{ $t(tab.label) }}
                                        <v-icon x-large>{{ tab.icon }}</v-icon>
                                    </v-tab>
                                </template>
                            </v-tabs>
                            <v-tabs-items v-model="attachment_tab">
                                <v-tab-item
                                    v-if="
                                        checkIfExists('billing') &&
                                            attachment_tab ==
                                                'attachment_bill_document'
                                    "
                                    :key="'attachment_bill_document'"
                                    :value="'attachment_bill_document'"
                                >
                                    <AttachmentDialogContent
                                        :resource="activeResource"
                                        :data="getIdForResource('billing')"
                                        @resourceData="selectedResourceData"
                                    ></AttachmentDialogContent>
                                </v-tab-item>

                                <!-- <v-tab-item
                        v-if="
                            checkIfExists('expedition') &&
                                attachment_tab == 'attachment_expedition'
                        "
                        :key="'attachment_expedition'"
                        :value="'attachment_expedition'"
                    >
                        <AttachmentDialogContent
                            :resource="activeResource"
                            :data="getIdForResource('expedition')"
                            @resourceData="selectedResourceData"
                        ></AttachmentDialogContent>
                    </v-tab-item> -->

                                <v-tab-item
                                    v-if="
                                        checkIfExists('expenses') &&
                                            attachment_tab ==
                                                'attachment_bill_expenses'
                                    "
                                    :key="'attachment_bill_expenses'"
                                    :value="'attachment_bill_expenses'"
                                >
                                    <AttachmentDialogContent
                                        :resource="activeResource"
                                        :data="getIdForResource('expenses')"
                                        @resourceData="selectedResourceData"
                                    ></AttachmentDialogContent>
                                </v-tab-item>

                                <v-tab-item
                                    v-if="
                                        checkIfExists('clients') &&
                                            attachment_tab ==
                                                'attachment_client'
                                    "
                                    :key="'attachment_client'"
                                    :value="'attachment_client'"
                                >
                                    <AttachmentDialogContent
                                        :resource="activeResource"
                                        :data="getIdForResource('clients')"
                                        @resourceData="selectedResourceData"
                                    ></AttachmentDialogContent>
                                </v-tab-item>

                                <v-tab-item
                                    v-if="
                                        checkIfExists('vehicles') &&
                                            attachment_tab ==
                                                'attachment_vehicle'
                                    "
                                    :key="'attachment_vehicle'"
                                    :value="'attachment_vehicle'"
                                >
                                    <AttachmentDialogContent
                                        :resource="activeResource"
                                        :data="getIdForResource('vehicles')"
                                        @resourceData="selectedResourceData"
                                    ></AttachmentDialogContent>
                                </v-tab-item>

                                <v-tab-item
                                    v-if="
                                        checkIfExists('tyre_depot') &&
                                            attachment_tab ==
                                                'attachment_tyre_depot'
                                    "
                                    :key="'attachment_tyre_depot'"
                                    :value="'attachment_tyre_depot'"
                                >
                                    <AttachmentDialogContent
                                        :resource="activeResource"
                                        :data="getIdForResource('tyre_depot')"
                                        @resourceData="selectedResourceData"
                                    ></AttachmentDialogContent>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="pr-2">
                    <v-spacer></v-spacer>
                    <v-btn color="grey lighten-2" @click="falseButtonClicked()">
                        {{ $t("attachment.close") }}
                        <v-icon dark right> mdi-close </v-icon>
                    </v-btn>
                </v-card-actions>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import _ from "lodash";
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import ConfirmationModel from "@/components/ConfirmationModel";
import AttachmentDialogContent from "@/components/AttachmentDialogEachTabContent";

export default {
    name: "AttachmentDialog",
    refs: ["attachment"],
    components: {
        AttachmentDialogContent,
    },
    mixins: [validationMixin],
    props: {
        showDialog: [Number, Boolean],
        showAttachmentType: { type: Boolean, default: false },
        order: {
            type: Array,
            default: () => [
                "billing",
                "vehicles",
                "tyre_depot",
                "expenses",
                "clients",
            ],
        },
    },
    validations: {
        // Attachment Validation
        // description: {
        //     required: required,
        // },
        title: {
            maxLength: maxLength(200),
        },
    },
    computed: {
        orderedTabs() {
            return this.order.map((key) => ({
                key,
                ...this.tabDetails[key],
            }));
            // .filter((element) => {
            //     if (element.href == undefined || element.href == null) {
            //         return false;
            //     }
            //     return true;
            // });
        },
        currentPageData() {
            return this.$store.state.currentPageData;
        },
        activeResource() {
            if (this.attachment_tab) {
                switch (this.attachment_tab) {
                    case "attachment_bill_document":
                        return "billing";
                    case "attachment_bill_expenses":
                        return "expenses";
                    case "attachment_client":
                        return "clients";
                    case "attachment_vehicle":
                        return "vehicles";
                    case "attachment_tyre_depot":
                        return "tyre_depot";
                    default:
                        return "billing";
                }
            }
            return null;
        },
        headers() {
            return [
                {
                    text: "",
                    align: "start",
                    sortable: true,
                    value: "date",
                    width: "10px",
                },
                {
                    text: this.$t("attachment.date"),
                    align: "start",
                    sortable: true,
                    value: "date",
                },
                // {
                //     text: this.$t("attachment.group"),
                //     align: "start",
                //     sortable: false,
                //     value: "group",
                // },
                {
                    text: this.$t("attachment.title"),
                    align: "start",
                    sortable: false,
                    value: "title",
                },
                {
                    text: this.$t("attachment.description"),
                    align: "start",
                    sortable: false,
                    value: "description",
                },
                {
                    text: this.$t("attachment.actions"),
                    align: "start",
                    sortable: false,
                    value: "actions",
                },
            ];
        },
    },
    data() {
        return {
            tabDetails: {
                billing: {
                    href: "#attachment_bill_document",
                    label: "attachments_module.tabs.billing",
                    icon: "mdi-file-document",
                },
                expedition: {
                    href: "#attachment_expedition",
                    label: "attachments_module.tabs.expedition",
                    icon: "mdi-file-document",
                },
                expenses: {
                    href: "#attachment_bill_expenses",
                    label: "attachments_module.tabs.expenses",
                    icon: "mdi-file-document",
                },
                clients: {
                    href: "#attachment_client",
                    label: "attachments_module.tabs.clients",
                    icon: "mdi-currency-eur",
                },
                vehicles: {
                    href: "#attachment_vehicle",
                    label: "attachments_module.tabs.vehicles",
                    icon: "mdi-car-back",
                },
                tyre_depot: {
                    href: "#attachment_tyre_depot",
                    label: "attachments_module.tabs.tyre_depot",
                    icon: "mdi-calendar",
                },
            },
            is_boxes: this.getCompanyPropertyFromLocalStorage(
                "boxes_on_fields",
                1
            ),
            sortBy: "description",
            sortDesc: false,
            options: {},
            data: null,
            dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
            isLoading: false,
            totalItems: 0,
            page: 1,
            lastPage: 1,
            itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
            autocompleteValueLocal: "off",
            internalShowDialog: this.showDialog,
            title: null,
            description: null,
            attachment: null,
            attachments: [],
            module_id: null,
            resource_id: null,
            editModeItem: null,
            group: null,
            resourceModule: null,
            attchmentErrors: "",
            showAttachmentTypeDialogVisible: false,

            attachment_tab: null,
            foundResourcesForCurrentData: [],
        };
    },
    mounted() {},
    methods: {
        checkIfExists(resourceToBeChecked) {
            let foundInObject = false;
            this.foundResourcesForCurrentData.forEach((element) => {
                if (element.includes(resourceToBeChecked)) {
                    foundInObject = true;
                }
            });

            let foundInAttachmentModule = false;
            let data = JSON.parse(localStorage.getItem("data"));
            let applicationResourceId = null;
            data.applicationResources.forEach((resource) => {
                if (resource.application_resource.key == resourceToBeChecked) {
                    applicationResourceId = resource.application_resource.id;
                }
            });
            data.attachmentModules.forEach((modules) => {
                if (modules.application_resource_id == applicationResourceId) {
                    foundInAttachmentModule = true;
                }
            });
            return foundInObject && foundInAttachmentModule;
        },
        getIdForResource(resource) {
            let id = false;
            this.foundResourcesForCurrentData.forEach((element) => {
                if (element.includes(resource)) {
                    const match = element.match(/\d+/); // Use a regular expression to find the numeric part
                    id = match ? match[0] : null;
                }
            });
            return id;
        },
        selectedResourceData(data) {
            this.data = data.data;
            this.module_id = data.module_id;
        },
        selectAttachmentTypeFromDialog(item) {
            this.attachmentType = item;
            this.showAttachmentTypeDialogVisible = false;
        },
        showAttachmentTypeDialog() {
            if (this.attachment && this.showAttachmentType) {
                this.showAttachmentTypeDialogVisible = true;
            }
        },
        handleDrop(event) {
            this.attachment = event.dataTransfer.files[0];
        },
        makeTitle(item) {
            let title = item ? item.title ?? "" : this.title ?? "";
            if (this.showAttachmentType && this.attachmentType) {
                title = title + " " + this.attachmentType.text;
            }
            return title;
        },
        makeDescription(item) {
            return item ? item.description ?? "" : this.description ?? "";
        },
        falseButtonClicked(item) {
            this.$emit("update:showDialog", false);
            this.$emit("buttonClicked", false);
            this.internalShowDialog = false;
        },
        resetAttachmentTab() {
            let tempAttachmentTab = this.attachment_tab;
            this.attachment_tab = null;
            setTimeout(() => {
                this.attachment_tab = tempAttachmentTab;
            }, 50);
        },
        resetForm() {
            this.attachment = null;
            this.title = null;
            this.group = null;
            this.description = null;
            this.attachmentType = null;
        },
        addAttachments() {
            this.$store.commit("setValidation", true); // enable validation
            if (this.$v.$invalid) {
                this.$toast.warning(this.$t("please_fill_form_correctly"));
                return false;
            } else {
                let formData = new FormData();
                formData.append("attachment", this.attachment);
                formData.append("module_id", this.module_id);
                formData.append("title", this.makeTitle(null));
                formData.append("group", this.group ?? "");
                formData.append("description", this.makeDescription(null));
                formData.append("resource_key_id", this.data);
                this.isLoading = true;
                axios
                    .post(API_BASE_URL + "/attachments", formData, {
                        headers: {
                            Authorization:
                                "Bearer " + this.$store.state.AccessToken,
                        },
                    })
                    .then(({ data }) => {
                        this.attachments.push(data.data);
                        this.totalItems++;
                        this.resetForm();
                        this.resetAttachmentTab();
                    })
                    .catch((error) => {
                        this.attchmentErrors = error.response.data.errors[0];
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
            this.$store.commit("setValidation", false); // disable validation
        },
        updateSort(sortBy) {
            if (typeof sortBy == "object") {
                this.sortBy = sortBy[0];
            } else {
                this.sortBy = sortBy == undefined ? "id" : sortBy;
            }
        },
        updateSortOrder(sortDesc) {
            if (typeof sortDesc == "object") {
                !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
            } else {
                !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
            }
            this.fetchData();
        },
        updatePagination(page) {
            this.page = page;
            this.fetchData();
        },
        updateItemsPerPage(itemsPerPage) {
            this.itemsPerPage =
                itemsPerPage == -1 ? this.totalItems : itemsPerPage;
            this.fetchData();
            this.itemsPerPage = this.itemsPerPage == -1 ? -1 : itemsPerPage;
        },
        fixTyreDepotForBillOrExpense(foundResources) {
            let depotInExists = false;
            let depotOutExists = false;
            let billingExists = false;
            let vehicleExists = false;
            foundResources.forEach((element) => {
                if (element.includes("tyre_depot_from_billing_depot_in#")) {
                    depotInExists = element.replace(
                        "tyre_depot_from_billing_depot_in#",
                        ""
                    );
                }
                if (element.includes("tyre_depot_from_billing_depot_out#")) {
                    depotOutExists = element.replace(
                        "tyre_depot_from_billing_depot_out#",
                        ""
                    );
                }
                if (element.includes("billing#")) {
                    billingExists = element.replace("billing#", "");
                }
                if (element.includes("vehicle_id_from_billing#")) {
                    vehicleExists = element.replace(
                        "vehicle_id_from_billing#",
                        ""
                    );
                }
            });
            if (billingExists) {
                // this should only work in case of billing is present because in billing page only tyre depot are multiple so we have to use the one which is used in the details
                foundResources = foundResources.filter((key) => {
                    if (key.startsWith("tyre_depot#")) {
                        return false;
                    }
                    return true;
                });
                if (depotInExists || depotOutExists) {
                    foundResources.push(
                        "tyre_depot#" +
                            (depotInExists
                                ? depotInExists
                                : depotOutExists
                                ? depotOutExists
                                : "")
                    );
                }
            }
            return foundResources;
        },
        fixVehicleForBillOrExpense(foundResources) {
            let vehicleExists = false;
            let billingExists = false;
            foundResources.forEach((element) => {
                if (element.includes("vehicle_id_from_billing#")) {
                    vehicleExists = element.replace(
                        "vehicle_id_from_billing#",
                        ""
                    );
                }
                if (element.includes("billing#")) {
                    billingExists = element.replace("billing#", "");
                }
            });
            if (billingExists && vehicleExists) {
                // this should only work in case of billing is present because in billing page only tyre depot are multiple so we have to use the one which is used in the details
                foundResources = foundResources.filter((key) => {
                    if (key.startsWith("vehicles#")) {
                        return false;
                    }
                    return true;
                });
                foundResources.push("vehicles#" + vehicleExists);
            }
            return foundResources;
        },
    },
    watch: {
        currentPageData: {
            handler: function(val) {
                let resourceFoundInObject = this.determineObject(
                    this.currentPageData
                );
                const uniqueKeys = new Set();
                resourceFoundInObject.forEach((element) => {
                    if (
                        element?.object?.details &&
                        element?.object?.details.length > 0
                    ) {
                        element.object.details.forEach((billOrExpense) => {
                            if (billOrExpense.depot_in_id) {
                                uniqueKeys.add(
                                    "tyre_depot_from_billing_depot_in" +
                                        "#" +
                                        (billOrExpense?.depot_in_id ?? "")
                                );
                            }
                            if (billOrExpense.depot_out_id) {
                                uniqueKeys.add(
                                    "tyre_depot_from_billing_depot_out" +
                                        "#" +
                                        (billOrExpense?.depot_out_id ?? "")
                                );
                            }
                        });
                    }

                    if (element?.object?.id && element?.type) {
                        if (
                            (element?.type == "billing" ||
                                element?.type == "expenses") &&
                            element?.object?.vehicle_id
                        ) {
                            uniqueKeys.add(
                                "vehicle_id_from_billing" +
                                    "#" +
                                    (element?.object?.vehicle_id ?? "")
                            );
                        }
                        uniqueKeys.add(
                            element?.type + "#" + (element?.object?.id ?? "")
                        );
                    }
                });
                this.foundResourcesForCurrentData = Array.from(uniqueKeys);

                this.foundResourcesForCurrentData = this.fixTyreDepotForBillOrExpense(
                    this.foundResourcesForCurrentData
                );
                this.foundResourcesForCurrentData = this.fixVehicleForBillOrExpense(
                    this.foundResourcesForCurrentData
                );
            },
            immediate: true,
        },
        draggedFile(val) {
            if (val) {
                this.attachment = val;
            }
        },
        showDialog: {
            handler: function(val) {
                this.internalShowDialog = val;
            },
        },
        attachment: {
            handler: function(val) {
                if (!val) {
                    this.attchmentErrors = null;
                    this.attachmentType = null;
                    this.$emit("attachmentsLoaded", this.attachments);
                }
            },
        },
        totalItems: {
            handler: function(val) {
                let data =
                    typeof this.$store.state.attachmentCounts == "number"
                        ? []
                        : this.$store.state.attachmentCounts ?? [];
                data[this.countKey ?? "data"] = val;
                this.$store.commit("setAttachmentCounts", data);
                this.$store.state.attachmentCounts.push({});
                this.$nextTick(() => {
                    this.$store.state.attachmentCounts.pop();
                });
            },
        },
    },
};
</script>

<style scoped>
.dialog-text {
    display: block;
    height: 300px !important;
}
.v-dialog > .v-card > .v-card__subtitle,
.v-dialog > .v-card > .v-card__text {
    padding: 0 24px 27px !important;
}
.text-content {
    font-size: 18px !important;
}
.text-title {
    font-size: 24px !important;
}
.v-tab--active {
    background-color: #fb8c00 !important;
    color: white !important;
}
</style>
