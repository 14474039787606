<template>
    <v-card id="navigation" v-if="!$route.path.includes('pricingcalculator')">
        <confirmation-model
            :showDialog.sync="confirmationDialog"
            :openedForOperation.sync="confirmationDialogOperation"
            :text.sync="confirmationDialogConfirmationText"
            :trueText.sync="confirmationDialogTrueText"
            :falseText.sync="confirmationDialogFalseText"
            @buttonClicked="confirmationButtonClicked"
        ></confirmation-model>
        <v-navigation-drawer
            style="z-index:9"
            :style="{
                backgroundColor: menuBackgroundColor,
                fontSize: '14px',
            }"
            v-model="$store.state.showNavDrawer"
            app
            :expand-on-hover="mini"
        >
            <v-list-item
                style="padding-bottom: 8px; border-bottom: 1px solid #585858;"
            >
                <v-list-item-content class="admin-head-top">
                    <div style="max-width: 40px !important;">
                        <v-icon
                            v-if="!profileImage"
                            :color="menuIconColor || 'white'"
                            >mdi-account-circle-outline</v-icon
                        >
                        <img
                            v-if="profileImage"
                            :src="profileImage"
                            style="width: 35px; height: 35px; border-radius: 50%;background-color: white; margin-left: -3px; margin-top: 10px;"
                        />
                    </div>
                    <div>
                        <v-list-item-title class="title">
                            <v-menu offset-y style="z-index: 999;">
                                <template v-slot:activator="{ on }">
                                    <!-- Dropdown Activator -->
                                    <div
                                        class="d-flex nowrap justify-content-around"
                                        style="width: 180px !important; "
                                    >
                                        <div
                                            class="d-flex align-center profile-dropdown active hide-scrollbar"
                                            v-on="on"
                                            style="width: 125px;"
                                        >
                                            <span
                                                class="hide-scrollbar"
                                                style="overflow-x: auto !important; color: white; font-size: 15px;"
                                            >
                                                {{ $store.state.loginUser }}
                                            </span>
                                            <v-icon small color="white"
                                                >mdi-chevron-down</v-icon
                                            >
                                        </div>
                                        <div style="width: 30px">
                                            <v-icon
                                                @click="launchMessageModal"
                                                :color="
                                                    totalUnreadMessagesCount > 0
                                                        ? 'red'
                                                        : 'white'
                                                "
                                            >
                                                mdi-email
                                            </v-icon>
                                        </div>
                                        <div
                                            style="width: 30px; margin-left: 10px;"
                                        >
                                            <v-icon
                                                @click="mini = !mini"
                                                color="white"
                                                >{{
                                                    mini
                                                        ? "mdi-menu-close"
                                                        : "mdi-menu-open"
                                                }}</v-icon
                                            >
                                        </div>
                                    </div>
                                </template>
                                <!-- Dropdown -->
                                <v-card>
                                    <v-list dense>
                                        <div
                                            v-for="(item,
                                            index) in profile_list"
                                            :key="index"
                                        >
                                            <template>
                                                <v-list-item
                                                    class="cursor-pointer"
                                                    :class="{
                                                        activeLanguage:
                                                            item.active,
                                                    }"
                                                    @click="
                                                        onListClick(
                                                            item.action,
                                                            item.data
                                                        )
                                                    "
                                                >
                                                    <v-hover>
                                                        <v-list-item-title>
                                                            <v-icon>
                                                                {{ item.icon }}
                                                            </v-icon>
                                                            {{ $t(item.title) }}
                                                        </v-list-item-title>
                                                    </v-hover>
                                                </v-list-item>
                                                <v-divider
                                                    v-if="item.divider"
                                                    :key="index"
                                                ></v-divider>
                                            </template>
                                        </div>
                                    </v-list>
                                </v-card>
                            </v-menu>
                        </v-list-item-title>
                        <v-list-item-subtitle
                            style="font-size: small; color:white"
                        >
                            @{{ getCompanyName() }}
                        </v-list-item-subtitle>
                    </div>
                </v-list-item-content>
            </v-list-item>
            <!-- Navigation Sidebar -->
            <v-treeview
                v-model="tree"
                :open="active_tree_opened_items"
                :items="nav_items"
                :active.sync="active_tree_items"
                item-key="name"
                activatable
                @update:active="selectActiveKey($event)"
                :active-class="'active-nav'"
                dense
                open-on-click
                dark
                color="#ffc23e"
            >
                <template v-slot:prepend="{ item }">
                    <v-icon
                        :color="menuIconColor || item.color || 'white'"
                        v-if="item.to"
                        @click="
                            item.toBAssignNavigationData === false
                                ? ''
                                : assignNavigationData(item);
                            item.toBAssignNavigationData === false
                                ? ''
                                : $router.push(item.to).catch(() => {});
                        "
                    >
                        {{ item.icon }}
                    </v-icon>
                    <v-icon
                        :color="menuIconColor || item.color || 'white'"
                        v-else
                    >
                        {{ item.icon }}
                    </v-icon>
                </template>
                <template v-slot:label="{ item }">
                    <span
                        :style="{ color: menuFontColor }"
                        v-if="item.to"
                        @click="
                            item.toBAssignNavigationData === false
                                ? ''
                                : assignNavigationData(item);
                            item.toBAssignNavigationData === false
                                ? ''
                                : $router.push(item.to).catch(() => {});
                        "
                    >
                        {{ $t("" + item.name) }}
                    </span>
                    <span :style="{ color: menuFontColor }" v-else>
                        {{ $t("" + item.name) }}
                    </span>
                </template>
            </v-treeview>
        </v-navigation-drawer>
        <messages-modal></messages-modal>
    </v-card>
</template>
<style scoped>
[b1],
.b1 {
    border: 1px dashed red;
}

.list__tile {
    height: auto;
    min-height: 48px;
}

.list__tile__title {
    white-space: normal !important;
    text-overflow: wrap !important;
    word-wrap: break-word;
}

.hide-scrollbar {
    /* Hide scrollbar for Chrome, Safari and Opera */
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}
</style>
<script>
import { API_BASE_URL } from "@/config";
import axios from "@/plugins/axios";
import ConfirmationModel from "@/components/ConfirmationModel";
import MessagesModal from "@/components/MessagesThread/MessagesModal.vue";
import { mapState } from "vuex";

export default {
    components: {
        MessagesModal,
        "confirmation-model": ConfirmationModel,
    },
    name: "Navigation",
    data: () => ({
        files: {
            html: "mdi-language-html5",
            js: "mdi-nodejs",
            json: "mdi-code-json",
            md: "mdi-language-markdown",
            pdf: "mdi-file-pdf",
            png: "mdi-file-image",
            txt: "mdi-file-document-outline",
            xls: "mdi-file-excel",
        },
        tree: [],
        active_tree_items: [],
        active_tree_opened_items: [],
        nav_items: [
            {
                name: "company",
                icon: "mdi-factory",
                tag: "home_sidebar",
                active: false,
                color: "white",
                toBAssignNavigationData: false,
                children: [
                    // Dashboard
                    {
                        name: "dashboard",
                        icon: "mdi-view-dashboard",
                        tag: "dashboard",
                        to: "/",
                        active: true,
                        color: "#FF9704",
                    },
                    // Expedition
                    {
                        name: "expedition_nav",
                        icon: "mdi mdi-truck",
                        tag: "expedition",
                        to: "/expedition",
                        active: false,
                        color: "#FF9704",
                    },
                    // Scheduler
                    {
                        name: "scheduler_nav",
                        icon: "mdi-calendar",
                        tag: "scheduler",
                        to: "/scheduler",
                        active: false,
                        color: "#FF9704",
                    },
                    // Sales
                    {
                        name: "facturation_nav",
                        icon: "mdi-invoice-text-plus",
                        tag: "billing",
                        to: "/billing",
                        active: false,
                        color: "#FF9704",
                        toBAssignNavigationData: false,
                        children: [
                            {
                                // name: "billings.types.estimation",
                                // icon: "mdi-routes",
                                // to: "/suppliers",
                                // tag: "suppliers",
                                // active: false,
                                // color: '#FF9704',
                            },
                            {
                                name: "billings.types.estimation",
                                icon: "mdi-hand-coin-outline",
                                tag: "billing",
                                to: "/billing",
                                active: false,
                                color: "#FF9704",
                            },
                            {
                                name: "billings.types.purchase_order",
                                icon: "mdi-cart-arrow-down",
                                tag: "billing",
                                to: "/billing",
                                active: false,
                                color: "#FF9704",
                            },
                            {
                                name: "billings.types.bill",
                                icon: "mdi-receipt-text-plus-outline",
                                tag: "billing",
                                to: "/billing",
                                active: false,
                                color: "#FF9704",
                            },
                            {
                                name: "billings.types.credit_note",
                                icon: "mdi-receipt-text-minus-outline",
                                tag: "billing",
                                to: "/billing",
                                active: false,
                                color: "#FF9704",
                            },
                        ],
                    },
                    // Expenses
                    {
                        name: "expenses_nav",
                        icon: "mdi-invoice-text-minus",
                        tag: "expenses",
                        active: false,
                        color: "#FF9704",
                        toBAssignNavigationData: false,
                        children: [
                            {
                                // name: "billings.types.estimation",
                                // icon: "mdi-routes",
                                // to: "/suppliers",
                                // tag: "suppliers",
                                // active: false,
                                // color: '#FF9704',
                            },
                            {
                                name: "expenses.types.bill",
                                icon: "mdi-receipt-text-plus-outline",
                                tag: "expenses",
                                to: "/expenses",
                                active: false,
                                color: "#FF9704",
                            },
                            {
                                name: "expenses.types.credit_note",
                                icon: "mdi-receipt-text-minus-outline",
                                tag: "expenses",
                                to: "/expenses",
                                active: false,
                                color: "#FF9704",
                            },
                        ],
                    },
                    // Stock
                    {
                        name: "stock_nav",
                        icon: "mdi-view-module",
                        tag: "stocks",
                        to: "/stocks",
                        active: false,
                        color: "#FF9704",
                    },
                    // Reception
                    {
                        name: "reception_nav",
                        icon: "mdi-account-tie",
                        tag: "receptions",
                        active: false,
                        color: "#FF9704",
                        children: [
                            {
                                name: "reception_scan_nav",
                                icon: "mdi-barcode-scan",
                                tag: "receptions",
                                to: "/receptions/list",
                                active: false,
                                color: "#FF9704",
                            },
                            {
                                name: "reception_nav",
                                icon: "mdi-receipt-text-check-outline",
                                tag: "receptions",
                                to: "/receptions/verify",
                                active: false,
                                color: "#FF9704",
                            },
                        ],
                    },
                    // Client
                    {
                        name: "clients_nav",
                        icon: "mdi-account",
                        tag: "clients",
                        to: "/clients",
                        active: false,
                        color: "#FF9704",
                    },
                    // Article
                    {
                        name: "articles_nav",
                        icon: "mdi-television-guide",
                        tag: "articles",
                        to: "/articles",
                        active: false,
                        color: "#FF9704",
                    },
                    // Vehicle
                    {
                        name: "vehicles_nav",
                        icon: "mdi-rv-truck",
                        tag: "vehicles",
                        to: "/vehicles",
                        active: false,
                        color: "#FF9704",
                    },
                    // Gardienage
                    {
                        name: "tyre_hotel_nav",
                        icon: "mdi-blur-radial",
                        tag: "tyre_depot",
                        active: false,
                        color: "#FF9704",
                        children: [
                            {
                                name: "pneu_hotel_nav",
                                icon: "mdi-garage-variant",
                                tag: "tyre_depot",
                                to: "/depot",
                                active: false,
                                color: "#FF9704",
                            },
                            // Bulk Operation
                            {
                                name: "tyre_hotel_bulk_operation_nav",
                                icon: "mdi-layers-triple-outline",
                                tag: "tyre_depot",
                                to: "/depot/bulk-operation",
                                active: false,
                                color: "#FF9704",
                            },
                        ],
                    },
                    // Tyre Hotel
                    // {
                    //     name: "tyre_hotel_nav",
                    //     icon: "mdi-blur-radial",
                    //     tag: "tyre_depot",
                    //     to: "/depot",
                    //     active: false,
                    //     color: 'white',
                    //     toBAssignNavigationData: false,
                    //     children: [
                    //         {
                    //             // name: "billings.types.estimation",
                    //             // icon: "mdi-routes",
                    //             // to: "/suppliers",
                    //             // tag: "suppliers",
                    //             // active: false,
                    //             // color: '#FF9704',
                    //         },
                    //         {
                    //             name: "tyre_hotel_nav",
                    //             icon: "mdi-blur-radial",
                    //             tag: "tyre_depot",
                    //             to: "/depot",
                    //             active: false,
                    //             color: '#FF9704',
                    //         },
                    //         // Bulk Operation
                    //         {
                    //             name: "tyre_hotel_bulk_operation_nav",
                    //             icon: "mdi-layers-triple-outline",
                    //             tag: "tyre_depot",
                    //             to: "/depot/bulk-operation",
                    //             active: false,
                    //             color: '#FF9704',
                    //         },
                    //     ]
                    // },
                    // Rental
                    {
                        name: "rentals_nav",
                        icon: "mdi-car-key",
                        tag: "rentals",
                        to: "/rentals",
                        active: false,
                        color: "#FF9704",
                    },
                    // Recurrence
                    {
                        name: "client_recurrence_document_nav",
                        icon: "mdi-account-details-outline",
                        tag: "client_recurrence_document",
                        to: "/client_recurrence_document",
                        active: false,
                        color: "#FF9704",
                    },
                    // Pricing Calculator
                    // {
                    //     name: "pricing_calculator_nav",
                    //     icon: "mdi-calculator-variant-outline",
                    //     tag: "billing",
                    //     to: "/pricingcalculator",
                    //     active: false,
                    //     color: '#FF9704',
                    // },
                    // Email
                    {
                        name: "email_nav",
                        icon: "mdi-email-box",
                        tag: "emails",
                        to: "/emails",
                        active: false,
                        color: "#FF9704",
                    },
                    // Report
                    {
                        name: "report_nav",
                        icon: "mdi-file-chart-outline",
                        tag: "report",
                        to: "/report",
                        active: false,
                        color: "#FF9704",
                    },
                ],
            },
            {
                name: "timing_attendance_nav",
                icon: "mdi-clock-check-outline",
                tag: "clocking",
                active: false,
                color: "white",
                toBAssignNavigationData: false,
                children: [
                    {
                        name: "clocking_nav",
                        icon: "mdi-clock-outline",
                        tag: "clocking",
                        to: "/clocking",
                        active: false,
                        color: "#FF9704",
                    },
                    {
                        name: "employees_nav",
                        icon: "mdi-account-group",
                        tag: "clocking",
                        to: "/employee-contracts",
                        active: false,
                        color: "#FF9704",
                    },
                    {
                        name: "employee_request_nav",
                        icon: "mdi-message-arrow-right-outline",
                        tag: "clocking",
                        to: "/requests",
                        active: false,
                        color: "#FF9704",
                    },
                    {
                        name: "employee_working_shift_nav",
                        icon: "mdi-message-arrow-right-outline",
                        tag: "clocking_shifts",
                        to: "/employee-working-shifts",
                        active: false,
                        color: "#FF9704",
                    },
                ],
            },

            {
                name: "settings_nav",
                icon: "mdi-filmstrip",
                tag: "settings",
                active: false,
                color: "white",
                toBAssignNavigationData: false,
                children: [
                    {
                        name: "company_setting_nav",
                        icon: "mdi-office-building-cog",
                        tag: "company_settings",
                        to: "/company-setting",
                        active: false,
                        color: "#FF9704",
                    },
                    {
                        name: "family_clients_nav",
                        icon: "mdi-account-switch",
                        tag: "family_clients",
                        to: "/family-clients",
                        active: false,
                        color: "#FF9704",
                    },
                    {
                        name: "family_articles_nav",
                        icon: "mdi-alpha-a-box",
                        tag: "family_articles",
                        to: "/family-articles",
                        active: false,
                        color: "#FF9704",
                    },
                    {
                        name: "brands_nav",
                        icon: "mdi-alpha-b-box",
                        to: "/brands",
                        tag: "brands",
                        active: false,
                        color: "#FF9704",
                    },
                    {
                        name: "article_pricing_nav",
                        icon: "mdi-cash-register",
                        tag: "article_pricing",
                        to: "/article-pricing",
                        active: false,
                        color: "#FF9704",
                    },
                    {
                        name: "pricing_nav",
                        icon: "mdi-cash-multiple",
                        tag: "pricing_rules",
                        to: "/pricing-rules",
                        active: false,
                        color: "#FF9704",
                    },
                    // {
                    //   name: "triggers_nav",
                    //   icon: "mdi-bullhorn",
                    //   tag: "pricing_rules",
                    //   to: "/triggers",
                    //   active: false,
                    //   color: '#FF9704',
                    // },
                    {
                        name: "recurrence_document_nav",
                        icon: "mdi-repeat-variant",
                        tag: "recurrence_document",
                        to: "/recurrence_document",
                        active: false,
                        color: "#FF9704",
                    },
                    {
                        name: "suppliers_nav",
                        icon: "mdi-routes",
                        to: "/suppliers",
                        tag: "suppliers",
                        active: false,
                        color: "#FF9704",
                    },
                    {
                        name: "users_nav",
                        icon: "mdi-account-multiple",
                        tag: "users",
                        to: "/users",
                        active: false,
                        color: "#FF9704",
                    },
                    {
                        name: "user_rights",
                        icon: "mdi-account",
                        tag: "user_rights",
                        to: "/user-rights",
                        active: false,
                        color: "#FF9704",
                    },
                    {
                        name: "employment_contract_nav",
                        icon: "mdi-file-document-edit-outline",
                        tag: "clocking",
                        to: "/employment-contracts",
                        active: false,
                        color: "#FF9704",
                    },
                    {
                        name: "working_shift_nav",
                        icon: "mdi-file-document-edit-outline",
                        tag: "clocking_shifts",
                        to: "/working-shifts",
                        active: false,
                        color: "#FF9704",
                    },
                    {
                        name: "company_supplier_connections_nav",
                        icon: "mdi-transit-connection-variant",
                        tag: "company_supplier_connections",
                        to: "/supplier-connections",
                        active: false,
                        color: "#FF9704",
                    },
                    {
                        name: "order_reminder_schema_nav",
                        icon: "mdi-data-matrix",
                        tag: "billing",
                        to: "/order-reminder-schema",
                        active: false,
                        color: "#FF9704",
                    },
                    {
                        name: "company_properties_languages_translations_nav",
                        icon: "mdi-translate-variant",
                        tag: "translations",
                        to: "/translations",
                        active: false,
                        color: "#FF9704",
                    },
                ],
            },
        ],
        profile_list: [
            {
                title: "profileDropDown.myProfile",
                data: null,
                icon: "mdi-account-circle",
                child: [],
                divider: true,
                active: false,
                action: "showProfile",
                color: "#FF9704",
            },
            {
                title: "profileDropDown.logout",
                data: null,
                icon: "mdi-power-standby",
                child: [],
                divider: false,
                active: false,
                action: "logout",
                color: "#FF9704",
            },
        ],
        dialog: false,
        right: null,
        drawer: null,
        confirmationDialog: false,
        confirmationDialogConfirmationText: null,
        confirmationDialogFalseText: null,
        confirmationDialogTrueText: null,
        confirmationDialogButtonClickedIs: null,
        confirmationDialogOperation: null,
        mini: false,
    }),
    computed: {
        ...mapState(["totalUnreadMessagesCount", "navigationIsOpen"]),
        menuBackgroundColor() {
            return this.getCompanyPropertyValue("MENU_BACKGROUND_COLOR");
        },
        menuIconColor() {
            return this.getCompanyPropertyValue("MENU_ICON_COLOR");
        },
        menuFontColor() {
            return this.getCompanyPropertyValue("MENU_FONT_COLOR");
        },
        profileImage() {
            let userData = JSON.parse(localStorage.getItem("data"));
            return userData?.profile_image;
        },
        currentRoute() {
            return this.$route.path;
        },
    },
    async created() {
        await this.resolveCompanyLanguages();

        let findCompanyLocale = await this.findCompanyLocale();
        this.$i18n.locale = findCompanyLocale;
        this.active_tree_items = [];
        let anyActiveLanguage = false;
        this.setMultiLevelFallback(findCompanyLocale);
        this.profile_list.forEach((element) => {
            if (element.data == findCompanyLocale) {
                element.active = true;
                anyActiveLanguage = element;
            }
        });
        // If no default language selected
        if (!anyActiveLanguage) {
            this.profile_list.forEach((element) => {
                if (!anyActiveLanguage && element.data) {
                    element.active = true;
                    anyActiveLanguage = element;
                    this.onListClick(element.action, element.data);
                }
            });
        }
        this.checkActiveTreeKey(this.nav_items);
    },
    mounted() {
        this.nav_items = this.filterNavItems(this.nav_items);
    },
    methods: {
        launchMessageModal() {
            document.getElementById("messageModalActivator").click();
        },
        async resolveCompanyLanguages() {
            return new Promise((resolve) => {
                this.$store
                    .dispatch("fetchCompanyLanguages")
                    .then((response) => {
                        let languageConstruct = response
                            .filter((item) => item.language.is_active == 1)
                            .map((item) => {
                                return {
                                    title: "appLanguage." + item.language_id,
                                    data: item.language_id,
                                    icon: "",
                                    child: [],
                                    divider: false,
                                    active: false,
                                    action: "changeLanguage",
                                };
                            });
                        // console.log("language construct", languageConstruct);
                        // add languageConstruct to profile_list after the first item
                        this.profile_list.splice(1, 0, ...languageConstruct);
                        resolve(response);
                    });
            });
        },
        filterNavItems(nav_items) {
            let newNavItems = [];
            nav_items.forEach((element) => {
                if (
                    this.checkTag(element.tag) &&
                    this.checkUserTag(element.tag)
                ) {
                    if (element.children) {
                        element.children = this.filterNavItems(
                            element.children
                        );
                    }
                    newNavItems.push(element);
                }
            });
            return newNavItems;
        },
        confirmationButtonClicked(buttonClicked) {
            this.confirmationDialogButtonClickedIs = buttonClicked;
            this.confirmationDialog = false;
        },
        logoutPopUp() {
            this.confirmationDialogConfirmationText = this.$t(
                "navigation.logout_confirmation"
            );
            this.confirmationDialogFalseText = this.$t("navigation.no");
            this.confirmationDialogTrueText = this.$t("navigation.yes");
            this.confirmationDialogOperation = "logout";
            this.confirmationDialog = true;
        },
        getCompanyName() {
            return localStorage.getItem("company_name");
        },
        getUserRole() {
            return localStorage.getItem("userRole");
        },
        selectActiveKey(event) {
            if (event.length > 0) {
                let navItem = this.getObject(this.nav_items, event[0]);
                this.assignNavigationData(navItem);
                this.$router.push(navItem.to).catch(() => {});
            }
        },
        getObject(theObject, valueSearch) {
            var result = null;
            if (theObject instanceof Array) {
                for (var i = 0; i < theObject.length; i++) {
                    result = this.getObject(theObject[i], valueSearch);
                    if (result) {
                        break;
                    }
                }
            } else {
                for (var prop in theObject) {
                    if (prop == "name") {
                        if (theObject[prop] == valueSearch) {
                            return theObject;
                        }
                    }
                    if (
                        theObject[prop] instanceof Object ||
                        theObject[prop] instanceof Array
                    ) {
                        result = this.getObject(theObject[prop], valueSearch);
                        if (result) {
                            break;
                        }
                    }
                }
            }
            return result;
        },
        checkActiveTreeKey(nav_items) {
            let matched = false;
            nav_items.forEach((element) => {
                if (element.to && element.to == this.$route.path) {
                    this.active_tree_items.push(element.name);
                    matched = true;
                }
                if (element.children) {
                    if (this.checkActiveTreeKey(element.children)) {
                        this.active_tree_opened_items.push(element.name);
                        let uniqueArray = [
                            ...new Set(this.active_tree_opened_items),
                        ];
                        this.active_tree_opened_items = uniqueArray;
                        matched = true;
                    }
                }
            });
            return matched;
        },
        async onListClick(action, data) {
            console.log("action list", action);
            if (action === "logout") {
                this.logoutPopUp();
            } else if (action == "showProfile") {
                this.$router.push("/user/profile");
            } else if (action === "changeLanguage") {
                let companyLocale = await this.findCompanyLocale(data);
                this.$i18n.locale = companyLocale;
                this.setMultiLevelFallback(companyLocale);
                // Change Vue and user language
                this.$vuetify.lang.current = companyLocale;
                localStorage.setItem("languageId", data);
                this.$store.state.languageId = data;

                this.profile_list.forEach((element) => {
                    if (element.action === "changeLanguage") {
                        element.active = false;
                        if (
                            element.data === localStorage.getItem("languageId")
                        ) {
                            element.active = true;
                        }
                    }
                });
            }
        },
        logout() {
            const token = this.$store.state.AccessToken;
            axios({
                method: "get",
                url: API_BASE_URL + "/logout",
                headers: {
                    Authorization: "Bearer " + token,
                },
            }).finally(() => {
                let data = JSON.parse(localStorage.getItem("data"));
                let companyIdentifier = data.userDetails.company.identifier;

                localStorage.clear();
                clearInterval(this.$store.state.getMessageInterval);
                let defaultLocalToset = this.$i18n.locale;
                if (defaultLocalToset.includes(companyIdentifier)) {
                    defaultLocalToset = defaultLocalToset.replaceAll(
                        companyIdentifier,
                        ""
                    );
                }
                localStorage.setItem(
                    "languageId",
                    defaultLocalToset ?? this.$i18n.locale
                );
                this.$store.state.AccessToken = "";
                this.$router.push("/login").catch(() => {});
            });
            this.dialog = false;
        },
        assignNavigationData(data) {
            this.$store.commit("setNavigationData", data);
        },
    },
    watch: {
        active_tree_items(val) {
            if (!val || val.length == 0) {
                // this.checkActiveTreeKey(this.nav_items);
            }
        },
        currentRoute() {
            // this.checkActiveTreeKey(this.nav_items);
        },
        confirmationDialogButtonClickedIs(newVal) {
            if (newVal == true && this.confirmationDialogOperation != null) {
                if (
                    this.confirmationDialogOperation == "logout" &&
                    this.confirmationDialogButtonClickedIs == true
                ) {
                    this.logout();
                }
                this.confirmationDialogButtonClickedIs = null;
            }
        },
        mini(val) {
            this.$store.state.navigationIsOpen = !val;
            this.$store.commit("invertNavDrawerVisibility", false);
            setTimeout(() => {
                this.$store.commit("invertNavDrawerVisibility", true);
            }, 200);
        },
        navigationIsOpen(val) {
            this.mini = !val;
        },
    },
};
</script>

<style>
div.v-treeview-node.v-treeview-node--click
    .v-treeview-node__root
    .v-treeview-node__root {
    display: flex !important;
    justify-content: space-between !important;
}
.v-treeview--dense .v-treeview-node__root {
    min-height: 36px !important;
}

div.v-treeview-node.v-treeview-node--click
    .v-treeview-node__root
    .v-treeview-node__toggle {
    /* order: 2 !important; */
}

.v-navigation-drawer--mini-variant .v-treeview-node__level {
    display: none !important;
}

.v-navigation-drawer--mini-variant
    .v-treeview-node__root
    .v-treeview-node__toggle {
    display: none !important;
}
.v-navigation-drawer--mini-variant
    .v-treeview-node__root
    .v-treeview-node__label {
    margin-left: 4px;
}
#navigation {
    z-index: 999 !important;
}

/* Targeting direct children of .v-treeview */
.v-treeview > .v-treeview-node:not(:last-child) {
    border-bottom: 1px solid rgb(108, 108, 108); /* Add your desired border style */
}
</style>
