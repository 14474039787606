var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"floating-form ",style:({
    position: 'absolute',
    top: '10px',
    left: _vm.navigationIsOpen ? '270px' : '70px',
  })},[_c('v-card-title',[_c('v-icon',{on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("mdi-arrow-left")]),_c('span',{staticClass:"ml-2 mr-2"},[_vm._v(_vm._s(_vm.$t('mappings.history')))]),(!_vm.hideBody)?_c('v-icon',{on:{"click":function($event){_vm.hideBody = true}}},[_vm._v("mdi-eye-off")]):_vm._e(),(_vm.hideBody)?_c('v-icon',{on:{"click":function($event){_vm.hideBody= false}}},[_vm._v("mdi-eye")]):_vm._e()],1),(!_vm.hideBody)?_c('v-card-text',[_c('v-form',[_c('v-select',{attrs:{"label":_vm.$t('select_duration'),"items":_vm.durations,"item-text":"text","item-value":"value","disabled":_vm.loading,"loading":_vm.loading},model:{value:(_vm.selectedDuration),callback:function ($$v) {_vm.selectedDuration=$$v},expression:"selectedDuration"}}),(_vm.selectedDuration === 'custom')?_c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('mappings.from_date'),"prepend-icon":"mdi-calendar","readonly":"","disabled":_vm.loading},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},'v-text-field',attrs,false),on))]}}],null,false,4212160169),model:{value:(_vm.fromDateDialog),callback:function ($$v) {_vm.fromDateDialog=$$v},expression:"fromDateDialog"}},[_c('v-date-picker',{on:{"input":function($event){_vm.fromDateDialog = false}},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('mappings.to_date'),"prepend-icon":"mdi-calendar","readonly":"","disabled":_vm.loading},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}},'v-text-field',attrs,false),on))]}}],null,false,1187244073),model:{value:(_vm.toDateDialog),callback:function ($$v) {_vm.toDateDialog=$$v},expression:"toDateDialog"}},[_c('v-date-picker',{on:{"input":function($event){_vm.toDateDialog = false}},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}})],1),_c('v-btn',{staticClass:"w-full",staticStyle:{"width":"100%"},attrs:{"disabled":_vm.loading,"color":"primary"},on:{"click":_vm.getPositions}},[_vm._v(" "+_vm._s(_vm.$t('mappings.proceed'))+" ")])],1):_vm._e()],1),(_vm.isset(_vm.trips))?_c('v-card-title',[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$t('mappings.trips')))]),(!_vm.hideTripsTable)?_c('v-icon',{on:{"click":function($event){_vm.hideTripsTable = true}}},[_vm._v("mdi-eye-off")]):_vm._e(),(_vm.hideTripsTable)?_c('v-icon',{on:{"click":function($event){_vm.hideTripsTable= false}}},[_vm._v("mdi-eye")]):_vm._e()],1):_vm._e(),(!_vm.hideTripsTable && _vm.isset(_vm.trips))?_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.tripItems},on:{"click:row":function (item) { return _vm.updateRouteCoordinates(item.positions); }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
  var item = ref.item;
  var index = ref.index;
return [_c('tr',{class:{'bg-blue': _vm.tripRendered === 'row_'+ index}},[_c('td',{on:{"click":function($event){return _vm.renderTrip(item, index)}}},[_vm._v(_vm._s(item.s_n))]),_c('td',{on:{"click":function($event){return _vm.renderTrip(item, index)}}},[_vm._v(" "+_vm._s(item.start_time))]),_c('td',{on:{"click":function($event){return _vm.renderTrip(item, index)}}},[_vm._v(" "+_vm._s(item.end_time))]),_c('td',{on:{"click":function($event){return _vm.renderTrip(item, index)}}},[_vm._v(" "+_vm._s(item.distance))]),_c('td',{on:{"click":function($event){return _vm.renderTrip(item, index)}}},[_vm._v(" "+_vm._s(item.average_speed))]),(_vm.tripRendered)?_c('td',[(_vm.tripRendered === 'row_'+index)?_c('v-icon',{attrs:{"color":"white"},on:{"click":_vm.removeRenderedTrip}},[_vm._v("mdi-close")]):_vm._e()],1):_vm._e()])]}}],null,false,1595630244)}):_vm._e(),(_vm.isset(_vm.events))?_c('v-card-title',[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$t('mappings.events')))]),(!_vm.hideEventsTable)?_c('v-icon',{on:{"click":function($event){_vm.hideEventsTable = true}}},[_vm._v("mdi-eye-off")]):_vm._e(),(_vm.hideEventsTable)?_c('v-icon',{on:{"click":function($event){_vm.hideEventsTable= false}}},[_vm._v("mdi-eye")]):_vm._e()],1):_vm._e(),(!_vm.hideEventsTable && _vm.isset(_vm.events))?_c('v-data-table',{attrs:{"headers":_vm.eventsTableHeaders,"items":_vm.eventItems}}):_vm._e()],1):_vm._e()],1),_c('div',{staticClass:"map",staticStyle:{"width":"100%","height":"100vh!important"},attrs:{"id":"main-map"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }