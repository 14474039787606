var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-data-table',{key:_vm.forceReloadComponentKey,staticClass:"elevation-0",staticStyle:{"width":"100%"},attrs:{"fixed-header":"","footer-props":{
            'items-per-page-options': _vm.dataTableOptions,
        },"headers":_vm.headers,"hide-default-header":true,"items":_vm.rec_items,"server-items-length":_vm.totalItems,"items-per-page":_vm.itemsPerPage,"options":_vm.options,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"expanded":_vm.expanded,"show-expand":"","loading-text":this.$t('loading'),"item-key":"id","height":'calc(-212px + 100vh)'},on:{"update:options":function($event){_vm.options=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":[function($event){_vm.sortDesc=$event},_vm.updateSortOrder],"update:expanded":function($event){_vm.expanded=$event},"update:page":_vm.updatePagination,"update:items-per-page":_vm.updateItemsPerPage,"update:sort-by":_vm.updateSort},scopedSlots:_vm._u([{key:"header",fn:function(ref){
        var headers = ref.props.headers;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',[_vm._l((headers),function(header){return [_c('th',{key:header.value,class:header.align == 'start'
                                    ? 'text-left'
                                    : 'text-right',style:(_vm.getHeaderWidth(header, header.width)),attrs:{"scope":"col"}},[(header.value == 'actions')?[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"d-flex dontbreakline",attrs:{"cols":"12","sm":"2"}},[_vm._v(" "+_vm._s(header.text)+" ")])],1)]:[_vm._v(" "+_vm._s(header.text)+" ")]],2)]})],2)])]}},{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"pt-4",attrs:{"flat":""}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mr-2 to-upper",attrs:{"label":_vm.$t('receptions.rec_date'),"prepend-icon":"mdi-calendar","readonly":"","append-icon":"mdi-close","outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"click:append":function($event){_vm.rec_date = null}},model:{value:(_vm.rec_date),callback:function ($$v) {_vm.rec_date=$$v},expression:"rec_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu_rec_date),callback:function ($$v) {_vm.menu_rec_date=$$v},expression:"menu_rec_date"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1,"weekday-format":_vm.getDay,"no-title":"","scrollable":"","locale":_vm.userLanguageId},on:{"input":function($event){_vm.menu_rec_date = false}},model:{value:(_vm.rec_date),callback:function ($$v) {_vm.rec_date=$$v},expression:"rec_date"}})],1),_c('v-autocomplete',{staticClass:"mr-2 to-upper",attrs:{"flat":"","items":_vm.suppliers,"label":_vm.$t('receptions.supplier'),"item-value":"id","item-text":"name","append-icon":"mdi-close","return-object":"","outlined":_vm.is_boxes,"dense":_vm.is_boxes,"hide-no-data":""},on:{"click:append":function($event){_vm.supplier = null}},model:{value:(_vm.supplier),callback:function ($$v) {_vm.supplier=$$v},expression:"supplier"}}),_c('v-text-field',{staticClass:"mr-3 to-upper",attrs:{"label":_vm.$t('receptions.reference'),"clearable":true,"clear-icon":"mdi-close","outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"focus":function($event){_vm.reference = null},"input":_vm.debounceInput},model:{value:(_vm.search_ref),callback:function ($$v) {_vm.search_ref=$$v},expression:"search_ref"}})],1)],1)]},proxy:true},{key:"item",fn:function(ref){
                                    var item = ref.item;
return [_c('tr',{class:_vm.setRowColorClass(item, _vm.index)},[_c('td',{on:{"click":function($event){return _vm.editItem(item)}}},[(item.supplier)?[_vm._v(" "+_vm._s(item.supplier.identifier)+" ")]:(item.supplier_id == 0)?[_vm._v(" INT ")]:_vm._e()],2),_c('td',{on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.formatDate(item.reception_date, 'dd-mm-yyyy H:i'))+" ")]),_c('td',{on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s((item.delivery_note_reference ? item.delivery_note_reference : "").toUpperCase())+" ")]),_c('td',{on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.computeScannedQuantity(item))+" ")]),_c('td',{on:{"click":function($event){return _vm.editItem(item)}}},[(item.user_id && item.user)?[_c('span',[_vm._v(_vm._s(item.user.name))])]:_vm._e()],2),_c('td',{on:{"click":function($event){return _vm.editItem(item)}}},[(item.is_injected == 1)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):_vm._e()],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }