var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.value,"max-width":"600px"}},[(_vm.employee)?_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('clocking_section.add_clocking_for', {name: _vm.employeeDisplayName})))])]),_c('v-card-text',[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-row',[_c('v-col',{attrs:{"md":_vm.addTypeIsSingle ? 3 : 6}},[_c('v-select',{attrs:{"items":_vm.addTypeOptions,"label":_vm.translate('select_duration'),"item-text":"text","item-value":"value"},model:{value:(_vm.addType),callback:function ($$v) {_vm.addType=$$v},expression:"addType"}})],1),_c('v-col',{attrs:{"md":_vm.addTypeIsSingle ? 5 : 6}},[_c('v-select',{attrs:{"items":_vm.processedTypes,"label":_vm.translate('clocking_type'),"item-text":"text","item-value":"id"},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})],1),(_vm.addTypeIsSingle)?_c('v-col',{attrs:{"md":"3"}},[_c('v-checkbox',{attrs:{"label":_vm.translate('is_full_day'),"color":"success","hide-details":""},model:{value:(_vm.isFullDay),callback:function ($$v) {_vm.isFullDay=$$v},expression:"isFullDay"}})],1):_vm._e(),(_vm.addTypeIsSingle)?_c('v-col',{attrs:{"md":_vm.isFullDay ? 12 : 4}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formattedDay,"label":_vm.translate('filter_options.select_day'),"prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}],null,false,893280480),model:{value:(_vm.dayPickerMenu),callback:function ($$v) {_vm.dayPickerMenu=$$v},expression:"dayPickerMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dayPickerMenu = false}},model:{value:(_vm.selectedDay),callback:function ($$v) {_vm.selectedDay=$$v},expression:"selectedDay"}})],1)],1):_vm._e(),(!_vm.isFullDay && _vm.addTypeIsSingle)?_c('v-col',{attrs:{"md":"4"}},[_c('v-text-field',{attrs:{"label":_vm.translate('from_time'),"type":"time"},model:{value:(_vm.fromTime),callback:function ($$v) {_vm.fromTime=$$v},expression:"fromTime"}})],1):_vm._e(),(!_vm.isFullDay && _vm.addTypeIsSingle)?_c('v-col',{attrs:{"md":"4"}},[_c('v-text-field',{attrs:{"label":_vm.translate('to_time'),"type":"time"},model:{value:(_vm.toTime),callback:function ($$v) {_vm.toTime=$$v},expression:"toTime"}})],1):_vm._e(),(_vm.addTypeIsMultiple)?_c('v-col',{attrs:{"md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formattedFromDate,"label":_vm.translate('filter_options.select_from_date'),"prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}],null,false,3956771391),model:{value:(_vm.fromDatePickerMenu),callback:function ($$v) {_vm.fromDatePickerMenu=$$v},expression:"fromDatePickerMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.fromDatePickerMenu = false}},model:{value:(_vm.selectedFromDate),callback:function ($$v) {_vm.selectedFromDate=$$v},expression:"selectedFromDate"}})],1)],1):_vm._e(),(_vm.addTypeIsMultiple)?_c('v-col',{attrs:{"md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formattedToDate,"label":_vm.translate('filter_options.select_to_date'),"prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}],null,false,2469321791),model:{value:(_vm.toDatePickerMenu),callback:function ($$v) {_vm.toDatePickerMenu=$$v},expression:"toDatePickerMenu"}},[_c('v-date-picker',{attrs:{"min":_vm.selectedFromDate},on:{"input":function($event){_vm.toDatePickerMenu = false}},model:{value:(_vm.selectedToDate),callback:function ($$v) {_vm.selectedToDate=$$v},expression:"selectedToDate"}})],1)],1):_vm._e(),_c('v-col',{attrs:{"md":"12"}},[_c('NoteEditor',{model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('span',{staticClass:"mr-2",class:{disabled: _vm.sending},on:{"click":_vm.close}},[_c('CloseButton')],1),_c('span',{class:{disabled: _vm.sending},on:{"click":_vm.save}},[_c('SaveButton',{attrs:{"disabled":_vm.sending}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }