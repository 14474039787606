<template>
    <v-dialog
        v-model="popUpModelVisible"
        max-width="1000"
        @click:outside="closePopUpModel()"
        @keydown.esc="closePopUpModel()"
    >
        <v-card>
            <v-card-title>
                <span class="text-h5">
                    {{ $t("receptions.add_external_order") }}
                </span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="12">
                        <tyre-hotel-search-article
                                :class="selected_article ? 'articleset': 'articlenotset'"
                                @changeDialog="changeDialog()" 
                                :compSelectedArticleId.sync="parentSearchSelectedArticleId"
                                :internalStockOnly.sync="parentInternalStockOnly"
                                ref="tyreSearchArticle"
                        ></tyre-hotel-search-article>

                        <v-text-field
                            v-if="selected_article && typeof selected_article == 'object'"
                            :autocomplete="autocompleteValueLocal"
                            class="ma-0 pa-0 to-upper selectedArticleTextBox"
                            append-icon="mdi-close"
                            @click:append="resetSelectedArticle()"
                            v-model="selected_article.description"
                            hide-details="auto"
                            outlined
                            dense
                        ></v-text-field>
                        <div class="v-messages theme--light error--text mt-n8"> 
                            {{ $store.state.validation && !$v.selected_article.required ? this.$t('receptions.text_messages.article_required') : '' }}
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <!-- Supplier -->
                    <v-col cols="12" sm="3">
                        <v-autocomplete
                            flat
                            :items="allSuppliers"
                            v-model="supplier"
                            :label="$t('receptions.supplier')"
                            class="mr-2 to-upper"
                            item-value="id"
                            item-text="name"
                            return-object
                            :outlined="is_boxes"
                            :dense="is_boxes"
                            hide-no-data
                            :error-messages="$store.state.validation && !$v.supplier.required ? [this.$t('receptions.text_messages.supplier_required')] : [] "
                        ></v-autocomplete>
                    </v-col>
                    <!-- Ordered Quantity -->
                    <v-col cols="12" sm="3">
                        <v-text-field
                            v-model="ordered_quantity"
                            :label="$t('receptions.ordered_quantity')"
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2 to-upper"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                            :error-messages="$store.state.validation && (!$v.ordered_quantity.required || !$v.ordered_quantity.minValue) ? [this.$t('receptions.text_messages.ordered_quantity_required')] : [] "
                        ></v-text-field>
                    </v-col>
                    <!-- Confirmed Quantity -->
                    <v-col cols="12" sm="3">
                        <v-text-field
                            v-model="confirmed_quantity"
                            :label="$t('receptions.confirmed_quant_label')"
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2 to-upper"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-text-field>
                    </v-col>
                    <!-- Received Quantity -->
                    <!-- <v-col cols="12" sm="2">
                        <v-text-field
                            v-model="received_quantity"
                            :label="$t('receptions.received_quant_label')"
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2 to-upper"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-text-field>
                    </v-col> -->
                    <!-- Status -->
                    <v-col cols="12" sm="3">
                        <v-autocomplete
                            v-model="rec_status"
                            :items="filteredStatuses"
                            :label="$t('receptions.status')"
                            item-value="id"
                            item-text="text"
                            class="mr-2"
                            return-object
                            :outlined="is_boxes"
                            :dense="is_boxes"
                            @click:append="status = null"
                            hide-details
                            append-icon="mdi-close"
                            :error-messages="$store.state.validation && !$v.rec_status.required ? [this.$t('receptions.text_messages.status_required')] : [] "
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                
                <v-spacer></v-spacer>
                <v-btn color="grey-lighten-2" @click="closePopUpModel()">
                    {{ $t("receptions.modify_article.cancel") }}
                    <v-icon dark right> mdi-keyboard-return </v-icon>
                </v-btn>

                <v-btn color="success" :disabled="externalOrderIsEnabled" @click="saveExternalOrder()">
                    {{ $t('vehicles.Save') }}
                    <v-icon dark right> mdi-content-save </v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { validationMixin } from "vuelidate";
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import TyreHotelSearchArticle from "@/components/TyreHotel/SearchArticle";
import { required, minLength, maxLength, minValue } from "vuelidate/lib/validators";

export default {
    mixins: [validationMixin],
    validations: {
        // Attachment Validation
        selected_article: {
            required: required
        },
        ordered_quantity: {
            required: required,
            minValue: minValue(1)
        },
        supplier: {
            required: required
        },
        rec_status: {
            required: required
        }
    },
    props: {
        rec_statuses: [Object],
    },
    components:{
        "tyre-hotel-search-article":TyreHotelSearchArticle
    },
    data() {
        return {
            is_boxes: this.getCompanyPropertyFromLocalStorage(
                "boxes_on_fields",
                1
            ),
            popUpModelVisible: true,
            isLoading: false,
            autocompleteValueLocal: "off",
            rec_status: null,
            savedReception: false,
            company_supplier_connections: [],

            supplier: null,
            ordered_quantity: 0,
            confirmed_quantity: 0,
            received_quantity: 0,
            filteredStatuses: [],
            selected_article: null,
            parentSearchSelectedArticleId: null,
            parentInternalStockOnly: false,
        };
    },
    mounted() {
        this.autocompleteValueLocal = this.autocompleteValue();
        this.$store.commit("setValidation", false); // disable validation
    },
    destroyed() {
        this.$store.commit("setValidation", false); // Disable validation
    },
    methods: {
        closePopUpModel() {
            this.popUpModelVisible = false;
        },
        async SavePopUpModel(waitForManually = false) {
            await this.addNewExternalOrder(this.makeSubmitData()).then(() => {
                if (!waitForManually) {
                    this.savedReception = true;
                    this.popUpModelVisible = false;
                }
            });
        },
        makeRequest(){
            return {
                article_id:         this.selected_article.id,
                supplier_id:        this.supplier && typeof this.supplier == 'object' ? this.supplier.id : null,
                ordered_quantity:   this.ordered_quantity,
                confirmed_quantity: this.confirmed_quantity,
                // received_quantity:  this.received_quantity,
                status_id:          this.rec_status && typeof this.rec_status  == 'object' ? this.rec_status.id : null

            }
        },
        saveExternalOrder() {
            this.$store.commit("setValidation", true); // enable validation
            if(!this.$v.$invalid){
                return new Promise((resolve) => {
                    let data = this.makeRequest();
                    if(data.article_id){
                        axios
                            .post(
                                API_BASE_URL + "/suppliers/order/external_order",
                                data,
                                { headers: this.header }
                            )
                            .then((data) => {
                                this.$toast.success(
                                    this.$t(
                                        "receptions.text_messages.external_order_created_successfully"
                                    )
                                );
                                this.savedReception = true;
                                this.closePopUpModel();
                            })
                            .finally(() => {
                                resolve();
                            });
                    }
                });
            }
        },
        resetSelectedArticle() {
            this.selected_article = null;
            this.parentSearchSelectedArticleId = null;
        },
    },
    computed: {
        allSuppliers() {
            return this.$store.state.allSuppliers ?? [];
        },
        externalOrderIsEnabled(){
            if(this.selected_article && this.supplier && this.ordered_quantity && this.ordered_quantity > 0 && this.rec_status){
                return false;
            }
            return true;
        }
    },
    watch: {
        parentSearchSelectedArticleId(val){
            if(val && typeof val == 'object' && val.id){
                this.selected_article = val
            }
            else{
                this.selected_article = null
            }
        },
        popUpModelVisible(val) {
            this.$emit("change-popup-model-visibility", this.savedReception);
        },
        rec_statuses: {
            handler:function(){
                if(this.rec_statuses && this.rec_statuses.length > 0){
                    this.filteredStatuses = this.rec_statuses.filter((element) => {
                        if(element.key.includes('order_registered') || element.key.includes('successfully_ordered')){
                            return element;
                        }
                        return false;
                    })
                    this.rec_status = this.filteredStatuses[1];
                }
            },
            immediate: true
        }
    },
};
</script>
<style>
    .articlenotset{
        display: flex !important;
    }
    .articleset{
        display: none !important;
    }
    .selectedArticleTextBox{
        background-color: rgb(220, 255, 220)
    }
</style>