var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"pb-4"},[(_vm.workpoints.length > 1)?_c('v-col',{staticClass:"mt-1",attrs:{"md":"2"}},[[_c('v-select',{staticStyle:{"width":"100%"},attrs:{"items":_vm.workpoints,"label":_vm.$t('scheduler.work_point'),"item-text":"name","item-value":"id","menu-props":"auto","hide-details":"","outlined":"","dense":"","return-object":""},on:{"change":function () {
                            _vm.getAppointments();
                            _vm.getWorkResources();
                        }},model:{value:(_vm.workPoint),callback:function ($$v) {_vm.workPoint=$$v},expression:"workPoint"}})]],2):_vm._e(),_c('v-col',{attrs:{"md":"2"}},[_c('v-select',{staticClass:"pt-1",staticStyle:{"min-width":"150px!important","z-index":"99"},attrs:{"items":_vm.formattedCalendarViewTypes,"label":_vm.$t('scheduler.calendar_type'),"item-text":"text","item-value":"id","outlined":"","dense":"","return-object":""},model:{value:(_vm.viewType),callback:function ($$v) {_vm.viewType=$$v},expression:"viewType"}})],1),(_vm.filteredResourceTypes.length > 1)?_c('v-col',{staticClass:"mt-1",attrs:{"md":"2"}},[_c('v-select',{staticStyle:{"width":"100%"},attrs:{"items":_vm.filteredResourceTypes,"label":_vm.$t('resource_type'),"item-text":"text","item-value":"id","menu-props":"auto","hide-details":"","outlined":"","dense":"","return-object":""},model:{value:(_vm.resourceType),callback:function ($$v) {_vm.resourceType=$$v},expression:"resourceType"}})],1):_vm._e(),_c('v-col',[_c('div',{staticClass:"mt-2"},[_c('strong',[_c('v-icon',{staticStyle:{"font-size":"24px"},on:{"click":function($event){return _vm.setPrevious()}}},[_vm._v("mdi-chevron-left")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-calendar-month")]),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":true,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","max-width":"300px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"color":"#1976d2"}},'span',attrs,false),on),[(_vm.showDayPicker)?_c('span',[_vm._v(_vm._s(_vm.getDay(_vm.selectedDate))+" "+_vm._s(_vm.formatDate( _vm.selectedDate, "dd-mm-yyyy" ))+" ")]):_vm._e(),(_vm.showWeekPicker)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("measurement_units.week") + " " + _vm.getWeekNumber(_vm.selectedDate) + ", " + _vm.getMonthAndYearInText( _vm.selectedDate ))+" ")]):_vm._e(),(_vm.showMonthPicker)?_c('span',[_vm._v(" "+_vm._s(_vm.getMonthAndYearInText(_vm.selectedDate))+" ")]):_vm._e()])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[(_vm.viewType)?_c('v-date-picker',{attrs:{"id":"datepicker","first-day-of-week":1,"weekday-format":_vm.getDay,"allowed-dates":_vm.allowedDates(_vm.checkNotAvailableDays()),"elevation":"2","no-title":"","locale":_vm.userLanguageId,"full-width":"","events":_vm.functionEvents,"type":_vm.datePickerType,"picker-date":_vm.pickerDate},on:{"update:pickerDate":function($event){_vm.pickerDate=$event},"update:picker-date":function($event){_vm.pickerDate=$event}},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}}):_vm._e()],1),_c('v-icon',{staticStyle:{"font-size":"24px"},on:{"click":function($event){return _vm.setNext()}}},[_vm._v("mdi-chevron-right")])],1)])]),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-text-field',{staticClass:"mr-3 pt-1 to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('users.search'),"prepend-inner-icon":"mdi-magnify","clearable":true,"clear-icon":"mdi-close","outlined":false,"dense":false},on:{"input":function($event){_vm.debounceInput();
                    _vm.updateLocalStorageValues();},"change":function($event){return _vm.updateLocalStorageValues()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.resetDate();
                    _vm.resetSearchValue();}}},[_vm._v(" "+_vm._s(_vm.$t("scheduler.today"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-calendar-today")])],1)],1)],1),_c('br'),_c('v-row',{class:_vm.search && _vm.search.length > 0 ? 'inactive' : 'active'},[(
                _vm.workpoints.length > 0 &&
                    _vm.timeslots.length > 0 &&
                    _vm.workresources.length > 0 &&
                    _vm.viewType &&
                    _vm.viewType.key === 'day'
            )?_c('v-col',{staticClass:"pa-0",staticStyle:{"min-height":"80vh","overflow":"scroll !important"},attrs:{"id":"dayview","cols":"12","sm":"12"}},[(
                    _vm.workpoints.length > 0 &&
                        _vm.timeslots.length > 0 &&
                        _vm.workresources.length > 0 &&
                        _vm.viewType.key === 'day'
                )?_c('day-view',{attrs:{"workpoints":_vm.workpoints,"worktype":_vm.worktype,"timeslots":_vm.timeslots,"workresources":_vm.workresources,"appointments":_vm.appointments,"selectedDate":_vm.selectedDate,"minimumTimeTask":_vm.minimumTimeTask,"resourceType":_vm.resourceType,"readonlygrids":false},on:{"update:workpoints":function($event){_vm.workpoints=$event},"update:worktype":function($event){_vm.worktype=$event},"update:timeslots":function($event){_vm.timeslots=$event},"update:workresources":function($event){_vm.workresources=$event},"update:appointments":function($event){_vm.appointments=$event},"update:selectedDate":function($event){_vm.selectedDate=$event},"update:selected-date":function($event){_vm.selectedDate=$event},"update:minimumTimeTask":function($event){_vm.minimumTimeTask=$event},"update:minimum-time-task":function($event){_vm.minimumTimeTask=$event},"update:resourceType":function($event){_vm.resourceType=$event},"update:resource-type":function($event){_vm.resourceType=$event}}}):_vm._e()],1):_vm._e(),(
                _vm.viewType &&
                    (_vm.viewType.key === 'week' || _vm.viewType.key === 'month')
            )?_c('v-col',[_c('WeekView',{attrs:{"selected-date":_vm.selectedDate,"workResources":_vm.workresources,"calendarType":_vm.viewType.key.toLowerCase(),"workPoint":_vm.workPoint,"resourceType":_vm.resourceType,"timeSlots":_vm.workpointstimings,"minimum-work-duration":_vm.minimumTimeTask},on:{"selectedDateChanged":function (val) { return (_vm.selectedDate = val); },"update:workResources":function($event){_vm.workresources=$event},"update:work-resources":function($event){_vm.workresources=$event},"update:workPoint":function($event){_vm.workPoint=$event},"update:work-point":function($event){_vm.workPoint=$event},"update:resourceType":function($event){_vm.resourceType=$event},"update:resource-type":function($event){_vm.resourceType=$event},"update:minimumWorkDuration":function($event){_vm.minimumTimeTask=$event},"update:minimum-work-duration":function($event){_vm.minimumTimeTask=$event}}})],1):_vm._e(),(
                _vm.workpoints.length > 0 &&
                    _vm.timeslots.length == 0 &&
                    _vm.workresources.length > 0 &&
                    new Date(_vm.selectedDate).getDay() == 0 &&
                    _vm.viewType &&
                    _vm.viewType.key === 'day'
            )?_c('v-col',{staticClass:"pa-0",attrs:{"id":"dayview","cols":"12","sm":"12"}},[_c('day-view',{attrs:{"workpoints":_vm.workpoints,"worktype":_vm.worktype,"timeslots":[
                    {
                        id: 13,
                        work_point_id: 0,
                        dow: 0,
                        start_time: '07:00:00',
                        end_time: '20:30:00',
                        is_excluded: 0,
                    } ],"workresources":_vm.workresources,"appointments":_vm.appointments,"selectedDate":_vm.selectedDate,"minimumTimeTask":_vm.minimumTimeTask,"resourceType":_vm.resourceType,"readonlygrids":true},on:{"update:workpoints":function($event){_vm.workpoints=$event},"update:worktype":function($event){_vm.worktype=$event},"update:workresources":function($event){_vm.workresources=$event},"update:appointments":function($event){_vm.appointments=$event},"update:selectedDate":function($event){_vm.selectedDate=$event},"update:selected-date":function($event){_vm.selectedDate=$event},"update:minimumTimeTask":function($event){_vm.minimumTimeTask=$event},"update:minimum-time-task":function($event){_vm.minimumTimeTask=$event},"update:resourceType":function($event){_vm.resourceType=$event},"update:resource-type":function($event){_vm.resourceType=$event}}})],1):_vm._e(),(
                (_vm.workpoints.length == 0 ||
                    (_vm.timeslots.length == 0 &&
                        new Date(_vm.selectedDate).getDay() != 0) ||
                    _vm.workresources.length == 0) &&
                    _vm.isLoadedCriticalThingsForDayView == true
            )?_c('v-col',{staticStyle:{"min-height":"80vh"},attrs:{"cols":"12","sm":"12"}},[[_c('div',{staticStyle:{"position":"relative","top":"30vh","left":"15vw"}},[_vm._v(" "+_vm._s(_vm.$t( "scheduler.text_messages.work_points_or_work_resources_or_work_time_slots_not_defined_for_company" ))+" ")])]],2):_vm._e()],1),(_vm.resetDataTableComp)?_c('v-row',{staticClass:"mt-n9",class:_vm.search && _vm.search.length > 0 ? 'active' : 'inactive'},[_c('v-data-table',{key:_vm.forceReloadComponentKey,staticClass:"elevation-0",staticStyle:{"width":"100%"},attrs:{"fixed-header":"","footer-props":{
                'items-per-page-options': _vm.dataTableOptions,
            },"options":_vm.options,"server-items-length":_vm.totalItems,"loading-text":this.$t('loading'),"headers":_vm.searchHeaders,"items":_vm.searchData,"items-per-page":5,"height":'calc(-210px + 100vh)',"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":[function($event){_vm.sortBy=$event},_vm.updateSort],"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":[function($event){_vm.sortDesc=$event},_vm.updateSortOrder],"update:page":_vm.updatePagination,"update:items-per-page":_vm.updateItemsPerPage},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('tr',{class:_vm.addClass(item),style:(_vm.addTaskStyle(item)),on:{"click":function($event){return _vm.editScheduler(item)}}},[_c('td',[(
                                item &&
                                    item.appointment_status &&
                                    item.appointment_status.key ==
                                        'appointment_is_executed'
                            )?_c('v-icon',{staticStyle:{"font-size":"20px"},attrs:{"color":"white"}},[_vm._v(" mdi-account-check ")]):_vm._e(),_vm._v(" "+_vm._s(item.work_type.name)+" ")],1),_c('td',[_vm._v(" "+_vm._s(item.start_time)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.client.name)+" "+_vm._s(item.client.first_name ? item.client.first_name : "")+" ")]),_c('td',[(item.vehicle)?[_vm._v(" "+_vm._s(item.vehicle.registration_number)+" ")]:_vm._e()],2),_c('td',[(item.vehicle)?[_vm._v(" "+_vm._s(item.vehicle.vin)+" ")]:_vm._e()],2),_c('td',[_vm._v(" "+_vm._s(item.created_at)+" ")])])]}}],null,false,589309330)})],1):_vm._e(),_c('v-btn',{staticClass:"d-none",attrs:{"id":"create-calendar"},on:{"click":function($event){return _vm.createCalendar()}}}),_c('v-btn',{staticClass:"d-none",attrs:{"id":"print-report"},on:{"click":function($event){return _vm.printReportFromTopButton()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }