<template>
    <v-dialog
        persistent
        @click:outside="falseButtonClicked()"
        @keydown.esc="falseButtonClicked()"
        v-model="internalShowDialog"
        min-width="600"
        max-width="600"
    >
        <v-card>
            <!-- Card Title -->
            <v-card-title class="text-title">
                {{ $t("min_article_stock_title") }}
            </v-card-title>
            <!-- Card Content -->
            <v-card-text class="dialog-text text-content" color="black">
                <v-row class="mb-0">
                    <v-col>
                        <custom-money-input
                            class="mt-4"
                            ref="min_alert_stock"
                            v-model="min_alert_stock"
                            :label="$t('min_stock_for_alert_keys.text_field_title')"
                            v-bind:properties="{
                                prefix: currencySymbol,
                                readonly: false,
                                disabled: false,
                                outlined: false,
                                placeholder: getDecimalNumber() == 2 ? '0.00' : '0.000',
                                dense: true,
                                hideDetails: 'auto',
                            }"
                            v-bind:options="{
                                locale: userLanguageId,
                                length: 12,
                                precision: defaultQuantityDecimals,
                                empty: getDecimalNumber() == 2 ? 0.0 : 0.0,
                            }"
                        />
                    </v-col>
                    <v-col>
                        <v-checkbox
                            v-model="is_favorite"
                            v-on="on"
                            class="shrink pa-0 mt-5"
                            color="primary"
                            :label="$t('min_stock_for_alert_keys.is_favorite')"
                        ></v-checkbox>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <!-- Card Actions -->
            <v-card-actions class="pr-2">
                <v-spacer></v-spacer>
                <v-btn
                    color="grey lighten-2"
                    class="px-4"
                    @click="falseButtonClicked()"
                >
                    {{ $t("nav_buttons.cancel") }}
                    <v-icon dark right> mdi-keyboard-return </v-icon>
                </v-btn>
                <v-btn
                    class="px-4"
                    :color="'success'"
                    @click="trueButtonClicked()"
                >
                    {{ $t("nav_buttons.save") }}
                    <v-icon dark right> mdi-content-save </v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import moment from "moment";
import CustomMoneyInput from "@/components/CustomInputs/MoneyInput";

export default {
    name: "BulkPayment",
    components: {
        "custom-money-input": CustomMoneyInput,
    },
    props: {
        showDialog: [Number, Boolean],
        item: [Object],
        familyArticle: [Object]
    },
    data() {
        return {
            is_clicked: false,
            is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
            internalShowDialog: this.showDialog,
            isLoading: false,

            is_favorite: false,
            min_alert_stock: null,
            defaultQuantityToSet: 0,
            defaultQuantityDecimals: 2
        };
    },
    async beforeMount(){
    },
    methods: {
        makeSubmitData(){
            return {
                min_alert_stock: this.min_alert_stock,
                is_favorite:     this.is_favorite
            } 
        },
        saveArticle(){
            return new Promise((resolve) => {
                let data  = this.makeSubmitData();
                axios.put(API_BASE_URL + '/stocks/' + this.item.id, data, {headers: this.header})
                    .then(data => {
                        this.$toast.success(this.$t('articles.text_messages.article_updated_successfully'));
                    })
                    .catch((error) =>{
                    })
                    .finally(() => {
                        resolve();
                    });
            })
        },
        falseButtonClicked() {
            this.$emit("update:showDialog", false);
            this.internalShowDialog = false;
        },
        async trueButtonClicked() {
            this.saveArticle().then(() => {
                this.item.min_alert_stock = this.min_alert_stock;
                this.item.is_favorite = this.is_favorite;
                this.is_clicked = true;
                this.$emit("update:showDialog", false);
                this.internalShowDialog = false;
            });
        },
    },
    computed: {
    },
    watch: {
        showDialog: {
            handler: function(val) {
                this.internalShowDialog = val;
            },
        },
        item:{
            handler: function(val){
                if(val){
                    this.min_alert_stock = val.min_alert_stock;
                    this.is_favorite     = val.is_favorite;
                }
                else{
                    this.is_favorite = false;
                    this.min_alert_stock = 0;
                }
            },
            immediate: true
        },
        familyArticle: {
            handler: function(val){
                this.defaultQuantityToSet = val.default_quantity;
                this.defaultQuantityDecimals = val.quantity_decimals;
                if(!this.min_alert_stock){
                    this.min_alert_stock = this.defaultQuantityToSet;
                }
            },
            immediate: true
        }
    },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__subtitle,
.v-dialog > .v-card > .v-card__text {
    padding: 0 24px 27px !important;
}
.text-content {
    font-size: 18px !important;
}
.text-title {
    font-size: 24px !important;
}
</style>
