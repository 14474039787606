<template>
    <v-dialog
        persistent
        @click:outside="falseButtonClicked()"
        @keydown.esc="falseButtonClicked()"
        v-model="internalShowDialog"
        min-width="500"
        max-width="1204"
    >
        <v-card>
            <v-card-title
                class="text-title"
                style="margin-bottom:0px"
            >
                {{ $t("appointment_details.heading") }}
                <v-spacer></v-spacer>
            </v-card-title>

            <v-card-text
                class="dialog-text text-content pa-3"
                color="black"
                :class="isLoaded ? '' : 'loading_content'"
            >
                <template v-if="isLoaded">
                    <v-data-table
                        :footer-props="{
                            'items-per-page-options': dataTableOptions,
                        }"
                        :headers="dataHeader"
                        :hide-default-footer="true"
                        :hide-default-header="true"
                        :items="selectedArticles"
                        :item-class="row_classes"
                        :items-per-page="10000"
                        class="elevation-0"
                        group-by="group"
                        show-group-by
                        style="width: 100%"
                        disable
                    >
                        <template v-slot:group.header="{ group }">
                            <template v-if="group && group.trim() !== ''">
                                <td
                                    :colspan="dataHeader.length"
                                    class="group-header"
                                >
                                    <strong>{{ group }}</strong>
                                </td>
                            </template>
                        </template>
                        <template #header="{ props: { headers } }">
                            <thead class="v-data-table-header">
                                <tr>
                                    <th
                                        v-for="header in headers"
                                        :key="header.value"
                                        scope="col"
                                        class="text-right"
                                        :style="
                                            getHeaderWidth(header, header.width)
                                        "
                                    >
                                        <template
                                            v-if="
                                                header.value ==
                                                    'article_original_name'
                                            "
                                        >
                                            <v-row justify="space-between">
                                                <v-col
                                                    class="d-flex"
                                                    cols="12"
                                                    sm="2"
                                                >
                                                    {{ header.text }}
                                                </v-col>
                                            </v-row>
                                        </template>
                                        <template
                                            v-else-if="
                                                header.value == 'actions'
                                            "
                                        >
                                            <v-row justify="space-between">
                                                <v-col
                                                    class="d-flex dontbreakline"
                                                    cols="12"
                                                    sm="2"
                                                >
                                                    {{ header.text }}
                                                </v-col>
                                            </v-row>
                                        </template>
                                        <template v-else>
                                            {{ header.text }}
                                        </template>
                                    </th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:no-data>
                            <span></span>
                        </template>

                        <template v-slot:item.information="{ item }">
                            <div class="d-flex flex-row information-class">
                                <div
                                    v-if="
                                        item.supplier &&
                                            item.supplier.length > 0 &&
                                            item.supplier[0] &&
                                            item.supplier[0].id >= 0 &&
                                            item.article_original_name &&
                                            item.article_original_name.family &&
                                            item.article_original_name.family
                                                .is_stock_managed
                                    "
                                    :class="
                                        typeof item.article != 'object'
                                            ? item.article
                                                  .internal_company_article &&
                                              item.article
                                                  .internal_company_article
                                                  .length > 0 &&
                                              item.article
                                                  .internal_company_article[0]
                                                  .stock < item.quantity
                                                ? 'articleNotInStock'
                                                : item.supplier &&
                                                  item.supplier.length > 0 &&
                                                  item.supplier[0].id != 0 &&
                                                  item.saved_supplier_order_detail &&
                                                  item
                                                      .saved_supplier_order_detail
                                                      .supplier_order &&
                                                  [
                                                      10091000,
                                                      10091001,
                                                      10091004,
                                                  ].includes(
                                                      item
                                                          .saved_supplier_order_detail
                                                          .supplier_order
                                                          .status_id
                                                  )
                                                ? (item.saved_supplier_order
                                                    ? new Date(
                                                          item.saved_supplier_order.delivery_date
                                                      ) >=
                                                      new Date(
                                                          formatDate(
                                                              getCurrentDate(),
                                                              'yyyy-mm-dd'
                                                          )
                                                      )
                                                    : false)
                                                    ? 'articleWaitingForSupplierOrderOrange'
                                                    : 'articleWaitingForSupplierOrder'
                                                : 'articleInStock'
                                            : ''
                                    "
                                    style="max-width: 100px; border:0px; width: 60px;"
                                    dense
                                    return-object
                                    readonly
                                    flat
                                    solo
                                    hide-details="auto"
                                >
                                    <v-icon
                                        v-if="
                                            item.supplier &&
                                                item.supplier.length > 0 &&
                                                item.supplier[0].id != 0 &&
                                                item.saved_supplier_order_detail &&
                                                item.saved_supplier_order_detail
                                                    .supplier_order &&
                                                [
                                                    10091000,
                                                    10091001,
                                                    10091004,
                                                ].includes(
                                                    item
                                                        .saved_supplier_order_detail
                                                        .supplier_order
                                                        .status_id
                                                )
                                        "
                                        slot="prepend"
                                        :color="
                                            item.saved_supplier_order_detail &&
                                            item.saved_supplier_order_detail
                                                .delivery_date &&
                                            new Date(
                                                item.saved_supplier_order_detail
                                            ) >=
                                                new Date(
                                                    formatDate(
                                                        getCurrentDate(),
                                                        'yyyy-mm-dd'
                                                    )
                                                )
                                                ? 'orange'
                                                : 'red'
                                        "
                                    >
                                        mdi-timer-sand
                                    </v-icon>
                                    <v-icon
                                        v-if="
                                            item.saved_supplier_order_detail &&
                                                item.saved_supplier_order_detail
                                                    .supplier_order &&
                                                [10091005].includes(
                                                    item
                                                        .saved_supplier_order_detail
                                                        .supplier_order
                                                        .status_id
                                                )
                                        "
                                        slot="prepend"
                                        :color="'green'"
                                    >
                                        mdi-check
                                    </v-icon>
                                    <template v-if="item.supplier.length > 0">
                                        {{ item.supplier[0].identifier }}
                                    </template>
                                </div>
                                <div
                                    style="max-width: 100px; border:0px; width: 60px; float:right"
                                    v-else-if="
                                        item.article_original_name &&
                                            item.article_original_name.family &&
                                            item.article_original_name.family
                                                .is_stock_managed
                                    "
                                >
                                    INT
                                </div>
                                <v-tooltip
                                    bottom
                                    z-index="9999"
                                    v-if="
                                        item.article.family &&
                                            item.article.family
                                                .is_stock_managed == 1 &&
                                            !(
                                                item.supplier &&
                                                item.supplier.length > 0 &&
                                                item.supplier[0] &&
                                                item.supplier[0].id
                                            )
                                    "
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-if="
                                                item.article
                                                    .internal_company_article &&
                                                    item.article
                                                        .internal_company_article
                                                        .length > 0 &&
                                                    item.article
                                                        .internal_company_article[0]
                                                        .stock < item.quantity
                                            "
                                            small
                                            v-bind="attrs"
                                            v-on="on"
                                            color="red"
                                        >
                                            mdi-information-outline
                                        </v-icon>
                                    </template>
                                    <span>{{
                                        $t(
                                            "billings.text_messages.article_does_not_have_stock"
                                        )
                                    }}</span>
                                </v-tooltip>
                            </div>
                        </template>
                        <template v-slot:item.article_original_name="{ item }">
                            <v-row
                                @mouseover="
                                    toggleElementById(item.srno, 'visible')
                                "
                                @mouseleave="
                                    toggleElementById(item.srno, 'hidden')
                                "
                            >
                                <v-col cols="12" sm="12" style="display:flex">
                                    <div class="" style="width: 100%">
                                        <template
                                            v-if="
                                                item.article_original_name &&
                                                    item.article_original_name
                                                        .title_description
                                                        .length > 0
                                            "
                                        >
                                            <div class="cursor-pointer-trans">
                                                <span>
                                                    {{
                                                        item
                                                            .article_original_name
                                                            .title_description[0]
                                                            .description
                                                    }}<br />
                                                    <span
                                                        class="article_identifier"
                                                    >
                                                        {{
                                                            item
                                                                .article_original_name
                                                                .identifier
                                                        }}
                                                    </span>
                                                </span>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div
                                                class="article_identifier"
                                                v-html="item.text"
                                            ></div>
                                        </template>
                                    </div>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.quantity="{ item }">
                            <v-row
                                @mouseover="
                                    toggleElementById(item.srno, 'visible')
                                "
                                @mouseleave="
                                    toggleElementById(item.srno, 'hidden')
                                "
                            >
                                <v-col cols="12" sm="12">
                                    <template v-if="item.quantity">
                                        <!-- {{ item.article_original_name }} -->
                                        <span>
                                            {{
                                                fixDecimal(
                                                    item.quantity,
                                                    item
                                                        ? item.article_original_name
                                                            ? item
                                                                  .article_original_name
                                                                  .family
                                                                  .quantity_decimals
                                                            : 0
                                                        : 0
                                                )
                                            }}
                                            {{ item.selectedmu.value }}
                                            <!-- Here we have hardcoded if nothing is their then family mu -->
                                        </span>
                                        <!-- <span> {{ item.quantity }} </span> -->
                                    </template>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.rounded_unit_price="{ item }">
                            <v-row
                                @mouseover="
                                    toggleElementById(item.srno, 'visible')
                                "
                                @mouseleave="
                                    toggleElementById(item.srno, 'hidden')
                                "
                            >
                                <v-col cols="12" sm="12">
                                    <span
                                        v-if="
                                            billType &&
                                                billType.key == 'credit_note'
                                        "
                                        class="text-no-wrap"
                                    >
                                        <!-- {{ item.rounded_unit_price ? (parseFloat(item.rounded_unit_price) * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber()) : (0).toFixed(getDecimalNumber()) }} -->
                                        {{ currencySymbol }}
                                        {{
                                            parseFloat(
                                                item.rounded_unit_price
                                            ).toFixed(getDecimalNumber())
                                        }}
                                    </span>
                                    <span v-else class="text-no-wrap">
                                        {{ currencySymbol }}
                                        {{
                                            item.rounded_unit_price
                                                ? parseFloat(
                                                      item.rounded_unit_price
                                                  ).toFixed(getDecimalNumber())
                                                : (0).toFixed(
                                                      getDecimalNumber()
                                                  )
                                        }}
                                    </span>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.red_percentage="{ item }">
                            <v-row
                                @mouseover="
                                    toggleElementById(item.srno, 'visible')
                                "
                                @mouseleave="
                                    toggleElementById(item.srno, 'hidden')
                                "
                            >
                                <v-col cols="12" sm="12">
                                    <span>
                                        {{
                                            (item.red_percentage
                                                ? item.red_percentage
                                                : 0
                                            ).toFixed(getDecimalNumber())
                                        }}
                                    </span>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.tva="{ item }">
                            <v-row
                                @mouseover="
                                    toggleElementById(item.srno, 'visible')
                                "
                                @mouseleave="
                                    toggleElementById(item.srno, 'hidden')
                                "
                            >
                                <v-col cols="12" sm="12">
                                    <span>
                                        {{
                                            (item.tva ? item.tva : 0).toFixed(
                                                getDecimalNumber()
                                            )
                                        }}
                                    </span>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.thtva="{ item }">
                            <v-row
                                @mouseover="
                                    toggleElementById(item.srno, 'visible')
                                "
                                @mouseleave="
                                    toggleElementById(item.srno, 'hidden')
                                "
                            >
                                <v-col cols="12" sm="12">
                                    <span
                                        v-if="
                                            billType &&
                                                billType.key == 'credit_note'
                                        "
                                    >
                                        <!-- {{ ((item.total ? item.total : 0) * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber()) }} -->
                                        {{
                                            item.thtva.toFixed(
                                                getDecimalNumber()
                                            )
                                        }}
                                    </span>
                                    <span v-else>
                                        {{
                                            (item.thtva
                                                ? item.thtva
                                                : 0
                                            ).toFixed(getDecimalNumber())
                                        }}
                                    </span>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.total="{ item }">
                            <v-row
                                @mouseover="
                                    toggleElementById(item.srno, 'visible')
                                "
                                @mouseleave="
                                    toggleElementById(item.srno, 'hidden')
                                "
                            >
                                <v-col cols="12" sm="12">
                                    <span
                                        v-if="
                                            billType &&
                                                billType.key == 'credit_note'
                                        "
                                        class="text-no-wrap"
                                    >
                                        <!-- {{ ((item.total ? item.total : 0) * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber()) }} -->
                                        {{ currencySymbol }}
                                        {{
                                            item.total.toFixed(
                                                getDecimalNumber()
                                            )
                                        }}
                                    </span>
                                    <span v-else class="text-no-wrap">
                                        {{ currencySymbol }}
                                        {{
                                            (item.total
                                                ? item.total
                                                : 0
                                            ).toFixed(getDecimalNumber())
                                        }}
                                    </span>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:body.append>
                            <!-- Total -->
                            <tr
                                class="last_row"
                                v-if="selectedArticles.length > 0"
                                style="background-color:rgba(142, 142, 142, 0.07)"
                            >
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right ma-0 pa-0 mt-1 mb-1">
                                    <custom-money-input
                                        :disabled="true"
                                        class="pt-0 to-upper right-aligned-input shrink float-right pr-2"
                                        hide-details="auto"
                                        dense
                                        tabindex="3"
                                        style="width:80px"
                                        ref="reduction_on_order"
                                        v-model="reduction_on_order"
                                        v-on:keydown="
                                            handleInput($event, 'float', 5)
                                        "
                                        prefix="%"
                                        v-bind:properties="{
                                            prefix: '%',
                                            readonly: true,
                                            disabled: true,
                                            outlined: false,
                                            placeholder:
                                                getDecimalNumber() == 2
                                                    ? '0.00'
                                                    : '0.000',
                                            dense: true,
                                            hideDetails: 'auto',
                                        }"
                                        v-bind:options="{
                                            locale: 'en',
                                            length: 12,
                                            precision: getDecimalNumber(),
                                            empty:
                                                getDecimalNumber() == 2
                                                    ? 0.0
                                                    : 0.0,
                                        }"
                                    />
                                </th>
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right">
                                    <span
                                        v-if="
                                            billType &&
                                                billType.key == 'credit_note'
                                        "
                                        class="text-no-wrap"
                                    >
                                        {{ currencySymbol }}
                                        {{
                                            articleTHTVATotalBeforeReduction.toFixed(
                                                getDecimalNumber()
                                            )
                                        }}
                                    </span>
                                    <span v-else class="text-no-wrap">
                                        {{ currencySymbol }}
                                        {{
                                            (articleTHTVATotalBeforeReduction
                                                ? articleTHTVATotalBeforeReduction
                                                : 0
                                            ).toFixed(getDecimalNumber())
                                        }}
                                    </span>
                                </th>
                                <th class="text-sm-right">
                                    <span
                                        v-if="
                                            billType &&
                                                billType.key == 'credit_note'
                                        "
                                        class="text-no-wrap"
                                    >
                                        <!-- {{ (articleTotal * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber())}} -->
                                        {{ currencySymbol }}
                                        {{
                                            articleTotalBeforeReduction.toFixed(
                                                getDecimalNumber()
                                            )
                                        }}
                                    </span>
                                    <span v-else class="text-no-wrap">
                                        {{ currencySymbol }}
                                        {{
                                            (articleTotalBeforeReduction
                                                ? articleTotalBeforeReduction
                                                : 0
                                            ).toFixed(getDecimalNumber())
                                        }}
                                    </span>
                                </th>
                            </tr>
                            <!-- Total After Reduction -->
                            <tr
                                class="last_row"
                                v-if="
                                    selectedArticles.length > 0 &&
                                        total_reduction > 0
                                "
                                style="background-color:rgba(142, 142, 142, 0.07)"
                            >
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right">
                                    {{ currencySymbol }}
                                    - {{ total_reduction }}
                                </th>
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right"></th>
                                <th class="text-sm-right">
                                    <span
                                        v-if="
                                            billType &&
                                                billType.key == 'credit_note'
                                        "
                                        class="text-no-wrap"
                                    >
                                        <!-- {{ (articleTotal * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber())}} -->
                                        {{ currencySymbol }}
                                        {{
                                            articleTotal.toFixed(
                                                getDecimalNumber()
                                            )
                                        }}
                                    </span>
                                    <span v-else class="text-no-wrap">
                                        {{ currencySymbol }}
                                        {{
                                            (articleTotal
                                                ? articleTotal
                                                : 0
                                            ).toFixed(getDecimalNumber())
                                        }}
                                    </span>
                                </th>
                            </tr>
                            <!-- Total Paid -->
                            <tr
                                class="last_row"
                                v-if="
                                    selectedArticles &&
                                        selectedArticles.length > 0 &&
                                        selectedBill &&
                                        selectedBill.type &&
                                        ['bill', 'credit_note'].includes(
                                            selectedBill.type.key
                                        )
                                "
                                style="background-color:rgba(142, 142, 142, 0.07)"
                            >
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right" v-text="''" />
                                <!-- <th class="text-sm-right" v-text="''" /> -->
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right">
                                    <span class="mr-0">
                                        {{ $t("billings.paid") }}
                                    </span>
                                </th>
                                <th
                                    class="text-sm-right text-no-wrap"
                                    align="right"
                                >
                                    <span>
                                        {{ currencySymbol }}
                                        ({{ totalPaid }})
                                    </span>
                                </th>
                            </tr>
                            <!-- Total Balance -->
                            <tr
                                class="last_row"
                                v-if="
                                    selectedArticles &&
                                        selectedArticles.length > 0 &&
                                        selectedBill &&
                                        selectedBill.type &&
                                        ['bill', 'credit_note'].includes(
                                            selectedBill.type.key
                                        )
                                "
                                style="background-color:rgba(142, 142, 142, 0.07)"
                            >
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-left" v-text="''" />
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right" v-text="''" />
                                <!-- <th class="text-sm-right" v-text="''" /> -->
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right">
                                    {{ $t("billings.balance") }}
                                </th>
                                <th class="text-sm-right">
                                    <span class="text-no-wrap">
                                        {{ currencySymbol }}
                                        {{ paymentBalance }}
                                    </span>
                                </th>
                            </tr>
                        </template>
                    </v-data-table>
                </template>
                <template v-else>
                    <v-container class="d-flex justify-center align-center">
                        <v-progress-circular
                            indeterminate
                            size="20"
                            width="2"
                            color="primary"
                        ></v-progress-circular>
                    </v-container>
                </template>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pr-2">
                <v-spacer></v-spacer>
                <v-btn color="grey lighten-2" @click="falseButtonClicked()">
                    {{ $t("attachment.close") }}
                    <v-icon dark right> mdi-close </v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import CustomMoneyInput from "@/components/CustomInputs/MoneyInput";
import billMixin from "@/mixins/billMixin";
import splitPaymentMixin from "@/mixins/splitPaymentMixin";

export default {
    mixins: [billMixin, splitPaymentMixin],
    components: {
        "custom-money-input": CustomMoneyInput,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
        showDialog: [Boolean],
    },
    data() {
        return {
            internalShowDialog: this.showDialog,
            appointmentData: null,
            isLoaded: false,
            dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
            mus: [],

            selectedBill: null,
            selectedArticles: [],
            unit_price: 0.0,
            quantity: 4,
            red_percentage: 0.0,
            rowNumber: 0,
            articleTHTVATotal: 0.0,
            articleTHTVATotalBeforeReduction: 0.0,
            articleTotal: 0.0,
            articleTotalBeforeReduction: 0.0,
            total_reduction: 0.0,
            reduction_on_order: 0,
            billType: null,
            dataHeader: [
                {
                    text: "",
                    align: "right",
                    width: "10px",
                    sortable: false,
                    value: "information",
                },
                {
                    text: this.$t("scheduler.article"),
                    align: "start",
                    width: 450,
                    sortable: true,
                    value: "article_original_name",
                },
                {
                    text: this.$t("scheduler.quantity"),
                    align: "end",
                    width: 80,
                    sortable: true,
                    value: "quantity",
                },
                // { text: this.$t('scheduler.unit_price'),           align: 'end',    width:80,  sortable: false,         value: 'unit_price' },
                {
                    text: this.$t("billings.unit_price"),
                    align: "end",
                    width: 80,
                    sortable: false,
                    value: "rounded_unit_price",
                },
                {
                    text: this.$t("scheduler.red_percentage"),
                    align: "end",
                    width: 80,
                    sortable: false,
                    value: "red_percentage",
                },
                // { text: this.$t('scheduler.tarrif'),               align: 'end',    width:100,  sortable: false,         value: 'tarrif' },
                {
                    text: this.$t("scheduler.tva"),
                    align: "end",
                    width: 100,
                    sortable: false,
                    value: "tva",
                },
                {
                    text: this.$t("scheduler.thtva"),
                    align: "end",
                    width: 125,
                    sortable: false,
                    value: "thtva",
                },
                {
                    text: this.$t("scheduler.total"),
                    align: "end",
                    width: 125,
                    sortable: false,
                    value: "total",
                },
            ],
        };
    },
    mounted() {
        this.getMeasurementUnits();
        if (this.data && this.data.data && this.data.data.id) {
            this.fetchAppointmentByID(this.data.data.id);
        }
    },
    computed: {
        currencySymbol() {
            let symbol = null;
            if (this.$store.state.topCurrencyType) {
                symbol = this.parseSymbol(
                    this.$store.state.topCurrencyType.symbol ?? ""
                );
                return symbol;
            }
            return symbol ?? "";
        },
    },
    methods: {
        calculateTotals() {
            let thtva_total = 0;
            let total_total = 0;
            this.selectedArticles.forEach((element) => {
                thtva_total += parseFloat(element.thtva);
                total_total += parseFloat(element.total);
            });
            this.articleTHTVATotalBeforeReduction = parseFloat(thtva_total);
            this.articleTHTVATotal = this.reduction_on_order
                ? parseFloat(
                      this.articleTHTVATotalBeforeReduction -
                          (this.articleTHTVATotalBeforeReduction *
                              this.reduction_on_order) /
                              100
                  )
                : this.articleTHTVATotalBeforeReduction;
            this.articleTotalBeforeReduction = parseFloat(total_total);
            this.articleTotal = this.reduction_on_order
                ? parseFloat(
                      this.articleTotalBeforeReduction -
                          (this.articleTotalBeforeReduction *
                              this.reduction_on_order) /
                              100
                  )
                : this.articleTotalBeforeReduction;
            this.total_reduction = parseFloat(
                (this.articleTotalBeforeReduction ?? 0) *
                    ((this.reduction_on_order ?? 0) / 100)
            ).toFixed(this.getDecimalNumber());
        },
        async getMeasurementUnits() {
            await this.getCodes("measurement_units", "measurement_units").then(
                (data) => {
                    this.mus = data;
                    this.mus = this.mus.sort(function(a, b) {
                        if (a.text < b.text) {
                            return -1;
                        }
                        if (a.text > b.text) {
                            return 1;
                        }
                        return 0;
                    });
                }
            );
        },
        row_classes(item) {
            return "articles_row_item";
        },
        falseButtonClicked(item) {
            this.resetPage();
            this.$emit("update:showDialog", false);
            this.internalShowDialog = false;
        },
        resetPage() {
            this.appointmentData = null;
            this.isLoaded = false;
            this.selectedBill = null;
        },
        fetchAppointmentByID(id) {
            this.isLoaded = false;
            axios
                .get(API_BASE_URL + "/appointments?id=" + id, {
                    headers: this.header,
                })
                .then(({ data }) => {
                    this.appointmentData = data.data;
                    if (this.appointmentData.order) {
                        this.selectedBill = this.appointmentData.order;
                        this.reduction_on_order = this.selectedBill.reduction;
                    }
                    if (
                        this.selectedBill &&
                        this.selectedBill.details &&
                        this.selectedBill.details.length > 0
                    ) {
                        this.bill_note = this.selectedBill.message;
                        this.bill_note_temp = this.selectedBill.message;
                        this.rowNumber = 0;
                        this.setTopCurrencyBasedOnDefaultBank(
                            this.selectedBill.bank_id
                        );
                        this.selectedBill.details.forEach((element) => {
                            let isToMultiplyWithCredNoteMulValue = false;
                            let selectedmu = "";
                            this.mus.forEach((elementmu) => {
                                if (elementmu.id == element.mu_id) {
                                    selectedmu = elementmu;
                                }
                            });
                            
                            if (
                                this.billType &&
                                this.billType.key == "credit_note"
                            ) {
                                isToMultiplyWithCredNoteMulValue = true;
                            }

                            let thtva =
                                element.price * element.quantity -
                                (element.price *
                                    element.quantity *
                                    element.reduction) /
                                    100;
                            this.selectedArticles.push({
                                srno: this.rowNumber++,
                                article: element.article_id,
                                article_original_name: element.article,
                                quantity: element.quantity,
                                unit_price: parseFloat(
                                    isToMultiplyWithCredNoteMulValue
                                        ? parseInt(
                                              this.creditNoteMultiplicationValue
                                                  .value
                                          ) * (element.price ?? 0)
                                        : element.price ?? 0
                                ),
                                minimum_sales_price: parseFloat(
                                    element.minimum_sales_price
                                ),
                                rounded_unit_price: parseFloat(
                                    isToMultiplyWithCredNoteMulValue
                                        ? parseInt(
                                              this.creditNoteMultiplicationValue
                                                  .value
                                          ) * (element.rounded_unit_price ?? 0)
                                        : element.rounded_unit_price ?? 0
                                ),
                                red_percentage: parseFloat(
                                    element.reduction ?? 0
                                ),
                                tarrif: parseFloat(
                                    element.article?.tariff_price
                                ),
                                tva: parseFloat(element.tax),
                                thtva: parseFloat(
                                    isToMultiplyWithCredNoteMulValue
                                        ? parseInt(
                                              this.creditNoteMultiplicationValue
                                                  .value
                                          ) * (thtva ?? 0)
                                        : thtva ?? 0
                                ),
                                stock_property: element.stock_property ?? null,
                                total: parseFloat(
                                    isToMultiplyWithCredNoteMulValue
                                        ? parseInt(
                                              this.creditNoteMultiplicationValue
                                                  .value
                                          ) * (element.total ?? 0)
                                        : element.total ?? 0
                                ),
                                text: element.text,
                                row: element.row,
                                text_position: element.text_position ?? 0,
                                depot_out_id: element.depot_out_id,
                                depot_in_id: element.depot_in_id,
                                dots: null,
                                group: element.group ?? null,
                                selectedmu: selectedmu,
                                currency_id: element.currency_id,
                                order_counter_id: element.order_counter_id,
                            });
                        });
                    }
                })
                .catch((err) => {})
                .finally(() => {
                    this.isLoaded = true;
                });
        },
    },
    watch: {
        data: {
            handler: function(val) {},
            immediate: true,
        },

        selectedArticles() {
            this.calculateTotals();
            this.selectedArticles.forEach((article, index) => {
                // update mus for every article
                if (!article.selectedmu) {
                    article.selectedmu =
                        article.article_original_name.family.mu;
                }
            });

            // Enable or disable depot buttons based upon depot out
            let depotOutAlreadyExists = false;
            this.selectedArticles.forEach((article) => {
                if (article.depot_out_id) {
                    depotOutAlreadyExists = true;
                }
            });

            this.selectedArticles.forEach((article, index) => {

                // Attach Supplier Orders to the articles
                if (
                    this.selectedBill &&
                    this.selectedBill.details &&
                    this.selectedBill.details.length > 0
                ) {
                    let currentArticleId =
                        typeof article.article == "object"
                            ? article.article.id
                            : article.article;
                    this.selectedBill.details.forEach((detailItem) => {
                        console.log(
                            "matching supplier",
                            detailItem.article_id,
                            currentArticleId
                        );
                        if (
                            detailItem &&
                            detailItem.supplier_order_details_id &&
                            detailItem.supplier_order_detail &&
                            currentArticleId == detailItem.article_id
                        ) {
                            article.supplier = [
                                detailItem.supplier_order_detail.supplier_order
                                    .supplier,
                            ];
                            article.saved_supplier_order_detail =
                                detailItem.supplier_order_detail;
                        }
                    });
                }
            });
        },
    },
};
</script>

<style scoped>
.loading_content {
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
