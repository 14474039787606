var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1200"},on:{"click:outside":function($event){return _vm.closePopUpModel()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closePopUpModel()}},model:{value:(_vm.popUpModelVisible),callback:function ($$v) {_vm.popUpModelVisible=$$v},expression:"popUpModelVisible"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t("receptions.modify_receptions"))+" - "+_vm._s(_vm.data.supplier_order_details.article.original_name))])]),_c('v-card-text',[_c('confirmation-model',{attrs:{"showDialog":_vm.confirmationDialog,"openedForOperation":_vm.confirmationDialogOperation,"text":_vm.confirmationDialogConfirmationText,"trueText":_vm.confirmationDialogTrueText,"falseText":_vm.confirmationDialogFalseText},on:{"update:showDialog":function($event){_vm.confirmationDialog=$event},"update:show-dialog":function($event){_vm.confirmationDialog=$event},"update:openedForOperation":function($event){_vm.confirmationDialogOperation=$event},"update:opened-for-operation":function($event){_vm.confirmationDialogOperation=$event},"update:text":function($event){_vm.confirmationDialogConfirmationText=$event},"update:trueText":function($event){_vm.confirmationDialogTrueText=$event},"update:true-text":function($event){_vm.confirmationDialogTrueText=$event},"update:falseText":function($event){_vm.confirmationDialogFalseText=$event},"update:false-text":function($event){_vm.confirmationDialogFalseText=$event},"buttonClicked":_vm.confirmationButtonClicked}}),_c('v-row',[_c('span'),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-autocomplete',{staticClass:"mr-2 to-upper",attrs:{"disabled":!_vm.allowedStatusesToBChanged.includes(
                                _vm.data.status.key
                            ),"flat":"","items":_vm.allSuppliers,"label":_vm.$t('receptions.supplier'),"item-value":"id","item-text":"name","return-object":"","outlined":_vm.is_boxes,"dense":_vm.is_boxes,"hide-no-data":""},model:{value:(_vm.data.supplier),callback:function ($$v) {_vm.$set(_vm.data, "supplier", $$v)},expression:"data.supplier"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-text-field',_vm._g(_vm._b({staticClass:"mr-2 to-upper",attrs:{"label":_vm.$t('receptions.ordered_quantity'),"disabled":_vm.ifDisableReceptionStatus(),"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"input":function($event){_vm.data.supplier_order_details.confirmed_quantity >= _vm.data.supplier_order_details.ordered_quantity ? _vm.setReceptionStatusToStatus('successfully_ordered') :''}},model:{value:(
                            _vm.data.supplier_order_details.ordered_quantity
                        ),callback:function ($$v) {_vm.$set(_vm.data.supplier_order_details, "ordered_quantity", $$v)},expression:"\n                            data.supplier_order_details.ordered_quantity\n                        "}},'v-text-field',_vm.attrs,false),_vm.on))],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-text-field',_vm._g(_vm._b({staticClass:"mr-2 to-upper",attrs:{"label":_vm.$t('receptions.confirmed_quant_label'),"disabled":_vm.ifDisableReceptionStatus(),"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"input":function($event){_vm.data.supplier_order_details.confirmed_quantity >= _vm.data.supplier_order_details.ordered_quantity ? _vm.setReceptionStatusToStatus('successfully_ordered') :''}},model:{value:(
                            _vm.data.supplier_order_details.confirmed_quantity
                        ),callback:function ($$v) {_vm.$set(_vm.data.supplier_order_details, "confirmed_quantity", $$v)},expression:"\n                            data.supplier_order_details.confirmed_quantity\n                        "}},'v-text-field',_vm.attrs,false),_vm.on))],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-text-field',_vm._g(_vm._b({staticClass:"mr-2 to-upper",attrs:{"label":_vm.$t('receptions.already_received_quant_label'),"disabled":"","outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"input":function($event){return _vm.checkQuantityToChangeStatus()}},model:{value:(_vm.already_received_quantity),callback:function ($$v) {_vm.already_received_quantity=$$v},expression:"already_received_quantity"}},'v-text-field',_vm.attrs,false),_vm.on))],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-text-field',_vm._g(_vm._b({staticClass:"mr-2 to-upper",attrs:{"error-messages":_vm.isReceivedQuantityGreaterThen
                                ? [
                                      this.$t(
                                          'receptions.text_messages.received_quantity_cannot_be_greater_then_confirmed_quantity'
                                      ) ]
                                : [],"label":_vm.$t('receptions.received_quant_label'),"disabled":!_vm.isAllowedToChangeReceivedQuantity.includes(
                                _vm.rec_status.key
                            ),"outlined":_vm.is_boxes},on:{"input":function($event){return _vm.checkQuantityToChangeStatus()}},model:{value:(
                            _vm.data.supplier_order_details.received_quantity
                        ),callback:function ($$v) {_vm.$set(_vm.data.supplier_order_details, "received_quantity", $$v)},expression:"\n                            data.supplier_order_details.received_quantity\n                        "}},'v-text-field',_vm.attrs,false),_vm.on))],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-autocomplete',{staticClass:"mr-2",attrs:{"items":_vm.rec_statuses,"label":_vm.$t('receptions.status'),"item-value":"id","item-text":"text","return-object":"","outlined":_vm.is_boxes,"dense":_vm.is_boxes,"hide-details":"","append-icon":"mdi-close"},on:{"click:append":function($event){_vm.status = null}},model:{value:(_vm.rec_status),callback:function ($$v) {_vm.rec_status=$$v},expression:"rec_status"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[(
                    _vm.isActiveConnection &&
                        _vm.rec_status &&
                        (_vm.rec_status.key == 'order_registered' ||
                            _vm.rec_status.key == 'order_supplier_error')
                )?_c('v-btn',{attrs:{"color":"warning","dark":""},on:{"click":function($event){return _vm.sendToSupplier()}}},[_vm._v(" "+_vm._s(_vm.$t("receptions.modify_article.send_order_to_supplier"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-invoice-send ")])],1):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"mr-1 text-white-color",attrs:{"color":"error"},on:{"click":function($event){return _vm.deletePopUp()}}},[_vm._v(" "+_vm._s(_vm.$t("nav_buttons.delete"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-delete ")])],1),_c('v-btn',{attrs:{"color":"grey-lighten-2"},on:{"click":function($event){return _vm.closePopUpModel()}}},[_vm._v(" "+_vm._s(_vm.$t("receptions.modify_article.cancel"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-keyboard-return ")])],1),_c('v-btn',{attrs:{"color":"green","disabled":_vm.isReceivedQuantityGreaterThen,"dark":_vm.isReceivedQuantityGreaterThen ? false : true},on:{"click":function($event){return _vm.SavePopUpModel()}}},[_vm._v(" "+_vm._s(_vm.$t("receptions.modify_article.save"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-content-save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }