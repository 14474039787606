<template>
  <v-btn color="red" style="color: #ffffff">
    {{ $t("nav_buttons.delete") }}
    <v-icon class="ml-2">mdi-trash-can</v-icon>
  </v-btn>
</template>
<script>
export default{
  name: 'DeleteButton',

}
</script>