<template>
    <v-card flat>
        <confirmation-model
            :showDialog.sync="confirmationDialog"
            :openedForOperation.sync="confirmationDialogOperation"
            :checkboxText.sync="confirmationDialogReCheckBoxConfirmationText"
            :text.sync="confirmationDialogConfirmationText"
            :trueText.sync="confirmationDialogTrueText"
            :falseText.sync="confirmationDialogFalseText"
            @buttonClicked="confirmationButtonClicked"
        ></confirmation-model>
        <v-form
            ref="form"
            class="main_form"
            id="article-form"
            @submit.prevent="submit"
        >
            <v-btn type="submit" class="d-none" id="submit-article"></v-btn>
            <input type="hidden" name="id" v-model="form.article_id" />
            <v-container fluid>
                <v-row class="">
                    <v-col cols="12" sm="2">
                        <label class="logoImage ma-4">
                            <img
                                :src="imageUrl"
                                v-if="
                                    (operation === 'edit' &&
                                        form.dynamicProperties &&
                                        form.dynamicProperties.identifier) ||
                                        operation != 'edit'
                                "
                                style="width: 150px; height: 150px; border: 3px dotted #efefef;"
                                @error="imageUrl = fallbackImageSrc"
                            />
                            <input
                                type="file"
                                class="d-none"
                                id="files"
                                ref="files"
                                multiple
                                v-on:change="handleFileUpload"
                            />
                        </label>
                    </v-col>
                    <v-col cols="12" sm="10">
                        <v-row>
                            <v-col cols="12" sm="3" v-if="operation === 'edit'">
                                <v-text-field
                                    :autocomplete="autocompleteValueLocal"
                                    flat
                                    readonly
                                    v-model="form.dynamicProperties.id"
                                    :label="$t('articles.id')"
                                    class="mr-2 mt-1 to-upper custom-readonly-color"
                                    hide-details="auto"
                                    :outlined="is_boxes"
                                    :dense="is_boxes"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3" sm="3">
                                <!-- If in Edit Mode and selected article is having general description format then we can modify the article's family article -->
                                <v-select
                                    :autocomplete="autocompleteValueLocal"
                                    :readonly="
                                        operation === 'edit' &&
                                            !isGeneralDescriptionFormat
                                    "
                                    :class="[
                                        operation == 'edit'
                                            ? 'mt-1 custom-readonly-color'
                                            : '',
                                    ]"
                                    v-model="form.familyArticle"
                                    :items="globalfamilyArticles"
                                    :label="$t('articles.family')"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    @click="cacheCurrentData()"
                                    @change="changeFamilyArticle(true)"
                                    hide-details="auto"
                                    :outlined="is_boxes"
                                    :dense="is_boxes"
                                >
                                </v-select>
                            </v-col>
                            <v-col class="d-flex" cols="3" sm="3">
                                <v-select
                                    :autocomplete="autocompleteValueLocal"
                                    v-model="form.dynamicProperties.status_id"
                                    :items="statuses"
                                    :label="$t('articles.status')"
                                    item-value="id"
                                    item-text="text"
                                    class="mr-2"
                                    hide-details="auto"
                                    :readonly="!isEditGlobalArticleAllowed"
                                    :class="[
                                        !isEditGlobalArticleAllowed
                                            ? 'custom-readonly-color'
                                            : '',
                                    ]"
                                    :outlined="is_boxes"
                                    :dense="is_boxes"
                                ></v-select>
                            </v-col>
                            <v-col
                                class="d-flex"
                                cols="3"
                                :sm="operation == 'edit' ? 3 : 6"
                            >
                                <v-select
                                    :autocomplete="autocompleteValueLocal"
                                    v-model="form.dynamicProperties.approval_id"
                                    :items="approvals"
                                    :label="$t('articles.approval')"
                                    item-value="id"
                                    item-text="text"
                                    class="mr-2"
                                    hide-details="auto"
                                    :readonly="!isEditGlobalArticleAllowed"
                                    :class="[
                                        !isEditGlobalArticleAllowed
                                            ? 'custom-readonly-color'
                                            : '',
                                    ]"
                                    :outlined="is_boxes"
                                    :dense="is_boxes"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    :autocomplete="autocompleteValueLocal"
                                    color="teal"
                                    v-model="form.dynamicProperties.identifier"
                                    @input="
                                        identifierError = [];
                                        debounceHandler();
                                    "
                                    counter
                                    maxlength="13"
                                    :label="
                                        form.familyArticle &&
                                        (form.familyArticle.parent_family_id ==
                                            1 ||
                                            this.form.familyArticle.id == 1)
                                            ? $t('articles.ean')
                                            : $t('articles.identifier')
                                    "
                                    class="mr-2 mt-2 to-upper"
                                    :readonly="!isEditGlobalArticleAllowed"
                                    :class="[
                                        !isEditGlobalArticleAllowed
                                            ? 'custom-readonly-color'
                                            : '',
                                    ]"
                                    :outlined="is_boxes"
                                    :dense="is_boxes"
                                    :error-messages="identifierError"
                                ></v-text-field>
                            </v-col>
                            <v-col v-if="!checkIfNamePropertyExists()">
                                <v-text-field
                                    :error-messages="
                                        $store.state.validation &&
                                        !$v.form.dynamicProperties.name.required
                                            ? [
                                                  this.$t(
                                                      'articles.text_messages.name_required'
                                                  ),
                                              ]
                                            : []
                                    "
                                    :autocomplete="autocompleteValueLocal"
                                    v-model="form.dynamicProperties.name"
                                    :label="
                                        form.familyArticle &&
                                        (form.familyArticle.parent_family_id ==
                                            1 ||
                                            this.form.familyArticle.id == 1)
                                            ? $t('articles.profile')
                                            : $t('articles.name')
                                    "
                                    class="mr-2 mt-2 to-upper"
                                    hide-details="auto"
                                    :readonly="!isEditGlobalArticleAllowed"
                                    :class="[
                                        !isEditGlobalArticleAllowed
                                            ? 'custom-readonly-color'
                                            : '',
                                    ]"
                                    :outlined="is_boxes"
                                    :dense="is_boxes"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field-percent
                                    :autocomplete="autocompleteValueLocal"
                                    v-model="
                                        form.dynamicProperties.tariff_price
                                    "
                                    :label="$t('articles.tariff_price')"
                                    class="mr-2 mt-2 to-upper"
                                    :class="[
                                        !isEditGlobalArticleAllowed
                                            ? 'custom-readonly-color'
                                            : '',
                                    ]"
                                    @keypress="handleInput($event, 'float', 10)"
                                    :readonly="!isEditGlobalArticleAllowed"
                                    v-bind:properties="{
                                        suffix: '',
                                        readonly: false,
                                        disabled: false,
                                        outlined: is_boxes,
                                        clearable: false,
                                        placeholder:
                                            getDecimalNumber() == 2
                                                ? '0.00'
                                                : '0.000',
                                        dense: is_boxes,
                                        hideDetails: 'auto',
                                    }"
                                    v-bind:options="{
                                        locale: userLanguageId,
                                        length: 8,
                                        precision: getDecimalNumber(),
                                        empty:
                                            getDecimalNumber() == 2 ? 0.0 : 0.0,
                                    }"
                                    hide-details="auto"
                                    tabindex="4"
                                    v-bind:focus="true"
                                    ref="tableRedPercentage"
                                >
                                </v-text-field-percent>
                            </v-col>
                            <v-col>
                                <v-text-field-percent
                                    :autocomplete="autocompleteValueLocal"
                                    v-model="form.price"
                                    :label="$t('articles.price')"
                                    class="mr-2 mt-2 to-upper"
                                    :class="[
                                        !isEditGlobalArticleAllowed
                                            ? 'custom-readonly-color'
                                            : '',
                                    ]"
                                    @keypress="handleInput($event, 'float', 10)"
                                    :readonly="!isEditGlobalArticleAllowed"
                                    v-bind:properties="{
                                        suffix: '',
                                        readonly: false,
                                        disabled: false,
                                        outlined: is_boxes,
                                        clearable: false,
                                        placeholder:
                                            getDecimalNumber() == 2
                                                ? '0.00'
                                                : '0.000',
                                        dense: is_boxes,
                                        hideDetails: 'auto',
                                    }"
                                    v-bind:options="{
                                        locale: userLanguageId,
                                        length: 8,
                                        precision: getDecimalNumber(),
                                        empty:
                                            getDecimalNumber() == 2 ? 0.0 : 0.0,
                                    }"
                                    hide-details="auto"
                                    tabindex="4"
                                    v-bind:focus="true"
                                    ref="tableRedPercentage"
                                >
                                </v-text-field-percent>
                            </v-col>
                            <v-col>
                                <v-text-field-percent
                                    :autocomplete="autocompleteValueLocal"
                                    v-model="
                                        form.plus_marge
                                    "
                                    :label="$t('articles.plus_marge')"
                                    class="mr-2 mt-2 to-upper"
                                    :class="[
                                        !isEditGlobalArticleAllowed
                                            ? 'custom-readonly-color'
                                            : '',
                                    ]"
                                    @keypress="handleInput($event, 'float', 10)"
                                    :readonly="!isEditGlobalArticleAllowed"
                                    v-bind:properties="{
                                        suffix: '',
                                        readonly: false,
                                        disabled: false,
                                        outlined: is_boxes,
                                        clearable: false,
                                        placeholder:
                                            getDecimalNumber() == 2
                                                ? '0.00'
                                                : '0.000',
                                        dense: is_boxes,
                                        hideDetails: 'auto',
                                    }"
                                    v-bind:options="{
                                        locale: userLanguageId,
                                        length: 8,
                                        precision: getDecimalNumber(),
                                        empty:
                                            getDecimalNumber() == 2 ? 0.0 : 0.0,
                                    }"
                                    hide-details="auto"
                                    tabindex="4"
                                    v-bind:focus="true"
                                >
                                </v-text-field-percent>
                            </v-col>
                            <v-col
                                cols="3"
                                sm="3"
                                v-if="form.familyArticle.is_stock_managed"
                            >
                                <v-text-field
                                    :autocomplete="autocompleteValueLocal"
                                    class="mr-2 mt-2 to-upper"
                                    v-model="form.internal_stocks"
                                    :label="$t('articles.internal_stocks')"
                                    @keypress="handleInput($event, 'int', 8)"
                                    hide-details="auto"
                                    :readonly="!isEditGlobalArticleAllowed"
                                    :class="[
                                        !isEditGlobalArticleAllowed
                                            ? 'custom-readonly-color'
                                            : '',
                                    ]"
                                    :outlined="is_boxes"
                                    :dense="is_boxes"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row> </v-row>

                <v-row> </v-row>

                <!-- Now Display the properties that are not in the above rendered property  -->
                <v-row class="d-flex">
                    <v-col
                        v-for="(filter, index) in filterPropertiesExcept(
                            customFilters
                        )"
                        :key="index"
                    >
                        <template v-if="filter.property_name == 'property_00'">
                            <v-autocomplete
                                style="min-width:200px"
                                :autocomplete="autocompleteValueLocal"
                                ref="brand"
                                :items="brands"
                                v-model="form.dynamicProperties.brand_id"
                                :label="$t('articles.properties.brand')"
                                class="mr-2 mt-1 to-upper"
                                item-value="id"
                                item-text="name"
                                @focus="brand = null"
                                hide-details="auto"
                                append-icon="mdi-close"
                                @click:append="
                                    form.dynamicProperties.brand_id = null
                                "
                                :readonly="!isEditGlobalArticleAllowed"
                                :class="[
                                    !isEditGlobalArticleAllowed
                                        ? 'custom-readonly-color'
                                        : '',
                                ]"
                                :outlined="is_boxes"
                                :dense="is_boxes"
                                hide-no-data
                            ></v-autocomplete>
                        </template>
                        <template v-else>
                            <v-autocomplete
                                style="min-width:200px"
                                :autocomplete="autocompleteValueLocal"
                                v-if="filter.mapping_key != null"
                                v-model="
                                    form.dynamicProperties[filter.property_name]
                                "
                                :items="multiselectsValues[index]"
                                :label="filter.text"
                                item-value="id"
                                hide-details="auto"
                                class="mr-2 mt-1"
                                append-icon="mdi-close"
                                @click:append="
                                    form.dynamicProperties[
                                        filter.property_name
                                    ] = null
                                "
                                :readonly="!isEditGlobalArticleAllowed"
                                :class="[
                                    !isEditGlobalArticleAllowed
                                        ? 'custom-readonly-color'
                                        : '',
                                ]"
                                :outlined="is_boxes"
                                :dense="is_boxes"
                                hide-no-data
                            ></v-autocomplete>
                            <v-text-field
                                style="min-width:200px"
                                :autocomplete="autocompleteValueLocal"
                                v-if="filter.mapping_key == null"
                                flat
                                v-model="
                                    form.dynamicProperties[filter.property_name]
                                "
                                :label="filter.text"
                                :ref="filter.property_name"
                                @focus="filter.value = null"
                                @keypress="
                                    handleInput(
                                        $event,
                                        filter.keytype,
                                        filter.search_length,
                                        filter.keytype == 'float' ? 2 : 0
                                    )
                                "
                                hide-details="auto"
                                :readonly="!isEditGlobalArticleAllowed"
                                class="to-upper"
                                :class="[
                                    !isEditGlobalArticleAllowed
                                        ? 'custom-readonly-color'
                                        : '',
                                ]"
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-text-field>
                        </template>
                    </v-col>
                </v-row>

                <v-row
                    class="mt-2"
                    v-for="(row, rowIndex) in articleBooleanProperties"
                    :key="'R' + rowIndex"
                >
                    <v-col
                        class="ml-2 pa-0"
                        v-for="(property, colIndex) in row"
                        :key="'C' + colIndex"
                    >
                        <v-checkbox
                            :label="$t('articles.properties.' + property.name)"
                            v-model="
                                form.dynamicProperties[property.property_name]
                            "
                            :readonly="!isEditGlobalArticleAllowed"
                            :class="[
                                !isEditGlobalArticleAllowed
                                    ? 'custom-readonly-color'
                                    : '',
                            ]"
                        ></v-checkbox>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>

        <v-btn @click="onSubmit()" class="d-none" id="submit-article"></v-btn>
        <v-btn @click="onDelete()" class="d-none" id="delete-article"></v-btn>
        <v-btn
            @click="printReportFromTopButton()"
            class="d-none"
            id="print-report"
        ></v-btn>
        <v-btn
            @click="moveToParentFamily()"
            class="d-none"
            id="move-to-parent"
        ></v-btn>
    </v-card>
</template>

<script>
import axios from "@/plugins/axios";
import { API_BASE_URL, VIEW_MODE } from "@/config";
// import { EDIT_MODE } from "@/config";
import { DETAILS_MODE, JUST_CANCEL_MODE } from "@/config";
import { ADD_MODE } from "@/config";
import ConfirmationModel from "@/components/ConfirmationModel";
import { validationMixin } from "vuelidate";
import {
    required,
    requiredIf,
    minLength,
    maxLength,
} from "vuelidate/lib/validators";
import { debounce } from "lodash";

export default {
    components: {
        "confirmation-model": ConfirmationModel,
    },
    name: "Article",
    mixins: [validationMixin],
    validations: {
        form: {
            dynamicProperties: {
                name: {
                    required: requiredIf(function() {
                        return !this.checkIfNamePropertyExists();
                    }),
                },
            },
        },
    },
    data() {
        let defaultForm = Object.freeze({
            entityType: { id: 1 },
            familyArticle: "",
            status: "",
            approval: "",
            tariffPrice: "",
            supplier_id: null,
            price: 0,
            plus_marge: 0,
            internal_stocks: 0,
            dynamicProperties: [],
        });
        return {
            is_boxes: this.getCompanyPropertyFromLocalStorage(
                "boxes_on_fields",
                1
            ),
            operation: "edit",
            token: this.$store.state.AccessToken,
            header: {
                Authorization: "Bearer " + this.$store.state.AccessToken,
            },
            globalfamilyArticles: [],
            approval: "",
            status: "",
            form: {
                entityType: { id: 1 },
                familyArticle: "",
                status: "",
                approval: "",
                tariffPrice: "",
                supplier_id: null,
                price: 0,
                plus_marge: 0,
                internal_stocks: 0,
                dynamicProperties: [],
            },
            brands: [],
            brand: null,
            customFilters: [],
            customFiltersExceptions: [],
            articleBooleanProperties: [],
            autocompleteValueLocal: "off",
            isEditGlobalArticleAllowed: false,
            confirmationDialog: false,
            confirmationDialogConfirmationText: null,
            confirmationDialogReCheckBoxConfirmationText: null,
            confirmationDialogFalseText: null,
            confirmationDialogTrueText: null,
            confirmationDialogButtonClickedIs: null,
            confirmationDialogOperation: null,
            isGeneralDescriptionFormat: false,
            cacheFamilyArticledData: null,
            articleImage: "",
            imageUrl: API_BASE_URL + "/article/image/",
            imageError: false,
            imageData: null,
            fallbackImageSrc: require("@/assets/images/no-image.png"),
            identifierError: [],
            articleToBeEditAfterEanSame: null
        };
    },
    mounted() {
        this.autocompleteValueLocal = this.autocompleteValue();

        this.header = { Authorization: "Bearer " + this.token };
        this.setCurrentFormOperation();
        this.getFamilyArticles().then(() => {
            // this.getApprovals();
            // this.getStatuses();

            if (this.operation === "edit") {
                this.fetchData();
            } else {
                this.$store.commit("setFormMode", ADD_MODE);
                // this.fetchTestData(1, true);
            }
        });
    },
    created() {
        // Start Debounce
        this.debounceHandler = debounce(
            (event) =>
                this.checkIdentifierIfAlreadyExists(
                    this.form.dynamicProperties.identifier
                ),
            800
        );
    },
    methods: {
        async checkIdentifierIfAlreadyExists(identifier) {
            if (identifier && identifier.length > 0) {
                await axios
                    .get(
                        API_BASE_URL +
                            "/articles?identifier=" +
                            identifier +
                            (this.form.familyArticle &&
                            this.form.familyArticle.id
                                ? "&family=" + this.form.familyArticle.id
                                : ""),
                        {
                            headers: this.header,
                        }
                    )
                    .then(({ data }) => {
                        let dataArticles = data.data.length;
                        if (dataArticles > 1) {
                            this.identifierError = [
                                this.$t(
                                    "articles.text_messages.ean_already_exists"
                                ),
                            ];
                            this.showEditPopupIfAllowed(data.data[0]);
                        } else if (dataArticles == 1) {
                            // If exists and the exist article is different from current
                            if (data.data[0].id != this.form.id) {
                                this.identifierError = [
                                    this.$t(
                                        "articles.text_messages.ean_already_exists"
                                    ),
                                ];
                                this.showEditPopupIfAllowed(data.data[0]);
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        editSameEanArticle(){
            if(this.articleToBeEditAfterEanSame?.id){
                this.$store.commit("seteditId", this.articleToBeEditAfterEanSame?.id);
                this.fetchData();
            }
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    this.imageUrl = event.target.result;
                };

                reader.readAsDataURL(file);
            }
        },
        onSelectFile() {
            const input = this.$refs.files;
            const files = input.files;
            if (files && files[0]) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.imageData = e.target.result;
                };
                reader.readAsDataURL(files[0]);
                this.$emit("input", files[0]);
            }
        },
        onImgError() {
            console.log("Invalid Image");
            this.imageError = true;
        },
        cacheCurrentData() {
            this.cacheFamilyArticledData = this.form.dynamicProperties;
            console.log(
                "General article description format",
                this.cacheFamilyArticledData
            );
        },
        printReportFromTopButton() {
            if (this.$store.state.editId) {
                let url =
                    API_BASE_URL +
                    "/article/" +
                    this.$store.state.editId +
                    "/print?document=label&lang=" +
                    (localStorage.getItem("languageId") || "en") +
                    "&user=" +
                    localStorage.getItem("loginUserId");
                let fileName = this.form.name;
                this.setPreviewData(url, fileName, true, "application/pdf");
            }
        },
        confirmationButtonClicked(buttonClicked) {
            this.confirmationDialogButtonClickedIs = buttonClicked;
            this.confirmationDialog = false;
        },
        addImage(articleId) {
            return new Promise((resolve, reject) => {
                if (!this.$refs.files.files[0]) {
                    resolve();
                    return;
                }

                let formData = new FormData();
                formData.append("articleImage", this.$refs.files.files[0]);
                // this.header{ 'Content-Type': 'multipart/form-data'}
                axios
                    .post(
                        API_BASE_URL + "/article/image/" + articleId,
                        formData,
                        {
                            "Content-Type": "multipart/form-data",
                            headers: this.header,
                        }
                    )
                    .then((response) => {
                        resolve();
                    })
                    .catch((err) => {
                        console.log("image", err);

                        this.$toast.error(
                            this.$t("company_settings.image_format_error")
                        );
                        reject();
                    });
            });
        },
        editImage(articleId) {
            return new Promise((resolve, reject) => {
                if (!this.$refs.files.files[0]) {
                    resolve();
                    return;
                }

                let formData = new FormData();
                formData.append("articleImage", this.$refs.files.files[0]);
                // this.header{ 'Content-Type': 'multipart/form-data'}
                axios
                    .post(
                        API_BASE_URL + "/article/image/" + articleId,
                        formData,
                        {
                            "Content-Type": "multipart/form-data",
                            headers: this.header,
                        }
                    )
                    .then((response) => {
                        console.log("console log", response.data.data.logo);

                        resolve();
                    })
                    .catch((err) => {
                        console.log("image", err);
                        // this.$toast.error(this.$t("company_settings.image_format_error"));

                        reject();
                    });
            });
        },
        checkIfNamePropertyExists() {
            let found = false;
            if (this.form.familyArticle) {
                this.form.familyArticle.properties.forEach((element) => {
                    if (element.name.toLowerCase() == "name") {
                        found = true;
                    }
                });
            }

            return found;
        },
        filterPropertiesExcept(customFilters) {
            var properties;
            if (
                this.form.familyArticle &&
                (this.form.familyArticle.parent_family_id == 1 ||
                    this.form.familyArticle.id == 1)
            ) {
                properties = [];
            } else if (
                this.form.familyArticle &&
                (this.form.familyArticle.parent_family_id == 2 ||
                    this.form.familyArticle.id == 2)
            ) {
                properties = [];
            } else {
                properties = [];
            }

            var filteredProperties = [];
            filteredProperties = customFilters.filter((property) => {
                let found = false;
                properties.forEach((element) => {
                    if (element == property.property_name) {
                        found = true;
                    }
                });

                return found ? false : property;
            });
            return filteredProperties;
        },
        filterPropertiesRemoveKeyIfMappingKeyIsNull(customFilters, properties) {
            var filteredProperties = [];
            filteredProperties = customFilters.filter((property) => {
                let found = false;
                properties.forEach((element) => {
                    if (element == property.property_name) {
                        found = true;
                    }
                });
                return found
                    ? property.mapping_key != null
                        ? property
                        : false
                    : false;
            });
            return filteredProperties;
        },
        deleteItem() {
            axios
                .delete(
                    API_BASE_URL + "/articles/" + this.$store.state.editId,
                    { headers: this.header }
                )
                .then((resDeleteClient) => {
                    this.$store.commit("setRefreshList", true);
                    this.$toast.success(
                        this.$t("articles.deleted_successfully")
                    );
                    this.$router.push("/articles").catch(() => {});
                })
                .catch((error) => {
                    this.$toast.error(error);
                });
        },
        getBrands() {
            this.brands = [];
            if (this.form.familyArticle && this.form.familyArticle.id) {
                if (
                    this.cachedData["brands"][this.form.familyArticle.id] &&
                    this.cachedData["brands"][this.form.familyArticle.id]
                        .length > 0
                ) {
                    this.brands = this.cachedData["brands"][
                        this.form.familyArticle.id
                    ];
                } else {
                    axios
                        .get(
                            API_BASE_URL +
                                "/brands?family=" +
                                this.form.familyArticle.id +
                                "&parent_also=true&items_per_page=-1",
                            { headers: this.header }
                        )
                        .then(({ data }) => {
                            this.brands = data.data;
                            this.cachedData["brands"][
                                this.form.familyArticle.id
                            ] = this.brands;
                        })
                        .catch(function(error) {
                            console.log("an error occured " + error);
                        });
                }
            }
        },
        // getSuppliers() {
        //     return new Promise((resolve, reject) => {
        //         this.suppliers = [];
        //         axios.get(API_BASE_URL + '/suppliers?items_per_page=-1&family_id=' + this.form.familyArticle.id+'&parent_also',{headers: this.header})
        //         .then(({ data }) => {
        //             this.suppliers = data.data;
        //             resolve();
        //         })
        //         .catch(function (error) {
        //             console.log('an error occured ' + error);
        //             reject();
        //         });
        //     });
        // },
        onDelete() {
            this.deletePopUp();
        },
        deletePopUp() {
            this.confirmationDialogConfirmationText = this.$t(
                "articles.delete_confirmation"
            );
            this.confirmationDialogReCheckBoxConfirmationText = this.$t(
                "articles.text_messages.delete_article_confirmation_reconfirm_text"
            );
            this.confirmationDialogFalseText = this.$t("articles.cancel");
            this.confirmationDialogTrueText = this.$t("articles.delete");
            this.confirmationDialogOperation = "delete";
            this.confirmationDialog = true;
        },
        showEditPopupIfAllowed(articleToBeEdit) {
            if(this.allowEditGlobal){
                this.articleToBeEditAfterEanSame = articleToBeEdit;
                this.confirmationDialogConfirmationText = this.$t(
                    "articles.text_messages.edit_confirmation_for_ean_already_exists"
                );
                this.confirmationDialogFalseText = this.$t("no");
                this.confirmationDialogTrueText = this.$t("yes");
                this.confirmationDialogOperation = "editArticle";
                this.confirmationDialog = true;
            }
        },
        async fetchData() {
            this.articleToBeEditAfterEanSame = null;
            this.identifierError = [];
            await axios
                .get(API_BASE_URL + "/articles/" + this.$store.state.editId, {
                    headers: this.header,
                })
                .then(({ data }) => {
                    this.$store.commit("setCurrentPageData", data.data);
                    this.form.id = data.data.article_id;
                    this.form.name = data.data.name;
                    // this.form.familyArticle = data.data.family_id;
                    this.form.dynamicProperties = data.data;
                    this.form.dynamicProperties.id = data.data.article_id;
                    this.form.price = this.form.dynamicProperties["price"];
                    this.form.plus_marge = this.form.dynamicProperties["plus_marge"];
                    this.form.internal_stocks = this.form.dynamicProperties[
                        "stock"
                    ];
                    this.isGeneralDescriptionFormat =
                        data.data.general_description_format;
                    // this.form.dynamicProperties.property_01 = "";

                    this.changeFamilyArticle();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async fetchTestData(familyId, changeFamilyArticle) {
            await axios
                .get(API_BASE_URL + "/articles/params", {
                    params: {
                        familyId: familyId ?? null,
                    },
                    headers: this.header,
                })
                .then(({ data }) => {
                    this.form.dynamicProperties = data.data;
                    changeFamilyArticle
                        ? this.changeFamilyArticle(false)
                        : false;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        submit() {
            this.$store.commit("setValidation", true); // enable validation
            const data = {
                data: this.form,
            };
            if (
                this.validate() &&
                !this.$v.$invalid &&
                this.identifierError.length == 0
            ) {
                if (this.operation === "add") {
                    // add new article
                    this.addArticle(data);
                } else {
                    // save article
                    this.updateArticle(data);
                }
            } else if (this.identifierError.length > 0) {
                this.$toast.error(
                    this.$t("articles.text_messages.ean_already_exists")
                );
            }
        },
        moveToParentFamily() {
            this.$store.commit("setValidation", true); // enable validation
            const data = {
                data: this.form,
            };
            data.data.move_to_parent = true;
            if (this.validate() && !this.$v.$invalid) {
                if (this.operation !== "add") {
                    // save article
                    this.updateArticle(data, true);
                }
            }
        },
        validate() {
            return this.$refs.form.validate();
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        changeArticleProperties() {
            var currentFamilyArticle = this.globalfamilyArticles.find(
                (element) => {
                    if (element.id == this.form.familyArticle.id)
                        return element;
                }
            );

            if (currentFamilyArticle != undefined) {
                this.articleBooleanProperties = this.sliceIntoChunks(
                    currentFamilyArticle.properties.filter(
                        (property, index) => {
                            property.property_name =
                                "property_" +
                                (property.property_id < 10
                                    ? 0 + property.property_id.toString()
                                    : property.property_id);
                            var propertyName =
                                "property_" +
                                (property.property_id < 10
                                    ? 0 + property.property_id.toString()
                                    : property.property_id);
                            if (
                                property.cast == "boolean" &&
                                property.in_edit == 1
                            ) {
                                this.form.dynamicProperties[
                                    propertyName
                                ] = Boolean(
                                    Number(
                                        this.form.dynamicProperties[
                                            propertyName
                                        ]
                                    )
                                );
                                return property;
                            }
                            return false;
                        }
                    ),
                    5
                );
            }
        },
        setCurrentFamilyArticle() {
            var currentFamilyArticle = this.globalfamilyArticles.find(
                (element) => {
                    if (element.id == this.form.dynamicProperties.family_id)
                        return element;
                }
            );
            console.log(
                "setting up family article",
                currentFamilyArticle,
                this.form
            );
            if (currentFamilyArticle) {
                this.form.familyArticle = currentFamilyArticle;
            }
        },
        sliceIntoChunks(arr, chunkSize) {
            const res = [];
            for (let i = 0; i < arr.length; i += chunkSize) {
                const chunk = arr.slice(i, i + chunkSize);
                res.push(chunk);
            }
            return res;
        },
        async getApprovals() {
            await this.getCodes(
                "article_approval_statuses",
                "article_approval_statuses"
            ).then((data) => {
                this.approvals = data;
            });
        },
        async getStatuses() {
            await this.getCodes("article_statuses", "article_statuses").then(
                (data) => {
                    this.statuses = data;
                }
            );
        },
        getMultiSelectValuesFromCode() {
            this.customFilters.forEach((filter, filterindex) => {
                this.multiselectsValues[filterindex] = [];
            });
            this.multiselects.forEach((code, index) => {
                this.getCodes(parseInt(code), "codes").then((data) => {
                    this.customFilters.forEach((filter, filterindex) => {
                        if (filter.mapping_key == code) {
                            this.multiselectsValues[filterindex] = data;
                        }
                    });
                    this.multiselectsValues.push({});
                    this.multiselectsValues.splice(-1);
                });
            });
        },
        setDefaultFamilyArticle() {
            let defaultFamilyArticle = null;
            this.globalfamilyArticles.forEach((element) => {
                if (element.is_default) {
                    defaultFamilyArticle = element;
                }
            });
            var articleFilters = JSON.parse(
                localStorage.getItem("ARTICLE_FILTERS")
            );
            if (articleFilters != null) {
                this.form.familyArticle = this.getFamilyArticleById(
                    articleFilters.family ?? defaultFamilyArticle.id
                );
                console.log(
                    "family article",
                    articleFilters.family,
                    this.familyArticle
                );
            } else {
                if (!defaultFamilyArticle) {
                    this.form.familyArticle = this.globalfamilyArticles[0];
                } else {
                    this.form.familyArticle = defaultFamilyArticle;
                }
            }
            if (this.operation == "add") {
                this.fetchTestData(this.form.familyArticle.id, false);
            }
        },
        getFamilyArticleById(id) {
            let familyArticle = null;
            this.globalfamilyArticles.forEach((element) => {
                if (element.id == id) {
                    familyArticle = element;
                }
            });
            return familyArticle;
        },
        getFamilyArticles() {
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        API_BASE_URL +
                            "/family_articles?items_per_page=-1" +
                            (this.operation == "edit"
                                ? "&global_also=true"
                                : ""),
                        { headers: this.header }
                    )
                    .then(({ data }) => {
                        this.globalfamilyArticles = data.data;
                        this.globalfamilyArticles.forEach((family_article) => {
                            // If family article has properties then its properties will be of family article
                            if (
                                family_article &&
                                family_article.parent_family_properties.length >
                                    0
                            ) {
                                family_article.properties =
                                    family_article.parent_family_properties;
                            }
                        });

                        this.setDefaultFamilyArticle();
                        this.changeFamilyArticle();
                    })
                    .catch(function(error) {
                        console.log("an error occured " + error);
                    });
                resolve();
            });
        },
        setDynamicFilterBoxes() {
            var currentFamilyArticle = this.globalfamilyArticles.find(
                (element) => {
                    if (element.id == this.form.familyArticle.id)
                        return element;
                }
            );

            this.customFilters = [];
            this.multiselects = [];
            currentFamilyArticle.properties.filter((property) => {
                if (
                    property.in_edit == 1 &&
                    property.cast != "boolean" &&
                    !this.customFiltersExceptions.includes(property.property_id)
                ) {
                    var indexOfClass = (property.mapping ?? "").indexOf(
                        "class="
                    );
                    if (indexOfClass != -1) {
                        var mappingKey =
                            property.mapping.substr(indexOfClass + 6, 4) + "";
                        var propertyName =
                            "property_" +
                            (property.property_id < 10
                                ? 0 + property.property_id.toString()
                                : property.property_id);

                        if (this.form.dynamicProperties) {
                            this.form.dynamicProperties[
                                propertyName
                            ] = parseInt(
                                this.form.dynamicProperties[propertyName]
                            );
                        }
                    }
                    if (mappingKey) {
                        this.multiselects.push(mappingKey);
                    }
                    this.customFilters.push({
                        text: this.$t("articles.properties." + property.name),
                        align: "start",
                        sortable: true,
                        property_name:
                            "property_" +
                            (property.property_id < 10
                                ? 0 + property.property_id.toString()
                                : property.property_id),
                        property_id: property.property_id,
                        column_name: property.name,
                        value: null,
                        property_type: property.cast,
                        mapping: property.mapping ?? null,
                        mapping_key: mappingKey ?? null,
                        keytype: property.cast,
                        search_length: property.search_length,
                    });
                    return true;
                }
                return false;
            });
        },
        getModelNameFromClassId(classId) {
            var modelName = [
                { key: "1010", name: "tyreTypes" },
                { key: "1011", name: "tyreSeasons" },
                { key: "1041", name: "wheelTypes" },
                { key: "1042", name: "wheelMaterials" },
            ];
            return modelName.find((code) => {
                if (code.key == classId) return code;
            }).name;
        },
        addArticle(data) {
            axios
                .post(API_BASE_URL + "/articles", data, {
                    headers: this.header,
                })
                .then((response) => {
                    if (response.status === 201) {
                        console.log(response.data.data.article_id);
                        this.addImage(response.data.data.article_id).finally(
                            () => {
                                this.$store.commit("setRefreshList", true);
                                this.$toast.success(
                                    this.$t(
                                        "articles.text_messages.article_added_successfully"
                                    )
                                );
                                this.$router.push("/articles").catch(() => {});
                            }
                        );
                    } else {
                        alert("ERROR on saving. Please try again later");
                    }
                })
                .catch((error) => {
                    if (error.response.status === 409) {
                        this.$toast.error(
                            this.$t(
                                "articles.text_messages.article_already_exists"
                            )
                        );
                    }
                })
                .finally(() => {});
        },
        updateArticle(data, isMoving = false) {
            axios
                .put(API_BASE_URL + "/articles/" + this.form.id, data, {
                    headers: this.header,
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.editImage(this.form.id).finally(() => {
                            this.$store.commit("setRefreshList", true);
                            if (isMoving) {
                                this.$toast.success(
                                    this.$t(
                                        "articles.text_messages.article_moved_successfully"
                                    )
                                );
                            } else {
                                this.$toast.success(
                                    this.$t(
                                        "articles.text_messages.article_updated_successfully"
                                    )
                                );
                            }
                            this.$router.push("/articles").catch(() => {});
                        });
                    } else {
                        if (isMoving) {
                            this.$toast.error(
                                this.$t(
                                    "articles.text_messages.article_moved_failed"
                                )
                            );
                        } else {
                            this.$toast.error(
                                this.$t(
                                    "articles.text_messages.article_updated_failed"
                                )
                            );
                        }
                    }
                })
                .catch((error) => {
                    if (error.response.status === 409) {
                        this.$toast.error(
                            this.$t(
                                "articles.text_messages.article_already_exists"
                            )
                        );
                    }
                })
                .finally(() => {});
        },
        setCurrentFormOperation() {
            this.operation =
                this.$route.path.indexOf("/add") > 0 ? "add" : "edit";
        },
        changeFamilyArticle(fetchTestDataAgain) {
            // if (this.operation === "edit") {
            //   this.setCurrentFamilyArticle();
            // }
            if (fetchTestDataAgain) {
                this.fetchTestData(this.form.familyArticle.id, false);
            }
            this.changeArticleProperties();
            this.brands = [];
            this.getBrands();
            // this.getSuppliers();
            this.setDynamicFilterBoxes();
            console.log(
                "General Description Format ---- 1",
                this.isGeneralDescriptionFormat,
                this.cacheFamilyArticledData
            );
            if (
                this.isGeneralDescriptionFormat &&
                this.cacheFamilyArticledData
            ) {
                console.log(
                    "General Description Format ---- 2",
                    this.cacheFamilyArticledData.name
                );
                this.form.dynamicProperties.name = this.cacheFamilyArticledData.name;
            }
        },
    },
    computed: {
        isCompanyEntitySelected() {
            return this.form.entityType.id === 1;
        },
        companyNameLabel() {
            return this.isCompanyEntitySelected ? "Company Name" : "Full Name";
        },
        // familyClient () {
        //   return this.familyClients.find(({ is_default }) => is_default === 1);
        // }
    },
    watch: {
        confirmationDialogButtonClickedIs(newVal) {
            if (newVal == true && this.confirmationDialogOperation != null) {
                if (
                    this.confirmationDialogOperation == "delete" &&
                    this.confirmationDialogButtonClickedIs == true
                ) {
                    this.deleteItem();
                }
                if (
                    this.confirmationDialogOperation == "editArticle" &&
                    this.confirmationDialogButtonClickedIs == true
                ) {
                    this.editSameEanArticle();
                }
                this.confirmationDialogButtonClickedIs = null;
            }
        },
        "form.dynamicProperties": {
            handler: function(val) {
                if (
                    this.form &&
                    this.form.dynamicProperties &&
                    this.form.dynamicProperties.identifier
                ) {
                    this.imageUrl =
                        this.imageUrl + this.form.dynamicProperties.identifier;
                } else {
                    this.imageUrl = this.fallbackImageSrc;
                }
            },
        },
        "form.familyArticle": {
            handler: function(val) {
                if (this.operation == "edit") {
                    if (val && !val.company_id) {
                        // This means article is a global article
                        if (!this.allowEditGlobal) {
                            this.isEditGlobalArticleAllowed = false;
                            this.$store.commit("setFormMode", JUST_CANCEL_MODE);
                            this.$toast.warning(
                                this.$t(
                                    "readonly_article_managed_by_garageexpert"
                                )
                            );
                        } else if (this.allowEditGlobal) {
                            this.$store.commit("setFormMode", DETAILS_MODE);
                            this.isEditGlobalArticleAllowed = true;
                        }
                    }
                    if (val && val.company_id) {
                        // This means article is company's article not a global article
                        this.$store.commit("setFormMode", DETAILS_MODE);
                        this.isEditGlobalArticleAllowed = true;
                    }
                    if (
                        this.isGeneralDescriptionFormat &&
                        this.cacheFamilyArticledData
                    ) {
                        this.$nextTick(() => {
                            setTimeout(() => {
                                this.form.dynamicProperties.id = this.cacheFamilyArticledData.id;
                                this.form.dynamicProperties.name = this.cacheFamilyArticledData.name;
                                this.form.dynamicProperties.identifier = this.cacheFamilyArticledData.identifier;
                                this.form.dynamicProperties.family_id = this.form.familyArticle.id;
                            }, 500);
                        });
                    }
                } else {
                    this.isEditGlobalArticleAllowed = true;
                }
                this.getMultiSelectValuesFromCode();
            },
        },
    },
};
</script>

<style scoped>
.articleImage:hover {
    opacity: 0.8 !important;
    cursor: copy;
}
</style>
