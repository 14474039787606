
<template>
  <v-btn :small="small"  class="text-white-color" type="submit" color="success" :loading="loading" :disabled="disabled">
    {{ label || $t("nav_buttons.save") }}
    <v-icon dark right> mdi-content-save </v-icon>
  </v-btn>
</template>
<script>
export default{
  name: 'SaveButton',
  props:{
    disabled: {
      default: false
    },
    loading: {
      default: false
    },
    label: {
      default: null
    },
    small: {
      default: false
    }
  }
}
</script>