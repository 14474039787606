<template>
    <v-dialog
        v-model="popUpModelVisible"
        max-width="800"
        @click:outside="closePopUpModel()"
        @keydown.esc="closePopUpModel()"
    >
        <v-card>
            <v-card-title>
                <span class="text-h5">
                    {{ $t("receptions.reception_details") }} -
                    {{
                        data.article_original_name.original_name
                    }}</span
                >
            </v-card-title>
            <v-card-text>
                <v-row>
                    <span> </span>
                    <v-col cols="12" sm="3">
                        <v-autocomplete
                            disabled
                            flat
                            :items="allSuppliers"
                            v-model="data.saved_supplier_order_detail.supplier_order.supplier"
                            :label="$t('receptions.supplier')"
                            class="mr-2 to-upper"
                            item-value="id"
                            item-text="name"
                            return-object
                            :outlined="is_boxes"
                            :dense="is_boxes"
                            hide-no-data
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-text-field
                            v-model="
                                data.saved_supplier_order_detail.confirmed_quantity
                            "
                            :label="$t('receptions.confirmed_quant_label')"
                            v-bind="attrs"
                            v-on="on"
                            disabled
                            class="mr-2 to-upper"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-text-field
                            v-model="
                                data.saved_supplier_order_detail.received_quantity
                            "
                            :label="$t('receptions.received_quant_label')"
                            disabled
                            @input="checkQuantityToChangeStatus()"
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2 to-upper"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="5">
                        <v-autocomplete
                            v-model="data.saved_supplier_order_detail.supplier_order.status"
                            :items="rec_statuses"
                            :label="$t('receptions.status')"
                            item-value="id"
                            item-text="text"
                            class="mr-2"
                            return-object
                            :outlined="is_boxes"
                            :dense="is_boxes"
                            @click:append="status = null"
                            hide-details
                            disabled
                            append-icon="mdi-close"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey-lighten-2" @click="closePopUpModel()">
                    {{ $t("receptions.modify_article.cancel") }}
                    <v-icon dark right> mdi-keyboard-return </v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { validationMixin } from "vuelidate";

export default {
    mixins: [validationMixin],
    validations: {},
    props: {
        data: [Object],
    },
    data() {
        return {
            is_boxes: this.getCompanyPropertyFromLocalStorage(
                "boxes_on_fields",
                1
            ),
            popUpModelVisible: true,
            autocompleteValueLocal: "off",
            rec_statuses: [],
        };
    },
    mounted() {
        this.autocompleteValueLocal = this.autocompleteValue();
        this.getStatuses();
    },
    methods: {
        closePopUpModel() {
            this.popUpModelVisible = false;
        },
        getStatuses() {
            return new Promise((resolve, reject) => {
                this.getCodes(
                    "supplier_order_statuses",
                    "receptions.statuses"
                ).then((data) => {
                    this.rec_statuses = data;
                    resolve();
                });
            });
        },
    },
    computed: {
        allSuppliers() {
            return this.$store.state.allSuppliers ?? [];
        },
    },
    watch: {
        popUpModelVisible(val) {
            this.$emit("change-popup-model-visibility", this.savedReception);
        },
        data: {
            handler: function(val) {
                this.rec_status = this.data.status;
            },
            immediate: true,
        },
    },
};
</script>
