<template>
    <v-dialog
        v-model="modify_stock_dialog"
        @click:outside="closeStocks()"
        @keydown.esc="closeStocks()"
        persistent
        max-width="1224px"
        scrollable
    >
        <v-card>
            <v-card-title>
                <span class="text-h5">{{
                    $t("stocks.modify_stock.title")
                }}</span>
            </v-card-title>
            <v-card-text>
                <v-container class="pt-0">
                    <v-row class="mb-n8">
                        <v-col>
                            <p class="orderTitle">{{ rowData.description }}</p>
                        </v-col>
                    </v-row>
                    <v-row class="mt-2">
                        <v-col>
                            <p class="orderSubTitle">
                                {{ rowData.identifier }}
                            </p>
                        </v-col>
                    </v-row>
                    <v-row class="pt-4">
                        <v-col cols="12" sm="1">
                            <!-- Internal Stock -->
                            <custom-money-input
                                :autocomplete="autocompleteValueLocal"
                                class="mr-2 to-upper right-aligned-input"
                                hide-details="auto"
                                :label="this.$t('stocks.int_stock')"
                                tabindex="2"
                                ref="stock"
                                v-model="form.stock"
                                v-on:keydown="handleInput($event, 'float', 8)"
                                v-bind:properties="{
                                    readonly: false,
                                    disabled: false,
                                    outlined: false,
                                    placeholder: '0',
                                    dense: false,
                                    hideDetails: 'auto',
                                }"
                                v-bind:options="{
                                    locale: userLanguageId,
                                    length: 10,
                                    precision: selectedFamily.quantity_decimals,
                                    empty: fixDecimal(
                                        0,
                                        selectedFamily.quantity_decimals
                                    ),
                                }"
                            />
                        </v-col>
                        <v-col cols="24" sm="0" class="pt-9 pl-0 pr-0">
                            <h2>
                                /
                            </h2>
                        </v-col>
                        <v-col cols="12" sm="1">
                            <!-- Reserve -->
                            <custom-money-input
                                :autocomplete="autocompleteValueLocal"
                                class="mr-2 to-upper text-red right-aligned-input"
                                hide-details="auto"
                                :label="this.$t('stocks.reserve')"
                                tabindex="2"
                                ref="reserve"
                                v-model="form.reserve"
                                v-on:keydown="handleInput($event, 'float', 8)"
                                v-bind:properties="{
                                    readonly: false,
                                    disabled: false,
                                    outlined: false,
                                    placeholder: '0',
                                    dense: false,
                                    hideDetails: 'auto',
                                }"
                                v-bind:options="{
                                    locale: userLanguageId,
                                    length: 10,
                                    precision: selectedFamily.quantity_decimals,
                                    empty: fixDecimal(
                                        0,
                                        selectedFamily.quantity_decimals
                                    ),
                                }"
                            />
                        </v-col>
                        <v-col>
                            <!-- Price -->
                            <v-text-field-percent
                                class="mr-2 to-upper right-aligned-input"
                                v-model="form.price"
                                :label="this.$t('stocks.price')"
                                :properties="{
                                    readonly: false,
                                    disabled: false,
                                    outlined: false,
                                    clearable: false,
                                    placeholder: '',
                                }"
                                :options="{
                                    locale: userLanguageId,
                                    length: 12,
                                    precision: getDecimalNumber(),
                                    empty: null,
                                }"
                            />
                        </v-col>
                        <v-col>
                            <!-- Plus Margin -->
                            <v-text-field-percent
                                class="mr-2 to-upper right-aligned-input"
                                v-model="form.plus_marge"
                                :label="this.$t('stocks.plus_margin')"
                                :properties="{
                                    readonly: false,
                                    disabled: false,
                                    outlined: false,
                                    clearable: false,
                                    placeholder: '',
                                }"
                                :options="{
                                    locale: userLanguageId,
                                    length: 12,
                                    precision: getDecimalNumber(),
                                    empty: null,
                                }"
                            />
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-autocomplete
                                flat
                                ref="brand"
                                disabled
                                readonly
                                :items="conditions"
                                v-model="form.condition_id"
                                :label="$t('pricing_rule.condition')"
                                class="mr-2 to-upper"
                                item-value="id"
                                item-text="text"
                                :return-object="false"
                                :append-icon="form.condition_id ? '' : ''"
                                :outlined="is_boxes"
                                :dense="is_boxes"
                                hide-no-data
                            ></v-autocomplete>
                        </v-col>
                        <!-- <v-col
                            v-if="
                                selectedFamily.parent_family_id == 1 ||
                                    selectedFamily.id == 1
                            "
                        >
                            <v-btn
                                :disabled="loadingDots"
                                :color="
                                    sumOfDots() != form.stock
                                        ? 'warning'
                                        : 'primary'
                                "
                                class="mt-3"
                                @click="showModifyDotDialog(rowData)"
                            >
                                {{ $t("dot") }}
                                <template v-if="!loadingDots">
                                    <v-icon
                                        dark
                                        right
                                        v-if="sumOfDots() == form.stock"
                                    >
                                        mdi-circle-opacity</v-icon
                                    >
                                    <v-icon
                                        dark
                                        right
                                        v-if="sumOfDots() != form.stock"
                                    >
                                        mdi-alert-circle-outline</v-icon
                                    >
                                </template>

                                <v-progress-circular
                                    v-if="loadingDots"
                                    size="20"
                                    :width="3"
                                    indeterminate
                                    color="grey lighten-5"
                                ></v-progress-circular>
                            </v-btn>
                        </v-col> -->
                        <v-spacer></v-spacer>
                        <v-col>
                            <v-switch
                                class="mt-4 ml-1"
                                color="success"
                                v-model="form.is_verified"
                                :label="$t('stocks.verified')"
                                inset
                            ></v-switch>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <reserved-stock-table
                                :articleId="article_id"
                                :familyArticleId="rowData.family_id"
                                :activeTab="activeTab"
                                :data="form"
                            >
                            </reserved-stock-table>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pr-2">
                <v-spacer></v-spacer>
                <v-btn color="grey lighten-2" @click="closeStocks()">
                    {{ $t("stocks.cancel") }}
                    <v-icon dark right> mdi-keyboard-return</v-icon>
                </v-btn>
                <v-btn
                    color="success"
                    @click="saveStocks()"
                    :disabled="
                        getUserRoleResourceForResource('stocks').can_edit == 0
                    "
                >
                    {{ $t("stocks.save") }}
                    <v-icon dark right> mdi-content-save</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
        <stock-article-dot
            v-if="modifyDots"
            :article="rowData"
            :passed_dot_items="dot_items"
            :total_stocks="form.stock"
            @change-modify-article-dialog="changeModifyArticleDialog()"
        ></stock-article-dot>
    </v-dialog>
</template>
<style scoped>
.right-aligned-input >>> input {
    text-align: right;
}

.text-red >>> input {
    color: red !important;
}
</style>
<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import StockArticleDot from "@/components/Stock/StockArticleDot";
import ReservedStocksTable from "../ReservedStocksTable.vue";
import CutsomMoneyInput from "@/components/CustomInputs/MoneyInput";

export default {
    props: {
        rowData: {
            type: Object,
        },
        header: {
            type: String,
        },
        selectedFamily: {
            type: Object,
        },
        activeTab: {
            default: 0,
        },
    },
    components: {
        "stock-article-dot": StockArticleDot,
        "reserved-stock-table": ReservedStocksTable,
        "custom-money-input": CutsomMoneyInput,
    },
    data() {
        return {
            is_boxes: this.getCompanyPropertyFromLocalStorage(
                "boxes_on_fields",
                1
            ),
            modify_stock_dialog: true,
            form: {
                condition_id: null,
                plus_marge: null,
                stock: this.rowData.stock ?? 0,
                reserve: this.rowData.reserve ?? 0,
                price: this.rowData.price
                    ? this.fixDecimal(this.rowData.price)
                    : 0.0,
                is_verified: this.rowData.is_verified,
            },
            old_stock: this.rowData.stock ?? 0,
            old_reserve: this.rowData.reserve ?? 0,
            article_id: this.rowData.article_id ?? this.rowData.id,
            autocompleteValueLocal: "off",
            modifyDots: false,
            dot_items: [],
            loadingDots: false,
            conditions: [],
        };
    },
    async mounted() {
        await this.getConditions();
        this.getArticleDots();
        this.getArticleDetail();
    },
    methods: {
        getArticleDetail() {
            axios
                .get(
                    API_BASE_URL +
                        "/company_article/" +
                        this.article_id +
                        "?supplier_id=0",
                    {
                        headers: {
                            Authorization:
                                "Bearer " + this.$store.state.AccessToken,
                        },
                    }
                )
                .then((response) => {
                    console.log("company article", response.data);
                    if (response.data) {
                        this.form.plus_marge = response.data.plus_marge ?? 0;
                        this.form.condition_id = response.data.condition_id;
                    } else {
                        this.form.plus_marge = 0;
                        this.form.condition_id = 10231000;
                    }
                })
                .catch(function(error) {
                    console.log("an error occured " + error);
                })
                .finally(() => {
                    this.loadingDots = false;
                });
        },
        async getConditions() {
            await this.getCodes(
                "article_conditions",
                "pricing_rule.conditions"
            ).then((data) => {
                this.conditions = data;
            });
        },
        getArticleDots() {
            this.dot_items = [];
            console.log("console");
            this.loadingDots = true;
            axios
                .get(
                    API_BASE_URL +
                        "/stock_property/" +
                        this.article_id +
                        "?property_class_id=dot_property",
                    {
                        headers: {
                            Authorization:
                                "Bearer " + this.$store.state.AccessToken,
                        },
                    }
                )
                .then((response) => {
                    console.log(response.data.data, "dot items");
                    this.dot_items = response.data.data;
                })
                .catch(function(error) {
                    console.log("an error occured " + error);
                })
                .finally(() => {
                    this.loadingDots = false;
                });
        },
        showModifyDotDialog(id) {
            this.modifyDots = true;
        },
        sumOfDots() {
            if (this.dot_items && this.dot_items?.length > 0) {
                let totalCounter = 0;
                this.dot_items.forEach((element) => {
                    totalCounter =
                        parseInt(totalCounter) + parseInt(element.counter);
                });
                console.log("total counter", totalCounter);
                return totalCounter;
            }
            return 0;
        },
        changeModifyArticleDialog() {
            this.modifyDots = !this.modifyDots;
            this.getArticleDots();
        },
        closeStocks() {
            this.modify_stock_dialog = false;
            this.$emit("modifyClose", false);
        },
        saveStocks() {
            if(this.article_id){
                let data = this.form;
                axios
                    .put(API_BASE_URL + "/stocks/" + this.article_id, data, {
                        headers: this.header,
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            // this.$router.push("/family-clients").catch(() => {});
                        } else {
                            alert("ERROR on saving. Please try again later");
                        }
                    })
                    .catch(function(error) {
                        console.log("an error occured " + error);
                    })
                    .finally(() => {
                        this.modify_stock_dialog = false;
                        this.$emit("modifyClose", true);
                    });
            }
        },
    },
};
</script>
